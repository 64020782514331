import React  from "react"; 
import { Container, Row } from "reactstrap";  
import { Breadcrumbs } from "../../../../../AbstractElements";

const CrmClientesMetricas = () => { 
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <>
     <Breadcrumbs mainTitle='Métricas' parent='Atendentes' title={company.name} />
      <Container fluid={true}>
      <Row className="widget-grid">
         
        </Row>
      </Container> 
    </>
  );
};

export default CrmClientesMetricas; 
import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/notification";

 const NotificationService =  {

    getUserId(userId) {    
    return axios
      .get(API_URL  + "/user/" + userId,  { headers: authHeader() });
  }
 
}  

export default NotificationService;

import React, { useState, useEffect} from 'react';
import { Card,   CardBody, CardHeader, Table , Input , Label} from 'reactstrap';
import roleService from '../../../../Services/administrativo/role.service'; 
import { H5 , H6 } from '../../../../AbstractElements';  
import perfilService from '../../../../Services/administrativo/perfil.service';  

const PerfilRoles = (props) => {
  const [roles, setRoles] = useState([]); 
  const  perfil = props.perfil;
  const [rolesPerfil, setRolesPerfil] = useState(props.perfil.roles);
  const module = props.perfil.module;

  useEffect(() => {
    const getListAll = async () => {
      try {
        const resp = await roleService.getAllByModule(module);
        setRoles(resp.data);  
      } catch (error) {
        console.log('cancelled', error);
      }
    };

    getListAll();
  }, [setRoles]);

  
  const removerRole = (nameRole) => {
    const novoArray = rolesPerfil.filter(valor => valor !== nameRole);
    setRolesPerfil(novoArray);
  };
 

  const addRole = (nameRole) => {
    setRolesPerfil([...rolesPerfil, nameRole]);
  };


  const updateRole = (e, nameRole) => {
    var checked = e.target.checked;
    return perfilService.updateRole(perfil.id, nameRole, checked)
    .then((response) => { 
      console.log(rolesPerfil);

      if (checked) addRole(nameRole);
      else removerRole(nameRole); 

      return response;
    }).catch((error) => {
      console.log(error);
     // toast.error("Erro ao logar = "+ error.response.data);
    });
     
  } 
 
  return (
    <Card>
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <H5 className='m-0'>Roles do perfil - {perfil.name}</H5>  
          <H6 className='m-0'>Módulo: {module}</H6>  
        </div>        
      </CardHeader>
      <CardBody className='pt-0 campaign-table'>
      {roles.length > 0 ? ( // Verifica se há dados em roles

        <div className='recent-table table-responsive currency-table'>
          <Table>
            <thead>
              <tr> 
                <th>Name</th> 
                <th>description</th>
                <th>checked</th>
              </tr>
            </thead>
            <tbody>
            {
            
            
            roles   && roles.map((item, i) => (
                
                <tr key={i}> 
                  <td>{item.nameRole}</td> 
                  <td>{item.description}</td> 
                  <td> 
                    <Label className="switch">
                        <Input type="checkbox" checked={rolesPerfil.indexOf(item.nameRole) > -1} value={item.nameRole} onChange={(e) => updateRole(e, item.nameRole)} />
                        <span className="switch-state" ></span>
                    </Label>
                
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        ) : (
          // Renderiza algo enquanto os dados estão sendo carregados
          <div>Loading...</div>
        )}
      </CardBody>
    </Card> 
  );
};

export default PerfilRoles;

import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn,  } from '../../../../AbstractElements';
import { Close  } from '../../../../Constant';

const FormPointModal = (props) => {
  
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>       
        {props.client.client ? ('Nome: ' + props.client.client.name) : ''}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
       
      <ModalFooter>
        <Btn attrBtn={{ color: 'secondary', onClick: props.toggler }} >{Close}</Btn>
      </ModalFooter> 
    </Modal>
  );
};

export default FormPointModal;
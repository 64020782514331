import React, { Fragment , useState} from 'react';
import { Breadcrumbs, Btn} from '../../../../AbstractElements';
import { useTranslation } from "react-i18next";
import { Col, Container, Form, Input, Row } from 'reactstrap';
import countryService from '../../../../Services/administrativo/country.service';
import { toast } from "react-toastify";
import { Yes, No, Delete, Edit, ACTIVE, New, TextSearch} from "../../../../Constant"; 
import { countryColumns } from '../../../../Data/administrativo/CountryList';

import CountryFormData from './country-form';  
import ListaData from '../../../../CommonElements/ListData';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const RolePage = () => {
  const { t } = useTranslation();
  const [countrys, setCountrys] = useState([]); 
  const [filtroName, setFiltroName] = useState(''); 
  const [option, setOption] = useState(""); 
  const [page, setPage] = useState(1); 
  const [size, setSize] = useState(10); 
  const [sort, setSort] = useState("name"); 
  const [country, setCountry] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  var requestFilter = {  
    name : filtroName
  } 



  const getListAll = async (e) => {
    setLoading(true);
    try { const response = await countryService.getFilter(requestFilter, page, size, sort);
      var contryList = await response.data.content;
    //var contryList = await response.data;
    const transformedItems = contryList.map((country) => ({
      ...country,
      status: (
        <div >
          <span>
            {t(country.status)}
          </span>
        </div> 
      ),
      standard: (
        <div >
          <span>
            {country.standard ? t(Yes) : t(No)}
          </span>
        </div> 
      ),
      action : (
        <div>
          <span>
            <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick : (e) => btnDelete(country) }}>{t(Delete)}</Btn>
          </span>{' '}
          &nbsp;&nbsp;
          <span>
            <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' , onClick : (e) => btnEdit(country) }}>{t(Edit)}</Btn>
          </span>
        </div>
      )
    }));
 
    setCountrys(transformedItems);    
    setLoading(false);
    
  } catch (error ) {
    setError(error);
    setLoading(false);
    console.log(error);
}
    return contryList;
  }

  const btnEdit = (country) => {
    setOption(Edit); 
    setCountry(country); 
  }

  const btnDelete = (country) => {
    setOption(Delete); 
    setCountry(country); 
  }

  const deleteCountry = (request) => {
    if (request.id != null) {
      return countryService.delete(request)
      .then((response) => {
        toast.success("País excluído com sucesso!"); 
        getListAll();
        setOption("list");
        setCountry({});
        return response;
      }).catch((error) => {
        setError(error);
        toast.error("Erro ao logar = "+ error.response.data);
      });
    }  
  }

  const btnNovo = () => {
    initialize();
    setOption(New);
  }
  
  const initialize = () => {
    setCountry(
      {
      id : -1,
      name: "",
      observation: "",
      code: "",
      standard: false,
      status : ACTIVE});
  }

  const btnCancel = () => {
    initialize();
    setOption("list");
  }


  const save = (request) => {
    return countryService.save(request)
    .then((response) => {
      initialize();
      setOption("list");
      toast.success("País cadastrado com sucesso!"); 
      getListAll();
      return response;
    }).catch((error) => {
      toast.error("Erro ao logar = "+ error.response.data);
    });
     
  } 

  const update = async  (request) => {
    setLoading(true);
     if (request.id != null) {
       return countryService.update(request)
       .then((response) => {
         toast.success("País alterado com sucesso!"); 
         getListAll();
         setOption("list");
         initialize();
         setLoading(false);
         return response;
       }).catch((error) => {
         toast.error("Erro ao logar = "+ error.response.data);
         setError(error);
         setLoading(false);
       });
     }  
   } 


  return (
    <Fragment>
    <Breadcrumbs mainTitle='Gestão de Países' parent='Administrativo' title='Gestão de Países' />
    <Container fluid={true}>
      <Row>
        <Col sm='12'>
            
              <Form className='theme-form'>

                <div className='input-group flex-nowrap'>
                    <span className='btn btn-success input-group-text' onClick={() => btnNovo() }>{t(New)}</span> 

                    <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)}
                    onChange={(e) =>setFiltroName(e.target.value)}  value={filtroName} />
                    <span className='btn btn-primary input-group-text'  onClick={() => {
                                setOption('list');
                                setPage(0);
                                getListAll(requestFilter);
                              } }>{t(TextSearch)}</span>
                </div>
              </Form>
              <br />

             
              { 
              option === New || option === Edit  || option === "delete" 
              ?  <CountryFormData errors = {errors} loading={loading} error={error}  cancel={btnCancel} option={option}  delete={deleteCountry}  update={update} save={save} data = {country} />
              : option === "list" ? 
              <ListaData titulo={'Listando Países'}  columns={countryColumns} loading={loading} error={error}  list={countrys} /> : '' }
        </Col>
      </Row>
    </Container>
  </Fragment>
  /*
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de Países' parent='Home' title='Países' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <FormSearch btnNovo ={btnNovo}  setOption ={setOption} setPage={setPage} setSize={setSize} setSort={setSort} getListAll={getListAll}/>
               
              </CardHeader>
              <CardBody>{ 
                option === "new" || option === "edit"  || option === "delete" 
                ?  <CountryFormData loading={loading} error={error}  cancel={btnCancel} option={option}  delete={deleteCountry}  update={update} save={save} data = {country} />
                : option === "list" ? 
                <ListaData columns={countryColumns} loading={loading} error={error}  list={countrys} /> : ''}
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment> */
  );
};

export default RolePage;

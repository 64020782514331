import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import AlertError from '../../../../../CommonElements/AlertError';
import { crmListaClientesColumns } from '../../../../../Data/crm/CrmListaClientes';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';

const AllClientes = (props) => {
  const clients = props.clients;
 
  return (clients.length === 0 ? <SearchNotFoundClass message = {'Não foi encontrado nenhum cliente com os filtros informados! '}/> :
    <>
      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message} />
        ) :
          props.loading ? (
            <p>Loading...</p>
          ) : <DataTable
          pagination
          paginationServer
          paginationTotalRows={props.totalRows}
          paginationPerPage={props.perPage}
          onChangePage={props.handlePageChange}
          onChangeRowsPerPage={props.handlePerRowsChange}
          columns={crmListaClientesColumns}
          paginationDefaultPage={props.currentPage} // Define a página ativa
          data={clients}
          highlightOnHover={false} 
          responsive={true} 
          sortable={false} // Desabilita a ordenação para todas as colunas
          paginationComponentOptions={{
            noRowsPerPage: false, // Opcional: permite esconder a opção de selecionar registros por página
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            rowsPerPageText: "Registros por página:",
            selectAllRowsItemText: 'Todos',
          }}
          paginationNextDisabled={props.ultimaPagina}
        />
      }

      </div> 

    </>

  );
};

export default AllClientes; 
import React, { useEffect, useState } from "react";
import { Btn, H5, } from "../../../../../AbstractElements";
import { Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row, } from "reactstrap";
import companyService from "../../../../../Services/administrativo/company.service";
import SweetAlert from 'sweetalert2';
import { Loader } from "react-bootstrap-typeahead";


const CrmMinhaLojaRegrasDePontuacao = () => {
  var companyLocal = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(true); 
  
  const [valorParaPontuar, setValorParaPontuar] = useState(0);
  const [diasDeValidadeDosPontos, setDiasDeValidadeDosPontos] = useState(0);


  var request = {
    valorParaPontuar: valorParaPontuar,
    diasDeValidadeDosPontos : diasDeValidadeDosPontos
  }

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        setLoading(true);
        await companyService.findByCompanyId(companyLocal.id).then((resp) => {
          const dados = resp.data; 

          setDiasDeValidadeDosPontos(dados.diasDeValidadeDosPontos);
          setValorParaPontuar(dados.valorParaPontuar);
          

          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getCompanyData();
  }, []);


  const update = () => {

    return companyService.atualizarValorParaPontuar(companyLocal.id, request)
      .then((response) => {
        SweetAlert.fire({
          title: 'Regras de pontuação alterada com sucesso!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          reverseButtons: true,
        });


        companyLocal.valorParaPontuar = valorParaPontuar;
        companyLocal.diasDeValidadeDosPontos = diasDeValidadeDosPontos;
        localStorage.setItem('company', JSON.stringify(companyLocal));
        return response;
      });

  }

  return (loading ? <Loader show={loading}/> : 
    <>
      <Card>
        <CardBody>
          <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
            <fieldset>
              <legend><H5>Regras de pontuação</H5></legend>

              <Label className='form-label f-light' htmlFor='money'>
                <H5>{' Quanto o usuário deve comprá para ganhar um ponto?'}</H5>
              </Label> 
              <div className='position-relative'>
                <select className='form-select crypto-select warning' style={{ height: 'calc(3.5rem + 2px)', marginLeft: '10px', fontSize: '1.5rem' }}>
                  <option>{'R$'}</option>
                </select>
                <Input id='money'
                  className='form-control form-control-lg highlighted-input'
                  type='number'
                  onChange={(e) => setValorParaPontuar(e.target.value)} value={valorParaPontuar}
                  style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', padding: '20px' }}  />
              </div>
            <br/>
              <Label className='form-label f-light' htmlFor='money'>
                <H5>{'Quantidade de dias de validade dos pontos?'}</H5>
              </Label> 
              <div className='position-relative'>
                
                <Input id='money'
                  className='form-control form-control-lg highlighted-input'
                  type='number'
                  onChange={(e) => setDiasDeValidadeDosPontos(e.target.value)} value={diasDeValidadeDosPontos}
                  style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', padding: '20px' }}  />
              </div>
            </fieldset>
            </Form>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => update() }}   >{'Salvar'}</Btn>
        </CardFooter>
      </Card>
    </>
  );
};

export default CrmMinhaLojaRegrasDePontuacao; 
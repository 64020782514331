export const crmClientList = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
 
  {
    name: 'Name',
    selector: (row) => row.client.name,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Telefone',
    selector: (row) => row.client.cellphone,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'E-mail',
    selector: (row) => row.client.mail,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true
  },
];

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Bar } from 'react-chartjs-2'; 

const CrmMinhaLojaNPS = () => {
  const company = JSON.parse(localStorage.getItem('company'));
  const [npsScore, setNpsScore] = useState(0);

  useEffect(() => {
    
    const score = 75; // Aqui você buscaria o valor do NPS do backend
    setNpsScore(score);
  }, []);

  const data = {
    labels: ['Detratores', 'Neutros', 'Promotores'],
    datasets: [
      {
        label: 'Distribuição de Clientes',
        data: [10, 15, 75], // Exemplo de distribuição, ajuste conforme necessário
        backgroundColor: ['#ff6384', '#ffcd56', '#4bc0c0'],
      }
    ]
  };

  return (
    <>
      <Breadcrumbs mainTitle="Métricas" parent="Atendentes" title={company.name} />
      <Container fluid={true}>
        <Row className="widget-grid">
          <Col md="6">
            <h3>NPS da Empresa: <span style={{ fontWeight: 'bold' }}>{npsScore}</span></h3>
            <p>O NPS (Net Promoter Score) mede a satisfação dos clientes. Acompanhe a evolução e a distribuição.</p>
          </Col>
          <Col md="6">
            <div style={{ maxHeight: '300px', maxWidth: '400px' }}>
              <Bar data={data} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CrmMinhaLojaNPS;

import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges } from '../../../Constant';

const CommonModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} size={props.size} centered>
      <ModalHeader >
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      {props.exibeBotoes ?
      <ModalFooter>
        <Btn attrBtn={{ color: 'secondary',  onClick : props.toggler  } } >{Close}</Btn>        
        <Btn attrBtn={{ color: 'primary', onClick: props.funcao}}>{SaveChanges}</Btn> 
      </ModalFooter>
       : '' }
    </Modal>
  );
};

export default CommonModal;
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, H5, H6, P } from '../../../AbstractElements';
import { Login } from '../../../Constant';
import authService from '../../../Services/auth.service';
import Loader from '../../../Layout/Loader';
import Alerts from '../../../CommonElements/Alert';


const RecuperacaoSenha = () => {
    const { t } = useTranslation();

    const queryParameters = new URLSearchParams(window.location.search);
    const mailKey = queryParameters.get("key");
    const [confirmed, setConfirmed] = useState(false);
    const [password, setPassword] = useState('');
    const [repetirSenha, setRepetirSenha] = useState('');
    const [refreshed, setRefreshed] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        const validaLinkRecuperacaoSenha = async () => {
            try {
                await authService.validaLinkRecuperacaoSenha(mailKey).then((response) => {
                    let isConfirmed = response.data;
                    setConfirmed(isConfirmed);
                });
            } catch (error) {
                console.log('cancelled', error);
            }
        };
        validaLinkRecuperacaoSenha();
    }, [setConfirmed]);

    const [loading, setLoading] = useState(false);

    const atualizarSenha = (e) => {
        setLoading(true);

        if (password !== repetirSenha) {
            setErrors('Senhas não conferem!')
        } else {
            setErrors(null);


            return authService.atualizarSenha(mailKey, password, repetirSenha)
                .then((response) => {
                    setLoading(false);
                    setRefreshed(response.data);

                }).catch((errorResponse) => {

                }).finally((f) => {
                    setLoading(false);
                });
        }
    };
    return (
        !confirmed ? <>
            <section>
                <Container className='p-0 login-page' fluid={true}>
                    <Row className='m-0'>
                        <Col className='p-0'>
                            <div className='login-card'>
                                <div className='login-main'>
                                    <Alerts attrAlert={{ color: 'secondary  alert-dismissible' }} >
                                        <H4 attrH4={{ className: 'alert-heading' }} >{t('Sorry!')}</H4>
                                        <H6 attrH6={{ className: 'alert-heading' }} >{t('Esse link de recuperação está incorreto ou expirado. Verifique e tente novamente')}</H6>
                                    </Alerts>
                                    <P attrPara={{ className: 'mb-0 text-start' }}>

                                        <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                                            {t('Return to ') + t('Login')}
                                        </Link>
                                    </P>

                                </div></div></Col></Row>
                </Container>
            </section>

        </> :
            <Fragment>
                <section>
                    <Container className='p-0 login-page' fluid={true}>
                        <Row className='m-0'>
                            <Col className='p-0'>
                                <div className='login-card'>
                                    <div className='login-main'>
                                        {errors ? <Alerts attrAlert={{ color: 'secondary  alert-dismissible' }} >
                                            <H4 attrH4={{ className: 'alert-heading' }} >{t('Sorry!')}</H4>
                                            <H6 attrH6={{ className: 'alert-heading' }} >{t(errors)}</H6>
                                        </Alerts> : ''}

                                        {
                                            loading ? <Loader show={loading} /> :
                                                refreshed ?
                                                    <>
                                                        <P attrPara={{ className: 'mb-0 text-start' }}>
                                                            {t('Email e senha atualizado! Efetue o login?')}
                                                            <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                                                                {t('Login')}
                                                            </Link>
                                                        </P>

                                                    </> : (

                                                        <Form className='theme-form login-form'>
                                                            <H4>{t('Reset Your Password')}</H4>
                                                            <FormGroup>
                                                                <Label className='m-0 col-form-label'>{t('New Password')}</Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Input className='form-control' type='password' onChange={(e) => setPassword(e.target.value)} value={password} />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label className='m-0 col-form-label'>{t('Repeat Password')}</Label>
                                                                <Row>
                                                                    <Col>
                                                                        <Input className='form-control' type='password' onChange={(e) => setRepetirSenha(e.target.value)} value={repetirSenha} />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                            <FormGroup className='text-end'>
                                                                <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => atualizarSenha() }}>{t('Send')}</Btn>

                                                            </FormGroup>

                                                            <P attrPara={{ className: 'mb-0 text-start' }}>
                                                                {t('Already have an account?')}
                                                                <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                                                                    {t(Login)}
                                                                </Link>
                                                            </P>
                                                        </Form>
                                                    )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
    )
}

export default RecuperacaoSenha;
// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";


// //social app 
import CountryPage from "../Components/Pages/administrativo/country";
import StatePage from "../Components/Pages/administrativo/state";
import CityPage from "../Components/Pages/administrativo/city";
import RolePage from "../Components/Pages/administrativo/role";
import PerfilPage from "../Components/Pages/administrativo/perfil";
import PersonPage from "../Components/Pages/administrativo/person";
import CompanyPage from "../Components/Pages/administrativo/company";
import UserPage from "../Components/Pages/administrativo/user";


import AdministradorDashBoardDefault from "../Components/Pages/administrativo/dashboard";
import VisitanteDashBoardDefault from "../Components/Pages/visitante/dashboard";

//CRM
import CrmDashBoardDefault from "../Components/Pages/crm/dashboard"; 
import CrmClient from "../Components/Pages/crm/client";
import CrmProfileClient from "../Components/Pages/crm/profileclient";
import CrmOperator from "../Components/Pages/crm/operator";
import CrmGoal from "../Components/Pages/crm/goal";
import CrmReportBirthday from "../Components/Pages/crm/report/birthday";
import CrmClienteListaClientes from "../Components/Pages/crm/clientes/listaClientes";




import FaqPage from "../Components/Pages/Faq";

import BranchPage from "../Components/Pages/administrativo/branch";
import FileManagerContain from "../Services/administrativo/FileManager";
import CrmPontuarResgatar from "../Components/Pages/crm/pontuarresgatar";
import CrmClienteSegmentacoes from "../Components/Pages/crm/clientes/seguimentacoes";
import CrmMinhaLojaIdentidadeVisual from "../Components/Pages/crm/minhaloja/identidadevisual";
import CrmMinhaLojaSms from "../Components/Pages/crm/minhaloja/sms";
import CrmMinhaLojaInstagram from "../Components/Pages/crm/minhaloja/instagram";
import CrmMinhaLojaInformacoesBasicas from "../Components/Pages/crm/minhaloja/informacoesbasicas";
import CrmMinhaLojaParametrizacao from "../Components/Pages/crm/minhaloja/parametrizacao";
import CrmMinhaLojaAplicativo from "../Components/Pages/crm/minhaloja/aplicativo";
import NotificationPage from "../Components/Pages/Notificacoes"; 
import CrmClienteDashboard from "../Components/Pages/crm/clientes/dashboard"; 
import NovoClienteForm from "../Components/Pages/crm/clientes/novocliente";
import IntegrationPage from "../Components/Pages/administrativo/integration";
import CrmRecuperarCliente from "../Components/Pages/crm/recuperarcliente";
import CrmMinhaLojaComprarSms from "../Components/Pages/crm/minhaloja/sms/comprarsms";
import CrmAtendentesDesempenho from "../Components/Pages/crm/atendentes/desempenho";
import CrmAtendentesGerenciar from "../Components/Pages/crm/atendentes/gerenciar";
import CrmAtendentesMetas from "../Components/Pages/crm/atendentes/metas";
import CrmClientesMetricas from "../Components/Pages/crm/clientes/metricas";
import CrmMinhaLojaNPS from "../Components/Pages/crm/minhaloja/nps";



export const routes = [


  { path: `${process.env.PUBLIC_URL}/app/users/profile`, Component: <UsersProfileContain /> },
  { path: `${process.env.PUBLIC_URL}/app/users/edit`, Component: <UsersEditContain /> },
  { path: `${process.env.PUBLIC_URL}/app/users/cards`, Component: <UsersCardssContain /> },


  //Visistante
  { path: `${process.env.PUBLIC_URL}/visitante/dashboard`, Component: <VisitanteDashBoardDefault /> },

  //CRM
  { path: `${process.env.PUBLIC_URL}/crm/dashboard`, Component: <CrmDashBoardDefault /> },
  { path: `${process.env.PUBLIC_URL}/crm/pontuarresgatar`, Component: <CrmPontuarResgatar /> },
  { path: `${process.env.PUBLIC_URL}/crm/recuperarcliente`, Component: <CrmRecuperarCliente /> },
   
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/informacoesbasica`, Component: <CrmMinhaLojaInformacoesBasicas /> },
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/parametrizacao`, Component: <CrmMinhaLojaParametrizacao /> }, 
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/nps`, Component: <CrmMinhaLojaNPS /> }, 
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/identidadevisual`, Component: <CrmMinhaLojaIdentidadeVisual /> },
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/sms`, Component: <CrmMinhaLojaSms /> },
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/comprarsms`, Component: <CrmMinhaLojaComprarSms /> },
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/instagram`, Component: <CrmMinhaLojaInstagram /> },
  { path: `${process.env.PUBLIC_URL}/crm/minhaloja/aplicativo`, Component: <CrmMinhaLojaAplicativo /> },


  { path: `${process.env.PUBLIC_URL}/crm/clientes/aplicativo`, Component: <CrmMinhaLojaAplicativo/> },
  { path: `${process.env.PUBLIC_URL}/crm/clientes/novoclienteform`, Component: <NovoClienteForm/> },
  { path: `${process.env.PUBLIC_URL}/crm/clientes/dashboard`, Component: <CrmClienteDashboard/> },
  { path: `${process.env.PUBLIC_URL}/crm/clientes/metricas`, Component: <CrmClientesMetricas/> },

  { path: `${process.env.PUBLIC_URL}/crm/atendentes/gerenciar`, Component: <CrmAtendentesGerenciar/> },
  { path: `${process.env.PUBLIC_URL}/crm/atendentes/metas`, Component: <CrmAtendentesMetas/> },
  { path: `${process.env.PUBLIC_URL}/crm/atendentes/desempenho`, Component: <CrmAtendentesDesempenho/> },


  
  { path: `${process.env.PUBLIC_URL}/crm/report/birthdays`, Component: <CrmReportBirthday /> },
  { path: `${process.env.PUBLIC_URL}/crm/client`, Component: <CrmClient /> },
  { path: `${process.env.PUBLIC_URL}/crm/profileclient/:clienteId`, Component: <CrmProfileClient /> },
  { path: `${process.env.PUBLIC_URL}/crm/operator`, Component: <CrmOperator /> },
  { path: `${process.env.PUBLIC_URL}/crm/goal`, Component: <CrmGoal /> },
  { path: `${process.env.PUBLIC_URL}/crm/clientes/listaClientes`, Component: <CrmClienteListaClientes /> },
  { path: `${process.env.PUBLIC_URL}/crm/clientes/segmentacoes`, Component: <CrmClienteSegmentacoes/> }, 
  
  

  

  //Administrador 
  { path: `${process.env.PUBLIC_URL}/administrativo/dashboard`, Component: <AdministradorDashBoardDefault />, },
  { path: `${process.env.PUBLIC_URL}/administrativo/company`, Component: <CompanyPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/person`, Component: <PersonPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/branch`, Component: <BranchPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/user`, Component: <UserPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/country`, Component: <CountryPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/integration`, Component: <IntegrationPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/state`, Component: <StatePage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/city`, Component: <CityPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/role`, Component: <RolePage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/perfil`, Component: <PerfilPage /> },
  { path: `${process.env.PUBLIC_URL}/administrativo/file-manager`, Component: <FileManagerContain /> },

  //Faq
  { path: `${process.env.PUBLIC_URL}/faq`, Component: <FaqPage /> }, 
  { path: `${process.env.PUBLIC_URL}/notificacoes`, Component: <NotificationPage /> }, 


];

import React, { Fragment , useRef , useState , useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H4,  Image, Btn } from '../../../../AbstractElements'; 
import userService from '../../../../Services/administrativo/user.service';
import downloadService from '../../../../Services/utils/download.service';
import { Save } from '../../../../Constant';

const MyProfileEdit = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));  
  const [mail, setMail] = useState(user.mail);
  const userId = props.user.id; 
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');


  useEffect(() => {
   
    const fetchImage = async () => {
      try {
        const image = await downloadService.getUrlFoto(); 
        setImageUrl(image);
      } catch (error) {
        console.error('Erro ao baixar a imagem:', error);
      }
    };
    fetchImage();
  }, []); 
 

 
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    if (event.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);

      const response = await userService.uploadPhoto(userId, formData); 
      const image = await downloadService.getImagem(response.data); 
      console.log(response);
      setImageUrl(image); 

      //setUploaded(true);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Fragment>
         <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
                <H5>Meu perfil</H5>
            </CardHeader>
            <CardBody>
              
          <Form>
            <Row className='mb-2'>
              <div className='profile-title'>
                <div className='media'>
                  <Image attrImage={{ className: 'img-70 img-fluid m-r-5 rounded-circle b-r-10 m-0 step1', alt: '', src: `${imageUrl}` }} />
                  <i className='icofont icofont-pencil-alt-5'   onClick={handleIconClick}>
                    <input type="file" style={{ display: 'none' }}  ref={fileInputRef} onChange={(e) => handleSubmit(e)} accept="image/*"  />
                  </i>
                  <div className='media-body'>
                      <H5 attrH5={{ className: 'mb-1' }}>{user.name}</H5>
                  </div> 
                </div>
              </div>
            </Row>
            
            <FormGroup className='mb-3'>
              <Label className='form-label'>Usuário de login</Label>
              <Input className='form-control' readOnly disabled  value={mail} />
            </FormGroup>

            <FormGroup className='mb-3'>
              <Label className='form-label'>Senha Atual:</Label>
              <Input className='form-control' type='password' />
            </FormGroup>

            <FormGroup className='mb-3'>
              <Label className='form-label'>Nova Senha</Label>
              <Input className='form-control' type='password' />
            </FormGroup>

            <FormGroup className='mb-3'>
              <Label className='form-label'>Repetir Senha:</Label>
              <Input className='form-control' type='password' />
            </FormGroup>
            
            <div className='form-footer'>
              <Btn attrBtn={{ className: 'btn-block', color: 'primary' }}>{'Salvar'}</Btn>
            </div> 
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default MyProfileEdit;

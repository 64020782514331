import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import { Form, Input,  Col, Container, Row,  } from 'reactstrap';
import userService from '../../../../Services/administrativo/user.service';
import { toast } from "react-toastify";
import { Cancel, Delete, Edit, List, New, TextSearch } from "../../../../Constant";
import UserFormData from './user-form';
import CommonModal from '../../../../CommonElements/Modals/common/modal';
import perfilService from '../../../../Services/administrativo/perfil.service';
import { Edit2, Trash } from 'react-feather';
import UserListData from './user-list';
import UserPerfilForm from './user-perfil-form';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const UserPage = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const [option, setOption] = useState("");
  const [optionPerfil, setOptionPerfil] = useState("");
  const [user, setUser] = useState({});
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [perfilOfUser, setPerfilOfUser] = useState([]);
  const toggle = (user) => {
    setUser(user);

    if (open) setOpen(false);
    else setOpen(true);
    setOpen(!open);
    setOptionPerfil('');

  }

  useEffect(() => {
    console.log('Open atual = ' + open);
  }, [setOpen]);

  useEffect(() => {
    const getListAll = async () => {
      try {
        const resp = await perfilService.getAll();
        setPerfis(resp.data);
      } catch (error) {
      }
    };

    getListAll();
  }, [setPerfis]);

  var requestFilter = {
    name: name
  }

  const getListAll = async (e) => {
    setLoading(true);
    try {
      const response = await userService.getFilter(requestFilter, page, size);
      var userList = await response.data.content;
      setTotalRows(response.data.totalItens);
      const transformedItems = userList.map((user) => ({
        ...user,
        perfil: (
          <div>
            <span >
              <Btn attrBtn={{ style: style2, color: 'info', className: 'btn btn-xs', type: 'button', onClick: (e) => { toggle(user); btnUpdadeUser(user); } }}>Perfis</Btn>
            </span>
          </div>
        ),
        action: (
          <div>

            <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick: (e) => btnDelete(user) }}>
              <Trash style={{ width: '18px', height: '18px' }} className='me-2' />
            </Btn> {' '}
            &nbsp;&nbsp;
            <Btn attrBtn={{ style: style2, color: 'warning', className: 'btn btn-xs', type: 'button', onClick: (e) => btnEdit(user) }}>
              <Edit2 style={{ width: '18px', height: '18px' }} className='me-2' />
            </Btn>
          </div>
        )
      }
      )
      );

      setUsers(transformedItems);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
    return userList;
  }

  const btnEdit = (user) => {
    console.log(user);
    setOption(Edit);
    setUser(user); 
  }

  const btnUpdadeUser = (user) => {
    setPerfilOfUser(user.perfis);
  }


  const btnDelete = (user) => {
    console.log(user);
    setOption(Delete);
    setUser(user); 
  }

  const deleteUser = (request) => {
    if (request.id != null) {
      console.log("Entrou no botão deletar = " + request);
      return userService.delete(request)
        .then((response) => {
          toast.success("User excluído com sucesso!");
          getListAll();
          setOption(List);
          setUser({}); 
          return response;
        }).catch((error) => {
          setError(error);
          toast.error("Erro ao logar = " + error.response.data);
        });
    }
  }

  const btnNovo = () => {
    initialize();
    setOption(New);
  }


  const btnNovoPerfil = () => {
    setOptionPerfil('NewPerfil');

  }

  const initialize = () => {

    setUser(
      {

        id: -1,
        name: "",
        observation: "",
        code: "",
        standard: "1",
        status: "Active",
        person: {
          personType: 'FÍSICA'
        }
      });
  }

  const btnCancel = () => {
    initialize();
    setOption(List);
  }


  const save = (request) => {
    return userService.save(request)
      .then((response) => {
        initialize();
        setOption(List);
        toast.success("User cadastrado com sucesso!");
        getListAll();
        return response;
      }).catch((error) => {
        toast.error("Erro ao logar = " + error.response.data);
      });

  }

  const update = async (request) => {
    console.log(request);
    setLoading(true);
    if (request.id != null) {
      return userService.update(request)
        .then((response) => {
          toast.success("User alterado com sucesso!");
          getListAll();
          setOption(List);
          initialize();
          setLoading(false);
          return response;
        }).catch((error) => {
          toast.error("Erro ao logar = " + error.response.data);
          setError(error);
          setLoading(false);
        });
    }
  }

  const addPerfil = (novoItem) => {
    console.log('Adicionando o perfil: ' + novoItem.name);
    const novoArray = [...perfilOfUser, novoItem];
    setPerfilOfUser(novoArray);
    console.log('Quantidade após a adição: ' + perfilOfUser.length);
  };

  const removePerfil = (itemParaRemover) => {
    console.log('Removendo o perfil o perfil: ' + itemParaRemover.name);
    const novoArray = perfilOfUser.filter(item => item !== itemParaRemover);
    setPerfilOfUser(novoArray);
    console.log('Quantidade após a remoção: ' + perfilOfUser.length);

  }
  

  const handlePageChange = (page) => {
    setPage(page);
    getListAll();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(0);
    setSize(newPerPage);
    getListAll();
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de Usuários' parent='Home' title='Usuários' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form className='theme-form'>
              <div className='input-group flex-nowrap'>
                <span className='btn btn-success input-group-text' onClick={() => btnNovo()}>{t(New)}</span>
                <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)} onChange={(e) => setName(e.target.value)} value={name} />
                <span className='btn btn-primary input-group-text' onClick={() => {
                  setOption(List)
                  getListAll(requestFilter);
                }}>{t(TextSearch)}</span>
              </div>
            </Form>
            <br></br> {
              option === New || option === Edit || option === Delete
                ? <UserFormData loading={loading} error={error} cancel={btnCancel} option={option} delete={deleteUser} update={update} save={save} data={user} />
                : option === List ?
                  <UserListData totalRows={totalRows} loading={loading} error={error} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} list={users} /> : ''}
          </Col>
        </Row>
      </Container>
      <CommonModal isOpen={open} title={'Perfis'} toggler={toggle} size="lg">

        <Form className='theme-form'>
          <div className='input-group flex-nowrap'>
            <span className='btn btn-success input-group-text' onClick={() => btnNovoPerfil()}>{t(New)}</span>

            <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)} onChange={(e) => setName(e.target.value)} value={name} />
            <span className='btn btn-primary input-group-text' onClick={() => {}}>{t(TextSearch)}</span>
          </div>
        </Form>
        <br/>
        <UserPerfilForm user={user} option = {optionPerfil} />  
        

        
        <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >{Cancel} </Btn>
      </CommonModal>
    </Fragment>
  );
};

export default UserPage;

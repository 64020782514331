import React, { Fragment, useState, useEffect} from "react";
import { Breadcrumbs,} from "../../../../../AbstractElements";
import { Col, Container, Row,  } from "reactstrap"; 
import CrmEditProfileStore from "./EditProfileStore";

import crmCompanyService from "../../../../../Services/crm/crm.company.service";

const CrmMinhaLojaInformacoesBasicas = () => {
  const [company, setCompany] = useState({});
  const companyLocal = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(false); 


  useEffect(() => {
    const getCompany = async () => {
      try {
        setLoading(true);
        await crmCompanyService.getId(companyLocal.id).then((resp) => {
          setCompany(resp.data);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getCompany();
  }, [setCompany]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Informações Básicas' parent='CRM' title='Minha loja' />
      <Container fluid={true}>
        {loading ? 'Carregando' :
          <div className='user-profile social-app-profile'>
            <Row>
              <Col  >
                <CrmEditProfileStore company={company} />
              </Col>
            </Row>
          </div>}
      </Container>
    </Fragment>
  );
};

export default CrmMinhaLojaInformacoesBasicas; 
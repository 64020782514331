import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import { Btn, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel, Delete, Edit, New, Select } from "../../../../Constant";
import personTypeService from '../../../../Services/administrativo/personType.service';
import stateService from '../../../../Services/administrativo/state.service';
import cityService from '../../../../Services/administrativo/city.service';
import dateService from '../../../../Services/utils/date.service';
import cepService from '../../../../Services/utils/cep.service'; 
import companyService from '../../../../Services/administrativo/company.service';

 

const BranchFormData = (props) => { 

  
  const [option, setOption] = useState(props.option);

  const [id, setId] = useState(props.data.id);
  const [name, setName] = useState(props.data.name);
  const [listOfCompany, setListOfCompany] = useState([]); 
  const [company, setCompany] = useState(props.data.company ? props.data.company.id : -1);
 
  useEffect(() => {
    if (props.option !== option) {
      // Limpar o formulário aqui
      setOption(props.option);
    }
  }, [props.option]);
 


 
   
  useEffect(() => {
    const fetListOfCompany = async () => {
      try {
        const response = await companyService.getAll();
        const data = await response.data;
        setListOfCompany(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfCompany();
  }, []);  


  var request = {
    id: id,
    name: name,
    company: company
  }

  const retornaErro = (field) => {
    try {
      if (props.errors) {
        const errors = props.errors.filter(e => e.objectName === field);
        console.log(errors);
        return errors || []; // Retorna um array vazio se não houver erros
      }
      return []; // Retorna um array vazio se props ou props.errors forem nulos
    } catch (error) {
     // console.error('Erro ao filtrar erros:', error);
      return []; // Retorna um array vazio em caso de erro
    }
  };


  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }
        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
          <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              <H5>{option} Dados cadastrais</H5>
            </CardHeader>
            <CardBody><Row><Col md={3}>
              <FormGroup>
                <Label className="col-form-label">Id</Label>
                {option !== New ?                 
                  <Input className="form-control btn-square disabled" type="text" placeholder="Id" onChange={(e) => setId(e.target.value)} value={id} readOnly /> :
                  <Input className="form-control btn-square disabled" type="text" placeholder="Código será gerado automaticamente" disabled readOnly /> 
                }
              </FormGroup>
            </Col>

              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label">Company:</Label>
                  <select className='form-control' size='1' value={company} onChange={(e) => setCompany(e.target.value)}>
                      <option value={""} >{Select}</option>
                    {listOfCompany.map((companyItem) => (
                      <option value={companyItem.id} key={companyItem.id} selected={companyItem.id === company} >{companyItem.name}</option>
                    ))}
                  </select>
                </FormGroup>

              </Col>
              <Col md={6}><FormGroup>
                  <Label className="col-form-label"> Nome</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setName(e.target.value)} value={name} />
                  <span style={{ color: 'red' }}>{retornaErro('name')}</span>
                </FormGroup>
                </Col>
            </Row>
              </CardBody></Card>

        </Form>


        <CardFooter className="text-end">
          {
            option === Edit || option === New ?
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => option === Edit ? props.update(request) : props.save(request) }}  >{Submit}</Btn> :
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
          }
          <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>

      </Col>
    </Fragment>
  );
};
export default BranchFormData;

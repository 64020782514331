import { Col, Row, Container } from "reactstrap"

const TermoServicoPage = () => {

    return (
        <>
            <Container fluid={true} className='faq-section'>
                <Row>
                    <Col xs="12">
                        <div className="knowledgebase-bg"
                            style={{ backgroundImage: `url(${require('../../../../assets/images/knowledgebase/bg_1.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="knowledgebase-search">
                            <Container fluid={true} className='p-0 login-page'>
                                <Row>
                                    <Col xs='12'>
                                        <div>
                                            <h1>TERMOS DE SERVIÇO</h1>
                                            <p>
                                                Estes termos de serviço ("Termos") regem o uso do sistema [Nome do Sistema], incluindo todos os seus recursos e funcionalidades ("Sistema"), fornecido por [Nome da Empresa ou Indivíduo] ("Nós", "Nosso" ou "Empresa"). Ao acessar ou utilizar o Sistema, você ("Usuário" ou "Você") concorda com estes Termos.
                                            </p>
                                            <h3>1. Uso do Sistema</h3>
                                            <p>
                                                1.1 Você concorda em utilizar o Sistema apenas para os fins permitidos por estes Termos e de acordo com todas as leis e regulamentos aplicáveis.
                                            </p>
                                            <p>
                                                1.2 Você é responsável por manter a segurança de suas credenciais de acesso ao Sistema e por todas as atividades que ocorrerem sob sua conta.
                                            </p>
                                            <h3>2. Propriedade Intelectual</h3>
                                            <p>
                                                2.1 Todos os direitos de propriedade intelectual relacionados ao Sistema são de propriedade exclusiva da Empresa.
                                            </p>
                                            <p>
                                                2.2 Você concorda em não copiar, modificar, distribuir, vender ou sublicenciar qualquer parte do Sistema sem autorização prévia por escrito da Empresa.
                                            </p>

                                            <h3>3. Limitações de Responsabilidade</h3>
                                            <p>
                                                3.1 O Sistema é fornecido "no estado em que se encontra", sem garantias de qualquer tipo, expressas ou implícitas.
                                            </p>
                                            <p>
                                                3.2 A Empresa não será responsável por quaisquer danos diretos, indiretos, incidentais, especiais, consequentes ou punitivos decorrentes do uso ou incapacidade de usar o Sistema.
                                            </p>
                                            <h3>4. Modificações nos Termos de Serviço</h3>
                                            <p>
                                            4.1 A Empresa se reserva o direito de modificar estes Termos a qualquer momento, mediante aviso prévio razoável. O uso continuado do Sistema após tais modificações constitui sua aceitação dos Termos modificados.
                                            </p>
                                            <h3>5. Jurisdição e Lei Aplicável</h3>
                                            <p>
                                            5.1 Estes Termos serão regidos e interpretados de acordo com as leis do Brasil. Qualquer litígio decorrente ou relacionado a estes Termos será submetido exclusivamente à jurisdição dos tribunais localizados no Brasil.
                                            </p>
                                            <h3>6. Disposições Gerais</h3>
                                            <p>
                                            6.1 Estes Termos constituem o acordo integral entre Você e a Empresa em relação ao seu uso do Sistema e substituem todos os acordos anteriores ou contemporâneos, comunicações e propostas, sejam verbais ou escritos, entre Você e a Empresa.
                                            </p>
                                            <p>
                                            6.2 Se qualquer disposição destes Termos for considerada inválida ou inexequível, essa disposição será limitada ou eliminada na medida mínima necessária, e as disposições restantes destes Termos permanecerão em pleno vigor e efeito.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div></Col>
                </Row>

            </Container>
        </>


    );
}

export default TermoServicoPage;
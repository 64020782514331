import { Row, Col } from "react-bootstrap";
import VisitorsCard from "./VisitorsCard";
import UserService from "../../../../Services/administrativo/user.service";
import RecentSales from "./RecentSales";

const LeftGrid = () => {
    var hasRoleVisitants = UserService.isHasRole('can_view_crm_dashboard_visitants');

    return ( <>
            <Col xxl='4' xl='4' md='4' className='box-col-4'>
                <Row>
                {hasRoleVisitants ? <VisitorsCard /> : ''}
                </Row>
            </Col>
            <Col xxl='8' xl='8' sm='8' className='box-col-8'>
                <RecentSales/>
            </Col>
            </> );
};

export default LeftGrid;
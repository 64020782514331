import React from 'react';
import './Skeleton.css'; // Certifique-se de que o CSS para skeleton esteja aqui

const SkeletonTable = ({ rows = 5, columns = 4 }) => {
  const skeletonRows = Array.from({ length: rows });
  const skeletonColumns = Array.from({ length: columns });

  return (
    <table className="table">
      <thead>
        <tr>
          {skeletonColumns.map((_, index) => (
            <th key={index}>
              <div className="skeleton" style={{ height: '20px', width: '100%' }}></div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {skeletonRows.map((_, rowIndex) => (
          <tr key={rowIndex}>
            {skeletonColumns.map((_, colIndex) => (
              <td key={colIndex}>
                <div className="skeleton" style={{ height: '20px', width: '100%' }}></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SkeletonTable;

import React from 'react';
import { Card, CardBody, CardHeader, Col, Media, Row, UncontrolledTooltip } from 'reactstrap';
import { Btn, H5, H6, LI, UL } from '../../../../../AbstractElements';
import { ChevronDown, ChevronUp, Image } from 'react-feather';
import downloadService from '../../../../../Services/utils/download.service';
import StarRatingClass from '../../../../Bonus-Ui/Rating/StarRating';
import AvalicaoCliente from './AvaliacaoCliente';
import dateService from '../../../../../Services/utils/date.service';

const ComunicacoesComCliente = ({ cliente }) => {
   
    return (
        <>
            <Card>

                <CardBody className='socialprofile filter-cards-view'>
                     
                        <Media body>
                            <H6 attrH6={{ className: 'font-primary f-w-600' }}>{cliente.client.name}</H6>
                            <span className='d-block'>
                                <span>
                                    <i className='fa fa-comments-o'> </i>
                                    <span className='px-2'>
                                        {'Sms'}
                                        <span className='badge rounded-pill badge-light ms-2'>{'9'}</span>
                                    </span>
                                </span>
                            </span>
                            <span className='d-block'>
                                <span>
                                    <i className='fa fa-envelop'></i>
                                    <span className='px-2'>
                                        {'Emails'}
                                        <span className='badge rounded-pill badge-light ms-2'>{'9'}</span>
                                    </span>
                                </span>
                            </span>

                        </Media>
                   
                </CardBody>
            </Card>
        </>
    );
};

export default ComunicacoesComCliente;

import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import MyProfileEdit from './MyProfile';
import EditMyProfile from './EditMyProfile';
import userService from '../../../../Services/administrativo/user.service';

const UsersEditContain = () => {
  const userId = JSON.parse(localStorage.getItem('userId'));
  const [userEdit, setUserEdit] = useState({});
  const [loading, setLoading] = useState(true);

  console.log(userId);

  useEffect(() => {
    fetUser();
  }, []);

  const fetUser = async () => {
    try {
      const response = await userService.getId(userId);
      const data = await response.data;
      setUserEdit(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (loading ? '' :
    <Fragment>
      <Breadcrumbs mainTitle='Editar Perfil' parent='Usuário' title='Editar Perfil' />
      <Container fluid={true}>
        {loading ? 'Carregando' :
          <div className='edit-profile'>
            <Row>
              <Col xl='3'>
                <MyProfileEdit user={userEdit} />
              </Col>
              <Col xl='9'>
                <EditMyProfile person={userEdit.person} />
              </Col>
            </Row>
          </div>}
      </Container>
    </Fragment>
  );
};
export default UsersEditContain;

// Arquivo gerado de forma automática pelo gerador. 
import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import {  Form, Input, Col, Container, Row } from 'reactstrap';
import personService from '../../../../Services/administrativo/person.service';
import { toast } from "react-toastify";
import { New,   Edit, Delete, List } from "../../../../Constant";

import SweetAlert from 'sweetalert2';
import PersonListData from './person-list';
import PersonFormData from './person-form';
import { Edit2, Plus, Search, Trash } from 'react-feather';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const PersonPage = () => {
  const { t } = useTranslation();
  const [persons, setpersons] = useState([]);
  const [option, setOption] = useState("");
  const [person, setPerson] = useState({});
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  
  const [filtroName, setFiltroName] = useState(""); 

  var requestFilter = {
    name : filtroName
  }

  const getListAll = async (e) => {
    setLoading(true);
    try {
      const response = await personService.getFilter(requestFilter, page, size);
      var personList = await response.data.content;
      setTotalRows(response.data.totalItens);
      const transformedItems = personList.map((person) => ({
        ...person,
        action: (
          <div>
             <span>
              <Btn attrBtn={{ style: style2, color: 'warning', className: 'btn btn-xs', type: 'button', onClick: (e) => btnEdit(person) }}>
                <Edit2 style={{ width: '18px', height: '18px' }} className='me-2' />
              </Btn>
            </span>
            &nbsp;&nbsp;
            <span>
              <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick: (e) => btnDelete(person) }}>
                <Trash style={{ width: '18px', height: '18px' }} className='me-2' />
              </Btn>
            </span>{' '} 
           
          </div>
        )
      }
      )
      );

      setpersons(transformedItems);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
    return personList;
  }

  const btnEdit = (person) => {
    setOption(Edit);
    setPerson(person);
  }

  const btnDelete = (person) => {
    setOption(Delete);
    setPerson(person);
  }

  const deleteperson = (request) => {
    if (request.id != null && request.id > 0) {
      SweetAlert.fire({
        title: 'Are you right?',
        text: 'Uma vez excluído, você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          personService.delete(request).then((response) => {
            SweetAlert.fire(
              'Removed!',
              'Registro foi apagado com sucesso.',
              'success'
            );
            getListAll();
            setOption(List);
            setPerson({});
            return response;
          });

        } else {
          SweetAlert.fire(
            'The register went deleted with security!'
          );
        }
      });


    }
  }

  const btnNovo = () => {
    setOption(New);
    initialize();
  }

  const initialize = () => {
    setError(null);
    setPerson({ id: -1, name: "", corporateName: "", nickname: "", rg: "", phone: "", cellphone: "", mail: "", zipCode: "", address: "", number: "", complement: "", district: "", cpfCnpj: "", });
  }

  const btnCancel = () => {
    initialize();
    setOption(List);
  }


  const save = (request) => {
    return personService.save(request)
      .then((response) => {
        initialize();
        setOption(List);
        toast.success("Pessoa cadastrado com sucesso!");
        getListAll();
        return response;
      }).catch((error) => {
        setErrors(error.fieldErrors);
      });

  }

  const update = async (request) => {
    setLoading(true);
    if (request.id != null) {
      return personService.update(request)
        .then((response) => {
          toast.success("Pessoa alterado com sucesso!");
          getListAll();
          setOption(List);
          initialize();
          setLoading(false);
          return response;
        }).catch((error) => {
          toast.error("Erro ao logar = " + error.response.data);
          setError(error);
          setLoading(false);
        });
    }
  }
  const handlePageChange = (page) => {
    setPage(page);
    getListAll();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(0);
    setSize(newPerPage);
    getListAll();
  };


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de pessoas' parent='Home' title='Pessoas' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>

            <Form className='theme-form'>

              <div className='input-group flex-nowrap'>
                <span className='btn btn-success input-group-text' onClick={() => btnNovo()}>
                <Plus/>  {t(New)}</span>

                <Input className='form-control-plaintext' type='search' placeholder={t('Search')}    onChange={(e) =>setFiltroName(e.target.value)}  value={filtroName} />
                <span className='btn btn-primary input-group-text' onClick={() => {
                  setOption(List);
                  setPage(0);
                  getListAll(requestFilter);
                }}><Search/>   {t('Search')}</span>
              </div>
            </Form>

            <br />
            {
              option === New || option === Edit || option === Delete
                ? <PersonFormData errors={errors} loading={loading} error={error} cancel={btnCancel} option={option} delete={deleteperson} update={update} save={save} data={person} />
                : option === List ?
                  <PersonListData totalRows={totalRows} loading={loading} error={error} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} list={persons} /> : ''}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PersonPage;

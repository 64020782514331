import React  from "react";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Container, Row } from "reactstrap";  

const CrmAtendentesDesempenho = () => { 
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <>
     <Breadcrumbs mainTitle='Desempenho' parent='Atendentes' title={company.name} />
      <Container fluid={true}>
      <Row className="widget-grid">
         
        </Row>
      </Container> 
    </>
  );
};

export default CrmAtendentesDesempenho; 
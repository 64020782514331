import React, { useEffect, useState } from 'react';
import { CheckCircle, DivideCircle } from 'react-feather';
import { Card, CardBody, Col } from 'reactstrap';
import { Btn, P, Image, UL, LI, H6, H5, H4 } from '../../../../AbstractElements';
import user from '../../../../assets/images/user/user.png';
import { ClientCategoriesList } from './ClientCategoriesList';
import downloadService from '../../../../Services/utils/download.service';


const ClientSideBar = (props) => {
  const [isOpen, setIsOpen] = useState(false); 
  const company = JSON.parse(localStorage.getItem('company'));
  console.log(company);
   

  return (
    <Col xl='3' className='xl-30 box-col-12'>
      <div className='md-sidebar'>
        <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>To Do filter</Btn>
        <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
          <div className='email-left-aside'>
            <div className='email-left-aside'>
              <Card>
                <CardBody>
                  <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                    <div className='d-flex align-items-center'>
                      <div className='media-size-email'>
                        <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src: downloadService.getBase64PictureObject(company.picture), alt: '' }} />
                      </div>
                      <div className='flex-grow-1'>
                        <H4 attrH6={{ className: 'f-w-600' }}>{company.name}</H4>
                      </div>
                    </div>
                    <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                      <LI attrLI={{ className: 'border-0 nav-item' }}>
                        <hr></hr>
                      </LI>
                      {ClientCategoriesList.map((item, i) => (
                        <LI key={i} attrLI={{ className: 'border-0 nav-item' }}>
                          <a href='#javascript'>
                            <span className={`iconbg badge-light-${item.color}`}>{item.icon}</span>
                            <span className='title ms-2'>{item.title}</span>
                            {item.badge && <span className={`badge badge-${item.badgeColor ? item.badgeColor : item.color}`}>{item.badge}</span>}
                          </a>
                        </LI>
                      ))  }
                    </UL>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ClientSideBar;

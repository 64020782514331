
import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { perfilColumns } from '../../../../Data/administrativo/PerfilList';
import AlertError from '../../../../CommonElements/AlertError'; 
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { List } from 'react-feather';

const PerfilListData = (props) => {
    
  const perfilData = props.list;
 

  return (
    <Card className={'card-absolute'}>
    <CardHeader className={'bg-primary'}>
      <H5>  <List className='me-2' /> Listando Perfis</H5>
    </CardHeader>
    <CardBody>

      <div className='table-responsive product-table'>
      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message}/>
        ) :
        props.loading ? (
          <p>Loading...</p>
        ) :  <DataTable noHeader pagination paginationServer columns={perfilColumns} data={perfilData} highlightOnHover={true} striped={true} responsive={true} /> 
        }
        
      </div>
    </div></CardBody></Card>
  );
};
export default PerfilListData;

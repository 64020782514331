import React, { Fragment, useEffect, useState, useCallback } from "react"; 

const Profileclient = () => {
  
  return (
    <>
      <h1>asdf</h1>
      
    </>
  );
};

export default Profileclient; 

export const extratoSmsListColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },    
  {
    name: 'Número',
    selector: (row) => row.numero,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: 'Mensagem',
    selector: (row) => row.mensagem,
    sortable: true,
    center: true,
  },
  {
    name: 'Custo',
    selector: (row) => row.custo,
    sortable: true,
    center: true,
  },
  {
    name: 'Cliente',
    selector: (row) => row.codigoCliente,
    sortable: true,
    center: true,
  },
  {
    name: 'Cobrado',
    selector: (row) => (row.cobrado ? 'Sim' : 'Não'),
    sortable: true,
    center: true,
    minWidth: '150px',
    maxWidth: '150px',
  },
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
];

import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";
import clientService from "../../../../Services/administrativo/cliente.service";
import { Image } from "react-bootstrap";
import downloadService from "../../../../Services/utils/download.service";
import { Breadcrumbs, Btn, H4, H5, LI, P, UL, } from "../../../../AbstractElements";
import LoadingView from "../../../../CommonElements/Loading";
import { Link } from "react-feather";
import DateService from "../../../../Services/utils/date.service";
import CustomizerContext from "../../../../_helper/Customizer";
import ClientSideBar from "./TodoSideBar";
import DataTable from "react-data-table-component";
import { crmClientList } from "../../../../Data/crm/CrmClientList";

const CrmClient = () => {
  const [clients, setClients] = useState([]);
  const company = JSON.parse(localStorage.getItem('company'));
  const { layoutURL } = useContext(CustomizerContext);
  const [loading, setLoading] = useState(false);
  const [addTask, setAddTask] = useState('');
  const border_danger = useState(''); 

  useEffect(() => {
    const getClients = async () => {
      setLoading(true);
      try {
        await clientService.listAll(company.id).then((resp) => {
          setClients(resp.data);
          setLoading(false);
        });

      } catch (error) {
        console.log('cancelled', error);
        setLoading(false);
      }
    };
    getClients();
  }, [setClients]);

  const openTaskWrapper = () => {
    setAddTask(' visible');
    document.getElementById('addTaskBtn').classList.add('hide');
  };
  const closeTaskWrapper = () => {
    setAddTask('');
    document.getElementById('addTaskBtn').classList.remove('hide');
  };
  const onTaskChanged = (e) => {

  };
  const addNewTask = () => {

  };

  const handleRemoveTodo = (todoId) => {

  };

  const handleMarkedTodo = (itemId, itemStatus) => {

  };

  return (
    <>
      <Breadcrumbs mainTitle={'Clientes'} parent='Clientes' />
      <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
        <Row>
          <ClientSideBar />
          <Col xl='9' className='xl-70 box-col-12'>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <H5>Clientes</H5>
                <div className='d-flex align-items-center gap-3'>
                  <div className='todo'>
                    <div className='todo-list-wrapper'>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='table-responsive product-table'>
                  <DataTable noHeader pagination paginationServer columns={crmClientList} data={clients} highlightOnHover={true} striped={true} responsive={true} />
                </div>


              </CardBody>
            </Card>
          </Col>
        </Row>
       
      </Container>
    </>
  );
};

export default CrmClient; 
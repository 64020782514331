import React, { useEffect, useState } from "react"

import Context from './index';
import Loader from "../../Layout/Loader";
import authService from "../../Services/auth.service";
import { toast } from "react-toastify";
//import { useNavigate } from "react-router";

function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
            setAuthenticated(true);
        }
        setLoading(false);
    }, [])


    const updateTokenUser = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const response = await authService.refreshToken(refreshToken);
            setUser((user) => ({ ...user, refreshToken: response.data.accessToken }));
            localStorage.setItem('token', response.data.accessToken);

        } catch (error) {
            authService.logout();
            toast.success('Token expirado! Favor efetuar login novamente!'); 
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const token = localStorage.getItem('token');
            if (token) {
                updateTokenUser();
            }
        }, 10 * 60 * 1000); // Refresh every 10 minutes
        return () => clearInterval(interval);
    }, [user]);

    if (loading) {
        return <Loader />
    }

    return (
        <Context.Provider value={{
            setUser,
            user,
            setAuthenticated,
            authenticated,
            loading,
            token
        }}>
            {children}
        </Context.Provider>
    )
}


export { Context, AuthProvider };
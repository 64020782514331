import React, { Fragment, useState } from "react";
import { Row, Col, Card, CardBody } from 'reactstrap'
import Rating from 'react-rating';
import { H6 } from "../../../../../AbstractElements";

const AvalicaoCliente = ( {rating} ) => { 
    return (
        <>

            <Row className="rating">
                <Col sm="3">
                    <H6 attrH6={{ className: "mb-0 text-start" }} >{"NPS"}: </H6>
                </Col>
                <Col sm="9">
                
                    <Rating
                        start={1}  stop={11}
                        initialRating={rating}
                        emptySymbol="fa fa-star-o fa-2x"
                        fullSymbol="fa fa-star fa-2x"                         
                        readonly 
                    >
                    </Rating> 
                </Col>
               
            </Row>
        </>
    )
}

export default AvalicaoCliente;
export const branchColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  
  {
    name: 'Name',
    selector: (row) => row.person.name,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
];

import React, { Fragment} from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Col, Container, Row } from "reactstrap"; 
import LeftGrid from './LeftGrid';  

const AdministradorDashBoardDefault = () => { 
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' parent='Administrativo' title='Dashboard' />
      <Container fluid={true}>
        <Row className='size-column'>
          <LeftGrid />           
        </Row>
      </Container> 
    </Fragment>
  );
};

export default AdministradorDashBoardDefault; 
import axios from 'axios';

const CepService =  {

  getCep(cep) {
    cep = cep.replace('.', '').replace('-', '');
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

}

export default  CepService;

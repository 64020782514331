
import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { stateColumns } from '../../../../Data/financeiro/StateList'; 
import AlertError from '../../../../CommonElements/AlertError'; 
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { List } from 'react-feather';

const StateListData = (props) => {
    
  const stateData = props.list;
 

  return (
    
    <Card className={'card-absolute'}>
    <CardHeader className={'bg-primary'}>
      <H5><List/> Listando Estados</H5>
    </CardHeader>
    <CardBody>

      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message}/>
        ) :
        props.loading ? (
          <p>Loading...</p>
        ) :  <DataTable noHeader pagination paginationServer columns={stateColumns} data={stateData} highlightOnHover={true} striped={true} responsive={true} /> 
        }
        
      </div>
     </CardBody></Card>
  );
};
export default StateListData;

import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL + "/fanfave-api/perfil";

 class PerfilService {
  
  getFilter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter', request, { headers: authHeader() });
  }

  getAll() {    
    return axios
      .get(API_URL ,  { headers: authHeader() });
  }

  getModules() {    
    return axios
      .get(API_URL+ '/modules',  { headers: authHeader() });
  }
 
  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  }

  update(request) {    
    return axios
      .put(API_URL+"/"+request.id, request, { headers: authHeader() });
  }

  delete(request) {    
    return axios
      .delete(API_URL+"/"+request.id, { headers: authHeader() });
  }

  updateRole(perfilId, nameRole, checked) {    
    return axios
      .put(API_URL+"/updateRole/"+perfilId + "/"+ nameRole + "/"+ checked, {} , { headers: authHeader() });
  }
}  


const perfilService = new PerfilService();
export default perfilService;   
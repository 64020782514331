import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api";

 const UtilService =  {

    createRemocaoDadosPage(request) {    
    return axios
      .post(API_URL  + "/public/solicitacaoRemocaoDados" , request, { headers: authHeader() });
  }
 
}  

export default UtilService;

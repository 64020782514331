import React, {} from 'react'; 
import { Modal, ModalBody, ModalHeader } from 'reactstrap';


const ResgatarModal = ({ toggle, modal}) => { 

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} >
                <ModalHeader toggle={toggle}> Registrar Resgate </ModalHeader>
                <ModalBody> 

                </ModalBody>
            </Modal>
        </>
    );
};
export default ResgatarModal;
import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig';  

const API_URL = config.data.API_URL+"/fanfave-api/dashboard";

 const CrmDashboardService =  {
  
  recentRegisters(companyId, userId, period ) {    
    return axios
      .get(API_URL+ '/recentRegisters/'+companyId + '/'+userId + '/'+period,  { headers: authHeader() });
  },
  getListVisitantePorDiaDaSemana(companyId) {    
    return axios
      .get(API_URL+ '/comprasPorSemana/'+companyId,  { headers: authHeader() });
  },
  getContadoresDaEmpresa(companyId) {    
    return axios
      .get(API_URL+ '/contadores/'+companyId,  { headers: authHeader() });
  },

  
  
}  

export default   CrmDashboardService; 
 
  
export const countryColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: 'Code',
    selector: (row) => row.code,
    sortable: true,
    center: true,
    minWidth: '150px',
    maxWidth: '150px',
  },
  {
    name: 'Standard',
    selector: (row) => row.standard,
    sortable: true,
    center: true,
    minWidth: '150px',
    maxWidth: '150px',
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
    center: true,
    minWidth: '150px',
    maxWidth: '150px',
  },
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
];

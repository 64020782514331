import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import { Image, P } from '../../../AbstractElements';
import search from '../../../assets/images/search-not-found.png';

const SearchNotFoundClass = (props) => {
    return (
        <Fragment>
            <Col sm="12">
                <div>
                    <div className="search-not-found text-center">
                        <div>
                            <Image attrImage={{ src: `${search}`, alt: '', className: 'second-search' }} />
                            <P attrPara={{ className: 'mb-2' }} >{ props.message ? props.message : 'Desculpe, nenhum registro encontrado para sua pesquisa!'}</P>
                        </div >
                    </div >
                </div >
            </Col >
        </Fragment >
    );
};

export default SearchNotFoundClass;
import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/person";

 class PersonService   {
  
  getFilter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter', request, { headers: authHeader() });
  }

  findByCpfCnpj(cpfCnpj) {
    return axios
    .get(API_URL + '/cpfCnpj/' + cpfCnpj, { headers: authHeader() });
 
  }
  
  getAll() {    
    return axios
      .get(API_URL, { headers: authHeader() });
  }

  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  }

  update(companyId, request) {    
    return axios
      .put(API_URL+"/"+companyId, request, { headers: authHeader() });
  }

  delete(request) {    
    return axios
      .delete(API_URL+"/"+request.id, { headers: authHeader() });
  }
}  
 
export default  new PersonService();    
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import { Btn, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel, Delete, Select, ACTIVE, INACTIVE, New, Edit } from "../../../../Constant";
import countryService from '../../../../Services/administrativo/country.service';
import { Edit3, Plus, Trash } from 'react-feather';

const StateFormData = (props) => {

  const [id, setId] = useState(props.data.id);
  const [name, setName] = useState(props.data.name);
  const [acronym, setAcronym] = useState(props.data.acronym);
  const [zipCode1, setZipCode1] = useState(props.data.zipCode1);
  const [zipCode2, setZipCode2] = useState(props.data.zipCode2);
  const [country, setCountry] = useState(props.data.country ? props.data.country.id : -1);
  const [status, setStatus] = useState(props.data.status === "" ? ACTIVE : props.data.status);
  const option = props.option;
  const [listOfCountry, setListOfCountry] = useState([]);

  useEffect(() => {
    const fetListOfCountry = async () => {
      try {
        const response = await countryService.getAll();
        const data = await response.data;
        setListOfCountry(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfCountry();
  }, []); 


  var request = {
    id: id,
    name: name,
    acronym: acronym,
    zipCode1: zipCode1,
    zipCode2: zipCode2,
    status: status,
    country: +country
  }

  return (
    <>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : null
        }
 <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              
              {
                option === New ? <H5><Plus/> Adicionando Estado</H5> : 
                option === Edit ? <H5><Edit3/> Editando Estado</H5> :
                option === Delete ? <H5><Trash/> Apagando Estado</H5> : ''}   
              
            </CardHeader>
            <CardBody>
        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
          {option === "edit" ?
            <FormGroup>
              <Label className="col-form-label">Codigo</Label>
              <Input className="form-control" type="text" placeholder="Código" onChange={(e) => setId(e.target.value)} value={id} />
            </FormGroup> : ''
          }
          <FormGroup>
            <Label className="col-form-label">Status:</Label>
            <Label className="switch">
              <Input type="checkbox" checked={status === ACTIVE} value={status} onChange={(e) => setStatus(e.target.checked ? ACTIVE : INACTIVE)} />
              <span className="switch-state" ></span>
            </Label>
          </FormGroup>
          <FormGroup className='mb-3 col-6 p-r-0'>
            <Label className="col-form-label">País:</Label>
            <select className='form-control' size='1' value={country} onChange={(e) => setCountry(e.target.value)}>
              <option >{Select}</option>
              {listOfCountry.map((country) => (
                <option value={country.id} key={country.id} selected={country === country.id} >{country.name}</option>
              ))}
            </select>
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label">Nome</Label>
            <Input className="form-control" type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} value={name} />
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">acronym</Label>
            <Input className="form-control" type="text" placeholder="acronym" onChange={(e) => setAcronym(e.target.value)} value={acronym} />
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">zipCode1</Label>
            <Input className="form-control" type="text" placeholder="acronym" onChange={(e) => setZipCode1(e.target.value)} value={zipCode1} />
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">zipCode2</Label>
            <Input className="form-control" type="text" placeholder="acronym" onChange={(e) => setZipCode2(e.target.value)} value={zipCode2} />
          </FormGroup>


        </Form>
        </CardBody>
        <CardFooter className="text-end">
          {
            option === "edit" || option === "new" ?
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => option === 'edit' ? props.update(request) : props.save(request) }}  >{Submit}</Btn> :
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
          }
          <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>
        </Card>
      </Col>
    </>
  );
};
export default StateFormData;
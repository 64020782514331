import React, { Fragment, useState , useEffect} from 'react';
import { Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody} from 'reactstrap';
import { Btn, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel, Delete, Select, Edit, New } from "../../../../Constant"; 
import perfilService from '../../../../Services/administrativo/perfil.service'; 
import { Edit3, Plus, Trash } from 'react-feather';

const PerfilFormData = (props) => {
  const [modules, setModules] = useState([]);
  const [id, setId] = useState(props.data.id);
  const [name, setName] = useState(props.data.name);  
  const [module, setModule] = useState(props.data.module); 

  const option = props.option;

  var request = {    
    id : id,
    name: name,
    module: module,    
    knowledge : "knowledge"
  } 

  useEffect(() => {
    const getModules = async () => {
      try {
        const resp = await perfilService.getModules();
        setModules(resp.data);
        console.log(resp.data);
      } catch (error) {
        console.log('cancelled', error);
      }
    };

    getModules();
  }, [setModules]);
  
  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }
       
          <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
            {
                option === New ? <H5><Plus/> Adicionando Perfil</H5> : 
                option === Edit ? <H5><Edit3/> Editando Perfil</H5> :
                option === Delete ? <H5><Trash/> Apagando Perfil</H5> : ''}   
                
            </CardHeader>
            <CardBody>
              

        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">  
          { option === "edit" ?       
            <FormGroup>
                <Label className="col-form-label">Codigo</Label>
                <Input className="form-control" type="text" placeholder="Código" onChange={(e) => setId(e.target.value)} value={id}/>
            </FormGroup> : ''
          }
         
          <FormGroup>
              <Label className="col-form-label">Nome</Label>
              <Input className="form-control" type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} value={name}/>
          </FormGroup>
          <FormGroup>
              <Label className="col-form-label">Módulo</Label>
                <select className='form-control' size='1' value={module} onChange={(e) => setModule(e.target.value)}>
                  <option >{Select}</option>
                  {modules && modules.map((item) => (
                    <option value={item} key={item} selected={item === module} >{item}</option>
                  ))}
                </select>              
          </FormGroup>
          
                
        </Form>
        </CardBody>
        <CardFooter className="text-end">
          {   
            option === Edit || option === New ?  
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) =>  option ===Edit ? props.update(request) : props.save(request) }}  >{Submit}</Btn> : 
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) =>  props.delete(request) }} >{Delete}</Btn> 
          }            
          <Btn attrBtn={{ color: "secondary", type: "submit" ,  onClick: (e) =>  props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>  
        </Card>
      </Col>       
    </Fragment>
  );
};
export default PerfilFormData;

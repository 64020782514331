import React, { Fragment} from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Col, Container, Row } from "reactstrap"; 

const CrmGoal = () => { 
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Metas' parent='Home' title='Metas' />
      <Container fluid={true}>
         
      </Container> 
    </Fragment>
  );
};

export default CrmGoal; 
import React, { Fragment, useState } from 'react';
import { Col, Form, FormGroup, Label, Input} from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel, Delete, ACTIVE, INACTIVE } from "../../../../Constant"; 
import Loader from '../../../../Layout/Loader';

const CountryFormData = (props) => {
  
  const [id, setId] = useState(props.data.id);
  const [name, setName] = useState(props.data.name);
  const [code, setCode] = useState(props.data.code); 
  const [status, setStatus] = useState(props.data.status === "" ? "ACTIVE" : props.data.status); 
  const [standard, setStandard] = useState(props.data.standard);  
  const option = props.option;

  var request = {    
    id : id,
    name: name, 
    code: code,
    standard: standard,
    status : status 
  } 
  
  return (
    <Fragment>
      <Col sm="12">        
        {props.loading ? (
          <Loader show={props.loading}/>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : null 
        }

        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">  
          { option === "edit" ?       
            <FormGroup>
                <Label className="col-form-label">Codigo</Label>
                <Input className="form-control" type="text" placeholder="Código" onChange={(e) => setId(e.target.value)} value={id}/>
            </FormGroup> : ''
          }
          <FormGroup>
            <Label className="col-form-label">Status:</Label>
            <Label className="switch">
                <Input type="checkbox" checked={status === ACTIVE} value={status} onChange={(e) => setStatus(e.target.checked ? ACTIVE : INACTIVE)} />
                <span className="switch-state" ></span>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label">Standard:</Label>
            <Label className="switch">
                <Input type="checkbox" checked={standard  === '1'} value={standard} onChange={(e) => setStandard(e.target.checked ? true : false)} />
                <span className="switch-state" ></span>
            </Label>
          </FormGroup>
          <FormGroup>
              <Label className="col-form-label">Code</Label>
              <Input className="form-control" type="text" placeholder="Número" onChange={(e) => setCode(e.target.value)} value={code}/>
          </FormGroup>
          <FormGroup>
              <Label className="col-form-label">Nome</Label>
              <Input className="form-control" type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} value={name}/>
          </FormGroup>
        </Form>
        <CardFooter className="text-end">
          {   
            option === "edit" || option === "new" ?  
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) =>  option ==='edit' ? props.update(request) : props.save(request) }}  >{Submit}</Btn> : 
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) =>  props.delete(request) }} >{Delete}</Btn> 
          }            
          <Btn attrBtn={{ color: "secondary", type: "submit" ,  onClick: (e) =>  props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>  
      </Col>       
    </Fragment>
  );
};
export default CountryFormData;

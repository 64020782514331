import React, { Fragment, useState } from "react";
import { Container } from 'reactstrap'
import { H2, H4, LI, UL } from "../../../AbstractElements";
import authService from '../../../Services/auth.service'; 

const ConfirmationMail = () => {
   const queryParameters = new URLSearchParams(window.location.search);
   const mailKey = queryParameters.get("key");
   const [confirmed, setConfirmed] = useState(false);

    const mailConfirmation = async (mailKey) => { 
        return authService.mailConfirmation(mailKey).then((response) => {
            let isConfirmed = response.data;
            setConfirmed(isConfirmed);
        });
    };

    mailConfirmation(mailKey);

    return (
        
        <Fragment>
            <section className="maintenance-sec">
                <div className="page-wrapper">
                    <div className="error-wrapper maintenance-bg">
                        <Container>
                            <UL attrUL={{ className: "maintenance-icons flex-row" }} >
                                <LI><i className="fa fa-cog"></i></LI>
                                <LI><i className="fa fa-cog"></i></LI>
                                <LI><i className="fa fa-cog"></i></LI>
                            </UL>
                            <div className="maintenance-heading">
                                <H2 attrH2={{ className: "headline" }} >{
                                confirmed ? (
                                    <h1>EMAIL CONFIRMADO</h1>
                                  ) : <h1>ERRO AO CONFIRMAR</h1>
                                }</H2>
                            </div>
                            <H4 attrH4={{ className: "sub-content" }} >Our Site is Currently under maintenance We will be back Shortly<br />Thank You For Patience</H4>
                            <div><a className="btn btn-primary btn-lg text-light" href="/login">Efetuar Login</a></div>
                        </Container>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ConfirmationMail;
 
  
export const userPerfilColumns = [
  
  {
    name: 'Empresa',
    selector: (row) => row.companyName,
    sortable: true,
    center: true,
  },
  
  {
    name: 'Módulo',
    selector: (row) => row.module,
    sortable: true,
    center: true,
  },
  {
    name: 'Name',
    selector: (row) => row.perfilName,
    sortable: true,
    center: true,
  },
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: false,
    maxWidth: '100px',
  },
  
];

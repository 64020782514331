import React, { useEffect, useState } from 'react';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import { H3 } from '../../../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { extratoPontuacaoClienteColumns } from '../../../../../Data/crm/ExtratoPontuacaoClienteList';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';

const ExtratoResgateClienteList = ({ cliente }) => {
    const [loading, setLoading] = useState(true);
    const [extratoResgate, setExtratoResgate] = useState([]);


    useEffect(() => {
        const getPontuacoes = async () => {
            setLoading(true);
            getExtratoPontuacaoClienteList();
            setLoading(false);
        };
        getPontuacoes();
    }, [])


    const getExtratoPontuacaoClienteList = async () => {
        setLoading(true);
        try {
            const response = await clienteService.getExtrato(cliente.id);
            const data = await response.data;
            setExtratoResgate(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (loading ? 'Carregando' : extratoResgate.length === 0 ? <SearchNotFoundClass message = {'Não foi encontrado nenhum registro de resgate para esse cliente! '}/> :
        <><H3>Resgates</H3>
            <DataTable noHeader columns={extratoPontuacaoClienteColumns} data={extratoResgate} highlightOnHover={true} striped={true} responsive={true} />
        </>
    );
};

export default ExtratoResgateClienteList;

import React, { useEffect, useState } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'; 
import EnviarMensagemEmailForm from './EnviarMensagemEmailForm'; 
import EnviarMensagemSmsForm from './EnviarMensagemSmsForm'; 
import EnviarMensagemPushNotificationForm from './EnviarMensagemPushNotificationForm';
import { Alerts, H4, H6 } from '../../../../../AbstractElements';
import { Sorry } from '../../../../../Constant';

const EnviarMensagemCliente = ({ toggle, modal, cliente, clients , totalClientes, requestFilter}) => {
    const [BasicLineTab, setBasicLineTab] = useState('1'); 
    

    return (<Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='xl'>
                <ModalHeader toggle={toggle}><b>Enviando mensagem para {cliente.name ? cliente.name : (totalClientes + ' cliente(s)')}</b></ModalHeader>
                <ModalBody>

                    {
                    totalClientes === 0 ? <Alerts attrAlert={{ color: 'secondary ' }} >
                    <H4 attrH4={{ className: 'alert-heading' }} >Desculpe!</H4>
                    <H6 attrH6={{ className: 'alert-heading' }} >Não existe cliente para enviar mensagem!</H6>
                  </Alerts> : 



                    <Row>
                        <Col className='xl-100'>
                            <Nav className='border-tab d-flex' tabs>
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={'w-100' + BasicLineTab === '1' ? ' active btn btn-succes' : ''} onClick={() => setBasicLineTab('1')}>
                                        <i className="icofont icofont-envelope"></i>
                                        {'Email'}
                                    </NavLink>
                                </NavItem>
                                { /*<NavItem className="flex-grow-1">
                                    <NavLink href='#' className={BasicLineTab === '2' ? 'active' : ''} onClick={() => setBasicLineTab('2')}>
                                        <i className="icofont icofont-brand-whatsapp"></i>
                                        {'Whatsapp'}
                                    </NavLink>
                                </NavItem> */ }
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={BasicLineTab === '3' ? 'active' : ''} onClick={() => setBasicLineTab('3')}>
                                        <i className="icofont icofont-ui-messaging"></i>
                                        {'SMS'}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={BasicLineTab === '3' ? 'active' : ''} onClick={() => setBasicLineTab('4')}>
                                        <i className="icofont icofont-ui-messaging"></i>
                                        {'Push Notification'}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={BasicLineTab}>
                                <TabPane className='fade show' tabId='1'>
                                     <EnviarMensagemEmailForm cliente={cliente.userId} clients = {clients} totalClientes = {totalClientes} requestFilter = {requestFilter} />   
                                </TabPane>
                                { /*  <TabPane tabId='2'>
                               <EnviarMensagemWhatsappForm cliente={cliente.userId}  clients = {clients}  totalClientes = {totalClientes} requestFilter = {requestFilter} />  

                                </TabPane> */}
                                <TabPane tabId='3'>
                                  <EnviarMensagemSmsForm cliente={cliente.userId}  clients = {clients}  totalClientes = {totalClientes}  requestFilter = {requestFilter} /> 

                                </TabPane>
                                <TabPane tabId='4'> 
                                <EnviarMensagemPushNotificationForm cliente={cliente.userId}  clients = {clients}  totalClientes = {totalClientes} requestFilter = {requestFilter}  />   

                                </TabPane>
                            </TabContent>

                        </Col>
                    </Row>
}

                </ModalBody>
            </Modal> 
    );
};
export default EnviarMensagemCliente;
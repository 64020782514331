import DateService from "../../Services/utils/date.service";

export const crmListaClientesColumns  = [
   
    {
        name: 'Id',
        selector: (row) => row.userId,
        maxWidth: '60px' 
       
    },
    {
        name: 'Nome',
        selector: (row) => row.name,
    },
   
    {
        name: 'Posição',
        selector: (row) => row.posicao,
        maxWidth: '120px' 
    },
    {
        name: 'Data Cadastro',
        selector: (row) => row.initialDate ,
    },
    {
        name: 'Última Compra',
        selector: (row) => DateService.getDateTimeSeparado(row.lastCallBack),
    },
    {
        name: 'Status',
        selector: (row) => row.status,
        maxWidth: '80px' 
    },
    {
        name: 'NPS',
        selector: (row) => row.nps,
        maxWidth: '80px' 
    },
  
    {
        name: 'Ações',
        selector: (row) => row.action,
        maxWidth: '80px' 
      },
  
  
];
 
import React, { Fragment, useEffect, useState } from "react";
import { Btn, H5, } from "../../../../../AbstractElements";
import { Card, CardBody, CardFooter, Form, Input, Label, } from "reactstrap";
import companyService from "../../../../../Services/administrativo/company.service";
import SweetAlert from 'sweetalert2';
import diafuncionamentoService from "../../../../../Services/administrativo/diaDeFuncionamento.service";

const CrmMinhaLojaDiaDeFuncionamento = () => {
  var companyLocal = JSON.parse(localStorage.getItem('company')); 
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDiasDaSemana = async () => {
      try {
        setLoading(true);
        await diafuncionamentoService.findByCompanyId(companyLocal.id).then((resp) => {
          const dados = resp.data;

          const novoDiasFuncionamento = {
            DOMINGO: {descricao: 'Domingo', habilitado: false, abertura: '', fechamento: '' },
            SEGUNDA_FEIRA: {descricao: 'Segunda-feira', habilitado: false, abertura: '', fechamento: '' },
            TERCA_FEIRA: { descricao: 'Terça-feira',habilitado: false, abertura: '', fechamento: '' },
            QUARTA_FEIRA: {descricao: 'Quarta-feira', habilitado: false, abertura: '', fechamento: '' },
            QUINTA_FEIRA: {descricao: 'Quinta-feira', habilitado: false, abertura: '', fechamento: '' },
            SEXTA_FEIRA: { descricao: 'Sexta-feira',habilitado: false, abertura: '', fechamento: '' },
            SABADO: { descricao: 'Sábado',habilitado: false, abertura: '', fechamento: '' },
           
          };
    
          // Itera pelos dados retornados pela API e mapeia para o estado
          dados.forEach((dia) => {
            const diaSemana = dia.diaSemana;

            if (diaSemana) {
              novoDiasFuncionamento[diaSemana] = { 
                descricao: novoDiasFuncionamento[diaSemana].descricao,
                diaSemana : diaSemana,
                habilitado: true,
                abertura: dia.horarioAbertura.slice(0, 5), // Retira os segundos (HH:mm:ss -> HH:mm)
                fechamento: dia.horarioFechamento.slice(0, 5),
              };
            }
          });
    
          // Atualiza o estado com os novos valores
          setDiasFuncionamento(novoDiasFuncionamento);

          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
     getDiasDaSemana();
  }, []);

 
  const validateHours = () => {
    const newErrors = {};

    // Valida se todos os dias habilitados possuem horários válidos
    Object.keys(diasFuncionamento).forEach((dia) => {
      const { habilitado, abertura, fechamento } = diasFuncionamento[dia];
      if (habilitado) {
        if (!abertura || !fechamento) {
          newErrors[dia] = 'Os horários de abertura e fechamento devem ser preenchidos.';
        } else if (abertura >= fechamento) {
          newErrors[dia] = 'O horário de fechamento deve ser posterior ao horário de abertura.';
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Retorna true se não houver erros
  };



  const [diasFuncionamento, setDiasFuncionamento] = useState({

    DOMINGO: { descricao: 'Domingo', habilitado: false, abertura: '', fechamento: '' },
    SEGUNDA_FEIRA: { descricao: 'Segunda-feira', habilitado: true, abertura: '08:00', fechamento: '18:00' },
    TERCA_FEIRA: { descricao: 'Terça-feira', habilitado: true, abertura: '08:00', fechamento: '18:00' },
    QUARTA_FEIRA: { descricao: 'Quarta-feira', habilitado: true, abertura: '08:00', fechamento: '18:00' },
    QUINTA_FEIRA: { descricao: 'Quinta-feira', habilitado: true, abertura: '08:00', fechamento: '18:00' },
    SEXTA_FEIRA: { descricao: 'Sexta-feira', habilitado: true, abertura: '08:00', fechamento: '18:00' },
    SABADO: { descricao: 'Sábado', habilitado: false, abertura: '08:00', fechamento: '12:00' },
  });


  const update = () => {

    if (!validateHours()) {
      SweetAlert.fire({
        title: 'Erro',
        text: 'Existem erros no preenchimento dos horários. Por favor, corrija-os.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    
    const diasArray = transformarDiasParaArray();
    return companyService.atualizarDiasDeFuncionamento(companyLocal.id, diasArray)
      .then((response) => {

        SweetAlert.fire({
          title: 'Informações dias de funcionamento alterada com sucesso!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          reverseButtons: true,
        });


        return response;
      });

  }
  const transformarDiasParaArray = () => {
    return Object.keys(diasFuncionamento).map((dia) => ({
      companyId: companyLocal.id,
      diaSemana: dia,
      horarioAbertura: diasFuncionamento[dia].abertura,
      horarioFechamento: diasFuncionamento[dia].fechamento,
      habilitado: diasFuncionamento[dia].habilitado,
    }));
  };

  const handleCheckboxChange = (dia) => {
    setDiasFuncionamento((prevState) => ({
      ...prevState,
      [dia]: {
        ...prevState[dia],
        habilitado: !prevState[dia].habilitado, // Alterna o valor de habilitado corretamente
      },
    }));
  };

  const handleHorarioChange = (dia, tipo, valor) => {
    setDiasFuncionamento({
      ...diasFuncionamento,
      [dia]: {
        ...diasFuncionamento[dia],
        [tipo]: valor
      }
    });
  };

  return (loading ? 'Carregando' :
    <>
      <Card>
        <CardBody>
          <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
            <fieldset>
              <legend><H5>Dias de funcionamento</H5></legend>

              {Object.keys(diasFuncionamento).map((dia) => (
                <div className="row align-items-center mt-2" key={dia}>
                  <div className="col-md-1">
                    <Label className="d-block" for="chk-ani">
                      <Input className="checkbox_animated"
                        type="checkbox"
                        id="domingoCheckbox" defaultChecked
                        checked={diasFuncionamento[dia].habilitado}
                        onChange={() => handleCheckboxChange(dia)}
                      />

                    </Label>
                  </div>
                  <div className="col-md-3">
                    <label className="form-label" htmlFor={`${dia}Checkbox`}>
                      {diasFuncionamento[dia].descricao}
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="time"
                      className="form-control"
                      value={diasFuncionamento[dia].abertura}
                      disabled={!diasFuncionamento[dia].habilitado}
                      onChange={(e) => handleHorarioChange(dia, 'abertura', e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="time"
                      className="form-control"
                      value={diasFuncionamento[dia].fechamento}
                      disabled={!diasFuncionamento[dia].habilitado}
                      onChange={(e) => handleHorarioChange(dia, 'fechamento', e.target.value)}
                    />
                  </div>
                </div>
              ))}

            </fieldset>

          </Form>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => update() }}   >{'Salvar'}</Btn>
        </CardFooter>
      </Card>
    </>
  );
};

export default CrmMinhaLojaDiaDeFuncionamento; 
import React, { Fragment, useState  , useEffect} from 'react';
import { Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody} from 'reactstrap';
import { Btn, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel, Delete, ACTIVE, INACTIVE , Select, New, Edit } from "../../../../Constant"; 
import stateService from '../../../../Services/administrativo/state.service';
import { Edit3, Plus, Trash } from 'react-feather';

const CityFormData = (props) => {
  
  const [id, setId] = useState(props.data.id);
  const [name, setName] = useState(props.data.name);
  const [code, setCode] = useState(props.data.code);
  const [stateId, setStateId] = useState(props.data.state ? props.data.state.id : -1);
  const [status, setStatus] = useState(props.data.status === "" ? ACTIVE : props.data.status); 
  const [standard, setStandard] = useState(props.data.setStandard); 
  const option = props.option;

  const [listOfState, setListOfState] = useState([]);

  useEffect(() => {
    const fetListOfState = async () => {
      try {
        const response = await stateService.getAll();
        const data = await response.data;
        setListOfState(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfState();
  }, []); 



  var request = {    
    id : id,
    name: name,
    code: code, 
    status : status,
    state:  +stateId
     
  } 
  
  return (
    <Fragment>
      <Col sm="12">        
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : null 
        }
<Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              
              {
                option === New ? <H5><Plus/> Adicionando Cidades</H5> : 
                option === Edit ? <H5><Edit3/> Editando Cidades</H5> :
                option === Delete ? <H5><Trash/> Apagando Cidades</H5> : ''}   
              
            </CardHeader>
            <CardBody>
        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">  
          { option === "edit" ?       
            <FormGroup>
                <Label className="col-form-label">Codigo</Label>
                <Input className="form-control" type="text" placeholder="Código" onChange={(e) => setId(e.target.value)} value={id}/>
            </FormGroup> : ''
          }
          <FormGroup>
            <Label className="col-form-label">Status:</Label>
            <Label className="switch">
                <Input type="checkbox" checked={status === ACTIVE} value={status} onChange={(e) => setStatus(e.target.checked ? ACTIVE : INACTIVE)} />
                <span className="switch-state" ></span>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label">Standard:</Label>
            <Label className="switch">
                <Input type="checkbox" checked={standard} value={standard} onChange={(e) => setStandard(e.target.checked ? true : false)} />
                <span className="switch-state" ></span>
            </Label>
          </FormGroup>
          <FormGroup className='mb-3 col-6 p-r-0'>
            <Label className="col-form-label">Estado:</Label>
            <select className='form-control' size='1' value={stateId} onChange={(e) => setStateId(e.target.value)}>
              <option >{Select}</option>
              {listOfState.map((state) => (
                <option value={state.id} key={state.id} selected={state === state.id} >{state.name}</option>
              ))}
            </select>
          </FormGroup>
          <FormGroup>
              <Label className="col-form-label">Code</Label>
              <Input className="form-control" type="text" placeholder="Número" onChange={(e) => setCode(e.target.value)} value={code}/>
          </FormGroup>
          <FormGroup>
              <Label className="col-form-label">Nome</Label>
              <Input className="form-control" type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} value={name}/>
          </FormGroup>
              
        </Form>
        </CardBody>
        <CardFooter className="text-end">
          {   
            option === "edit" || option === "new" ?  
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) =>  option ==='edit' ? props.update(request) : props.save(request) }}  >{Submit}</Btn> : 
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) =>  props.delete(request) }} >{Delete}</Btn> 
          }            
          <Btn attrBtn={{ color: "secondary", type: "submit" ,  onClick: (e) =>  props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>  
        </Card>
      </Col>       
    </Fragment>
  );
};
export default CityFormData;

import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import { Image, P } from '../../../AbstractElements';
import search from '../../../assets/images/search-not-found.png';

const ButtonSearchNotAction = (props) => {
    return (
        <Fragment>
            <Col sm="12">
                <div>
                    <div className="search-not-found text-center">
                        <div>
                           
                            <P attrPara={{ className: 'mb-2' }} >{ props.message ? props.message : 'Filtros atualizados, clique no botão pesquisar para realizar a consulta!'}</P>
                        </div >
                    </div >
                </div >
            </Col >
        </Fragment >
    );
};

export default ButtonSearchNotAction;
import React, { Fragment} from "react";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Col, Container, Row } from "reactstrap";   

const CrmReportBirthday = () => { 
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Aniversariantes' parent='CRM' title='Relatórios / Aniversariantes' />
      <Container fluid={true}>
         
      </Container> 
    </Fragment>
  );
};

export default CrmReportBirthday; 
import React, { useEffect, useState } from 'react';
import integrationService from '../../../../Services/administrativo/integration.service';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { Btn } from '../../../../AbstractElements';


const CompanyIntegrationForm = (props) => {
  const [listOfIntegration, setListOfIntegration] = useState([]);
  const [integrationId, setIntegrationId] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form data
  };

  useEffect(() => {
    const fetListOfPerfil = async () => {
      try {
        const response = await integrationService.getAll();
        const data = await response.data;
        setListOfIntegration(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfPerfil();
  }, []);


  return (
    props.option === 'NewIntegration' ?
      <>
        <form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST" onSubmit={handleSubmit}>
          <Row>
            <Col></Col>
          </Row>
          <FormGroup>
            <Label className="col-form-label">Integrações:</Label>
            <Input type="select" value={integrationId} onChange={(e) => setIntegrationId(e.target.value)}>
              <option>{'Selecione'}</option>
              {listOfIntegration.map((integration) => (
                <option key={integration.id} value={integration.id}>
                  {integration.descricao}
                </option>
              ))}
            </Input>
          </FormGroup>

          <div>
            <Btn attrBtn={{ color: 'success', onClick: props.toggle }} >{'Salvar'} </Btn>
            <Btn attrBtn={{ color: 'secondary', onClick: props.toggle }} >{'Cancelar'} </Btn>
          </div>

        </form>

        <div className='table-responsive product-table'>

        </div>
      </>
      : <></>

  );
};
export default CompanyIntegrationForm;

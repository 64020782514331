import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/moduleSystem";

 const ModuleSystemService =  {

  getAll() {    
    return axios
      .get(API_URL ,  { headers: authHeader() });
  }
 
}  

export default ModuleSystemService;

import { Col, Row, Container } from "reactstrap" 

const PoliticaPrivacidadePage = () => {

    return (
        <>
            <Container fluid={true} className='faq-section'>
                <Row>
                    <Col xs="12">
                        <div className="knowledgebase-bg"
                            style={{ backgroundImage: `url(${require('../../../../assets/images/knowledgebase/bg_1.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="knowledgebase-search">
                            <Container fluid={true} className='p-0 login-page'>
                                <Row>
                                    <Col xs='12'>
                                        <div>
                                            <h1>Política de Privacidade</h1>
                                            <p>
                                                Esta Política de Privacidade descreve como as informações pessoais são coletadas, usadas e compartilhadas quando você utiliza nosso sistema.
                                            </p>
                                            <h3>Informações que Coletamos</h3>
                                            <p>
                                                Quando você utiliza nosso sistema, automaticamente coletamos certas informações, incluindo informações sobre seu dispositivo, como endereço IP, tipo de navegador, fuso horário e algumas das cookies que estão instaladas no seu dispositivo. Além disso, quando você realiza uma ação dentro do sistema, nós coletamos as informações inseridas ou geradas durante essa interação.
                                            </p>
                                            <h3>Como Utilizamos Suas Informações</h3>
                                            <p>
                                                Utilizamos as informações coletadas para melhorar e otimizar o funcionamento do sistema. Além disso, podemos utilizar essas informações para comunicação com você, caso necessário, e para garantir a segurança do sistema.
                                            </p>
                                            <h3>Compartilhamento de Informações</h3>
                                            <p>
                                                Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer os serviços solicitados por você ou quando exigido por lei.
                                            </p>
                                            <h3>Segurança</h3>
                                            <p>
                                                Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.
                                            </p>
                                            <h3>Seus Direitos</h3>
                                            <p>
                                                Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais. Se desejar exercer esse direito, entre em contato conosco através dos meios fornecidos no final desta política.
                                            </p>
                                            <h3>Alterações nesta Política de Privacidade</h3>
                                            <p>
                                                Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento. As alterações e esclarecimentos terão efeito imediatamente após sua publicação no site. Se fizermos alterações significativas nesta política, iremos notificá-lo aqui que foi atualizada, para que você esteja ciente de quais informações coletamos, como as usamos e sob que circunstâncias, se houver, as utilizaremos e/ou divulgaremos.
                                            </p>
                                            <h3>Contato</h3>
                                            <p>
                                                Para mais informações sobre nossa política de privacidade, entre em contato conosco em <b>devluizdavid@gmail.com</b>.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div></Col>
                </Row>

            </Container>
        </>


    );
}

export default PoliticaPrivacidadePage;
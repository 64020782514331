import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Container, Row, Table , Col } from 'reactstrap';
import roleService from '../../../../Services/administrativo/role.service';
import { H5 , Breadcrumbs } from '../../../../AbstractElements';

const RolePage = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getListAll = async () => {
      try {
        const resp = await roleService.getAll();
        setRoles(resp.data);
        console.log(resp.data);
      } catch (error) {
        console.log('cancelled', error);
      }
    };

    getListAll();
  }, [setRoles]);



  return (
    <>
      <Breadcrumbs mainTitle='All Roles' parent='Home' title='Roles' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>          
              <CardBody className='pt-0 campaign-table'>
                {roles.length > 0 ? ( // Verifica se há dados em roles            

                  <div className='recent-table table-responsive currency-table'>
                    <br></br>

                    <Table>
                      <thead>
                        <tr>
                          <th>Module</th>
                          <th>Name</th>
                          <th>blockMessage</th>
                          <th>description</th>
                          <th>releaseMessage</th>
                        </tr>
                      </thead>
                      <tbody>

                        {roles.map((item, i) => (
                          <tr key={i} >
                            <td>{item.module}</td>
                            <td>{item.nameRole}</td>
                            <td>{item.blockMessage}</td>
                            <td>{item.description}</td>
                            <td>{item.releaseMessage}</td>
                          </tr>
                        ))}

                      </tbody>
                    </Table>
                  </div>
                ) : (
                  // Renderiza algo enquanto os dados estão sendo carregados
                  <div>Loading...</div>
                )}
              </CardBody>
            </Card></Col></Row></Container>
    </>
  );
};

export default RolePage;

import React, { useState } from 'react';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { Btn, Image } from '../../../../AbstractElements';
import SweetAlert from 'sweetalert2';
import WidgetImg from '../../../../assets/images/dashboard-2/widget-img.png';
import MobileGif from '../../../../assets/images/dashboard-2/mobile.gif';
import clientService from '../../../../Services/administrativo/cliente.service';
import FormPointModal from '../../../UiKits/Modals/formpoint/form';
import MaskedInput from 'react-text-mask';
import { useNavigate } from 'react-router';

// Máscara para telefone
const PhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);


const LocalizarCliente = () => {
  const [codeQr, setCodeQr] = useState('');
  const [searching, setSearching] = useState(false);
  const [modalPointForm, setModalPointForm] = useState(false);
  const [client, setClient] = useState({});
  const company = JSON.parse(localStorage.getItem('company'));
  const history = useNavigate();
  const modalPointFormToggle = () => setModalPointForm(!modalPointForm);

  const localizar = () => {
    var valido = validaDados();
    if (valido) {
      setSearching(true);

      return clientService.find(codeQr, company.id)
        .then((response) => {
          setClient(response.data);
          console.log(response);
          localStorage.setItem('clienteId', response.data.client.user.id);
          history('/crm/clientes/dashboard');

          //        modalPointFormToggle();
          setSearching(false);
          setCodeQr('');
          return response;
        }).catch((error) => { 
          const isTelefone = codeQr.length === 11;

          if (isTelefone && error.response.status === 404) {
            SweetAlert.fire({
              title: 'Cliente não encontrado?',
              text: 'Deseja realizar o cadastro?',
              icon: 'danger',
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              reverseButtons: true,
            }).then((result) => {
              if (result.value) {
                localStorage.setItem('novoCliente', codeQr);
                history('/crm/clientes/novoclienteform');
              }
            });
          } else if (error.response.status === 404) {
            SweetAlert.fire({ title: 'Desculpe', text: 'O cliente informado não foi encontrado', icon: 'warning', });
          } else {
            SweetAlert.fire({ title: 'Desculpe', text: 'Ocorreu um erro inesperado', icon: 'warning', });
          }

          setSearching(false);
        });
    }

  }
  const validaDados = () => {

    if (codeQr === "") {
      SweetAlert.fire({ title: 'Ops!', text: 'Campo obrigatório para localizar o cliente', icon: 'error' });
      return false;
    }
    return true;

  }

  const irParaNovoCliente = () => {
    history('/crm/clientes/novoclienteform');

  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      localizar();
    }
  };

  return (
    <Card className='o-hidden'>
      <CardBody className='balance-widget'>
        <h4 className='f-w-500 f-light'>Localizar Cliente</h4>
        <Row>
          <Col sm={6}>
            <Input tag={codeQr.length > 10 ? PhoneMask : ''} className='form-control form-control-lg center' id='qrCode' onKeyUp={handleKeyPress}
              type='text' onChange={(e) => setCodeQr(e.target.value)} value={codeQr} style={{ textAlign: 'center' }} />
          </Col>
        </Row>
        <br />
        {
          searching ? 'Carregando ...' :
            <Row>
              <Col md={3} >

                <Btn attrBtn={{ type: 'button', color: 'primary', className: 'btn-lg btn-hover-effect w-100', onClick: (e) => irParaNovoCliente() }}>
                  Novo cadastro
                </Btn>

              </Col>
              <Col md={3} >
                <Btn attrBtn={{ type: 'submit', color: 'success', className: 'btn-lg btn-hover-effect w-100', onClick: (e) => localizar() }}>
                  Localizar
                </Btn>
              </Col>

            </Row>

        }

        <div className='mobile-right-img'>
          <Image attrImage={{ className: 'left-mobile-img', src: WidgetImg, alt: '' }} />
          <Image attrImage={{ className: 'mobile-img', src: MobileGif, alt: 'mobile with coin' }} />
        </div>
      </CardBody>
      <FormPointModal footer={false} isOpen={modalPointForm} client={client} toggler={modalPointFormToggle}>
        Form
      </FormPointModal>
    </Card>
  );
};

export default LocalizarCliente;

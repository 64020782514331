import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import {Btn, H4, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel,  Edit, New, Select, Delete } from "../../../../Constant";
import personTypeService from '../../../../Services/administrativo/personType.service';
import stateService from '../../../../Services/administrativo/state.service';
import cityService from '../../../../Services/administrativo/city.service';
import dateService from '../../../../Services/utils/date.service';
import cepService from '../../../../Services/utils/cep.service';
import MaskedInput from 'react-text-mask';
import {  Edit3,  Plus, Trash } from 'react-feather';


// Máscara para telefone
const PhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);

// Máscara para telefone
const CellPhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);

// Máscara para CEP
const CepMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);

const PersonFormData = (props) => {


  const [option, setOption] = useState(props.option);

  const [id, setId] = useState(props.data.id);
  const [name, setName] = useState(props.data.name);
  const [lastName, setLastName] = useState(props.data.lastName);
  const [corporateName, setCorporateName] = useState(props.data.corporateName);
  const [nickname, setNickname] = useState(props.data.nickname);
  const [rg, setRg] = useState(props.data.rg);
  const [phone, setPhone] = useState(props.data.phone);
  const [cellphone, setCellphone] = useState(props.data.cellphone);
  const [mail, setMail] = useState(props.data.mail);
  const [zipCode, setZipCode] = useState(props.data.zipCode);
  const [address, setAddress] = useState(props.data.address);
  const [number, setNumber] = useState(props.data.number);
  const [complement, setComplement] = useState(props.data.complement);
  const [district, setDistrict] = useState(props.data.district);
  const [cpfCnpj, setCpfCnpj] = useState(props.data.cpfCnpjMascared);
  const [personType, setPersonType] = useState(props.data.personType ? props.data.personType : 'FISICA');
  const [stateId, setStateId] = useState(props.data.city ? props.data.city.state.id : null);
  const [city, setCity] = useState(props.data.city);
  const [cityId, setCityId] = useState(props.data.city ? props.data.city.id : null);
  const [listOfState, setListOfState] = useState([]);
  const [listOfCity, setListOfCity] = useState([]);
  const [listOfPersonType, setListOfPersonType] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(dateService.getDate(props.data.dateOfBirth));
  const [fantasyName, setFantasyName] = useState(props.data.fantasyName);
  const [validCep, setValidCep] = useState(false);


  useEffect(() => {
    if (props.option !== option) {
      // Limpar o formulário aqui
      setOption(props.option);
    }
  }, [props.option]);

  useEffect(() => {
    if (props.data.city !== city) {
      updateCityList(city.state.id);
    }
  }, [props.data.city]);




  const updateCityList = async (stateId) => {
    try {
      if (stateId === '') {
        setListOfCity([]);
      } else {
        if (stateId == null) setListOfCity([]);
        else {
          const response = await cityService.getAll(stateId);
          const data = await response.data;
          setListOfCity(data);
          setStateId(stateId);
        }
      }
    } catch (error) {
      console.error('Error to load options:', error);
    }
  }

  const updateCep = async (cep) => {

    try {
      if (cep === null || cep === '' || cep.replace('.', '').replace('-', '').length !== 8) {
      } else {
        const response = await cepService.getCep(cep);
        const data = await response.data;
        if (data.erro) {
          setValidCep(false);
          setAddress('');
          setDistrict('');
          setNumber('');
          setComplement('');
          setStateId(null);
          setCityId(null);
          setListOfCity([]);
        } else {
          setValidCep(true);
          setAddress(data.logradouro);
          setDistrict(data.bairro);
          setNumber('');
          setComplement('');
          setStateId(listOfState.find(state => state.acronym === data.uf).id);
          updateCityList(stateId);
          setCityId(listOfCity.find(city => city.code === data.ibge).id);
        }
      }
    } catch (error) {
      console.error('Error to load options:', error);
    }
  }


  useEffect(() => {
    const validaCepInicial = async () => {
      if (zipCode == null) setValidCep(false);
      else if ((zipCode === '' || zipCode.replace('.', '').replace('-', '').length !== 8)) {
        setValidCep(false);
      } else {
        const response = await cepService.getCep(zipCode);
        const data = await response.data;
        if (data.erro != null) {
          setValidCep(true);
        } else setValidCep(false);

      }
    };
    validaCepInicial();
  }, []);

  useEffect(() => {
    const fetListOfPersonType = async () => {
      try {
        const response = await personTypeService.getAll();
        const data = await response.data;
        setListOfPersonType(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfPersonType();
  }, []);

  useEffect(() => {
    const setListCity = async () => {
      if (stateId !== undefined) {
        updateCityList(stateId);
      }
    };
    setListCity();
  }, []);

  useEffect(() => {
    const fetListOfState = async () => {
      try {
        const response = await stateService.getAll();
        const data = await response.data;
        setListOfState(data);

      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfState();
  }, []);



  var request = {
    id: id,
    name: name,
    corporateName: corporateName,
    nickname: nickname,
    rg: rg,
    phone: phone,
    cellphone: cellphone,
    mail: mail,
    zipCode: zipCode,
    address: address,
    number: number,
    complement: complement,
    district: district,
    cpfCnpj: cpfCnpj,
    city: cityId,
    dateOfBirth: dateOfBirth,
    personType: personType,
    fantasyName: fantasyName
  }

  const retornaErro = (field) => {
    try {
      if (props.errors) {
        const errors = props.errors.filter(e => e.objectName === field);
        console.log(errors);
        return errors || []; // Retorna um array vazio se não houver erros
      }
      return []; // Retorna um array vazio se props ou props.errors forem nulos
    } catch (error) {
      // console.error('Erro ao filtrar erros:', error);
      return []; // Retorna um array vazio em caso de erro
    }
  };


  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }
       
          <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              
              {
                option === New ? <H5><Plus/> Adicionando Pessoa</H5> : 
                option === Edit ? <H5><Edit3/> Editando Pessoa</H5> :
                option === Delete ? <H5><Trash/> Apagando Pessoa</H5> : ''}   
              
            </CardHeader>
            <CardBody>
            <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
              <Row><Col md={3}>
                <FormGroup>
                  <Label className="col-form-label">Id</Label>
                  {option !== New ?
                    <Input className="form-control btn-square disabled" type="text" placeholder="Id" onChange={(e) => setId(e.target.value)} value={id} readOnly /> :
                    <Input className="form-control btn-square disabled" type="text" placeholder="Código será gerado automaticamente" disabled readOnly />
                  }
                </FormGroup>
              </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="col-form-label">Person Type:</Label>
                    <select className='form-control' size='1' value={personType} onChange={(e) => setPersonType(e.target.value)}>
                      {listOfPersonType.map((personTypeItem) => (
                        <option value={personTypeItem} key={personTypeItem} selected={personTypeItem === personType} >{personTypeItem}</option>
                      ))}
                    </select>
                  </FormGroup>

                </Col>
                <Col md={6}><FormGroup>
                  <Label className="col-form-label">{personType === 'FISICA' ? 'CPF' : 'CNPJ'}</Label>
                  <Input className="form-control btn-square" type="text" placeholder={personType === 'FISICA' ? '000.000.000-00' : '00.000.000/0000-00'} onChange={(e) => setCpfCnpj(e.target.value)} value={cpfCnpj} />
                </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}><FormGroup>
                  <Label className="col-form-label"> {personType === 'FISICA' ? 'Name' : 'Name'}</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setName(e.target.value)} value={name} />
                  <span style={{ color: 'red' }}>{retornaErro('name')}</span>
                </FormGroup>
                </Col>
                
                <Col md={3}><FormGroup>
                  <Label className="col-form-label">Rg</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setRg(e.target.value)} value={rg} />
                </FormGroup>
                </Col>
                <Col md={3}><FormGroup>
                  <Label className="col-form-label">Date of Birth</Label>
                  <Input className="form-control btn-square" type="date" placeholder="dd/mm/aaaa" onChange={(e) => setDateOfBirth(e.target.value)} value={dateOfBirth} />
                </FormGroup>
                </Col>
              </Row>
              {personType === 'FISICA' ? '' :
                <Row>
                  <Col md={6}><FormGroup>
                    <Label className="col-form-label">Fantasy Name</Label>
                    <Input className="form-control btn-square" type="text" onChange={(e) => setFantasyName(e.target.value)} value={fantasyName} />
                  </FormGroup>
                  </Col><Col md={6}><FormGroup>
                    <Label className="col-form-label">Nickname</Label>
                    <Input className="form-control btn-square" type="text" onChange={(e) => setNickname(e.target.value)} value={nickname} />
                  </FormGroup>
                  </Col></Row>
              }
            
            <H4 >{'Address'}</H4>       
            <Row><Col md={3}><FormGroup>
              <Label className="col-form-label">ZipCode</Label>
              <Input tag={CepMask} className="form-control btn-square" type="text" onChange={(e) => { setZipCode(e.target.value); updateCep(e.target.value); }} value={zipCode} />
            </FormGroup>
            </Col><Col md={6}><FormGroup>
              <Label className="col-form-label">Address</Label>
              <Input className={'form-control btn-square' + (validCep ? 'disabled' : '')} type="text" readOnly={validCep} onChange={(e) => setAddress(e.target.value)} value={address} />
            </FormGroup>
              </Col><Col md={3}><FormGroup>
                <Label className="col-form-label">Number</Label>
                <Input className="form-control btn-square" type="text" onChange={(e) => setNumber(e.target.value)} value={number} />
              </FormGroup>
              </Col></Row><Row><Col md={3}><FormGroup>
                <Label className="col-form-label">Complement</Label>
                <Input className="form-control btn-square" type="text" onChange={(e) => setComplement(e.target.value)} value={complement} />
              </FormGroup>
              </Col>
                <Col md={3}><FormGroup>
                  <Label className="col-form-label">District</Label>
                  <Input className="form-control btn-square" type="text" readOnly={validCep} onChange={(e) => setDistrict(e.target.value)} value={district} />
                </FormGroup>
                </Col>
                <Col md={3}>

                  <FormGroup>
                    <Label className="col-form-label">State:</Label>
                    <select className='form-control' size='1' value={stateId} disabled={validCep} onChange={(e) => { setStateId(e.target.value.id); updateCityList(e.target.value); }}>
                      <option value='' >{Select}</option>
                      {listOfState.map((state) => (
                        <option value={state.id} key={state.id} selected={state.id === state} >{state.name}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Col><Col md={3}>
                  <FormGroup>
                    <Label className="col-form-label">City:</Label>
                    <select className='form-control' size='1' value={cityId} disabled={validCep} onChange={(e) => setCityId(e.target.value)}>
                      <option >{Select}</option>
                      {listOfCity.map((city) => (
                        <option value={city.id} key={city.id} selected={city.id === city} >{city.name}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <H4 >{'Contatos'}</H4>      
           <Row><Col md={3}><FormGroup>
              <Label className="col-form-label">Phone</Label>
              <Input tag={PhoneMask} className="form-control btn-square" type="text" placeholder="Phone" onChange={(e) => setPhone(e.target.value)} value={phone} />
            </FormGroup>
            </Col><Col md={3}><FormGroup>
              <Label className="col-form-label">Cellphone</Label>
              <Input tag={CellPhoneMask} className="form-control btn-square" type="text" placeholder="Cellphone" onChange={(e) => setCellphone(e.target.value)} value={cellphone} />
            </FormGroup>
              </Col><Col md={6}><FormGroup>
                <Label className="col-form-label">Mail</Label>
                <Input className="form-control btn-square" type="text" placeholder="Mail" onChange={(e) => setMail(e.target.value)} value={mail} />
              </FormGroup>
              </Col></Row>   </Form>         </CardBody>         

        


        <CardFooter className="text-end">
          {
            option === Edit || option === New ?
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => option === Edit ?  props.update(request) : props.save(request) }}  >{Submit}</Btn> :
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
          }
          <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>
          </Card>
      </Col>
    </Fragment>
  );
};
export default PersonFormData;

import React, {Fragment} from 'react';
import AlertError from '../AlertError'; 
import Loading from '../Loading';
import DataTable from 'react-data-table-component'; 
import SearchNotFoundClass from '../../Components/Common/CommonWidgets/SearchNotFoundClass';
import { Card, CardBody, CardHeader } from 'reactstrap';
 
import { Btn, H5 } from '../../AbstractElements';
import { List } from 'react-feather';

const ListaData = (props) => { 
  return (
    
    <Fragment>
           <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              <H5><List/> {props.titulo}</H5>
            </CardHeader>
            <CardBody>
     
      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message}/>
        ) :
        props.loading ? (
          <Loading/>
        ) : props.list.length  == 0 ? < SearchNotFoundClass message = "Desculpe, não encontramos nenhum registro com os filtros informados"/> : 
        
        
        
        <DataTable noHeader pagination paginationServer columns={props.columns} data={props.list} highlightOnHover={true} striped={true} responsive={true} /> 
        }
        
      </div>
      </CardBody></Card>
    </Fragment>
  );
};

export default ListaData;
import React from 'react';
import DataTable from 'react-data-table-component';  
import { Card, CardBody, CardHeader } from 'reactstrap'; 
import { H5 } from '../../../../../AbstractElements';
import AlertError from '../../../../../CommonElements/AlertError';
import { segmentacaoColumns } from '../../../../../Data/crm/SegmentacaoList';

const SegmentacaoListData = (props) => {

  const BranchData = props.list;
  return (
    <Card className={'card-absolute'}>
      <CardHeader className={'bg-primary'}>
        <H5>Listando Segmentações</H5>
      </CardHeader>
      <CardBody>

        <div className='table-responsive product-table'>{
          props.error ? (
            <AlertError title={'Sorry!'} message={props.error.message} />
          ) :
            props.loading ? (
              <p>Loading...</p>
            ) : <DataTable noHeader pagination paginationServer columns={segmentacaoColumns} data={BranchData} highlightOnHover={true} striped={true} responsive={true} />
        }</div>
      </CardBody>
    </Card>
  );
};
export default SegmentacaoListData;

import React, {  useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import RegisterFrom from './RegisterForm';
import authService from '../../../Services/auth.service';
//import { useNavigate } from "react-router-dom";
//import CustomizerContext from "../../../_helper/Customizer";
import Loader from "../../../Layout/Loader";
import { useRecaptcha } from '../../../_helper/Recaptcha/RecaptchaProvider';
import { Image } from 'react-feather';

const Register = () => {
  const { isVerified} = useRecaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState('');
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mailMascared, setMailMascared] = useState(null);
  const [success, setSuccess] = useState(false);
  //  const { layoutURL } = useContext(CustomizerContext);
  //const history = useNavigate();
  const register = async (request) => {
    setLoading(true);
    //isVerified = true;
  //  if (isVerified) {
      request.recaptcha=recaptchaToken;

      return authService.register(request)
        .then((response) => {
          let user = response.data;
          //        toast.success("User registred with success!.."); 
          setLoading(false);
          setSuccess(true);
          setMailMascared(authService.mascararEmail(request.mail));
          setError(null);
          return user;
        }).catch((errorResponse) => {
          setError(errorResponse.response.data.message);
          setLoading(false);
          setSuccess(false);
        });
   /* } else {
      setError('Por favor, verifique o reCAPTCHA antes de enviar.');
      setLoading(false);
    }*/

  };
 

  const handleRecaptchaChange = (token) => {
    console.log(token);
    setRecaptchaToken(token);
    
  };
  

  return (
    loading ? (
      <Loader show={loading} />
    ) :

      <Container fluid={true} className='p-0 login-page'>
        <Row>
        <Col xl='7' className='b-center bg-size' style={{ backgroundImage: `url(${require('../../../assets/images/login/2.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
              <Image attrImage={{ className: 'bg-img-cover bg-center d-none', src: `${require('../../../assets/images/login/2.jpg')}`, alt: 'looginpage' }} />
          </Col>
          
          <Col xl='5 p-0'>    
            <div className='login-card'>
              <div className='login-main login-tab'>
                <RegisterFrom handleRecaptchaChange={handleRecaptchaChange} logoClassMain="text-start" setSuccess={setSuccess} setError={setError} mailMascared={mailMascared} success={success} register={register} error={error} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>


  );
};

export default Register;

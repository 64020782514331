import React, { Fragment , useState} from 'react';
import { Breadcrumbs, Btn} from '../../../../AbstractElements';
import { Card, Form, Input,  CardBody, CardHeader, Col, Container, Row } from 'reactstrap';  
import stateService from '../../../../Services/administrativo/state.service';
import { toast } from "react-toastify";
import { ACTIVE, Delete, Edit, New, TextSearch} from "../../../../Constant"; 
import StateFormData from './state-form';
import StateListData from './state-list'; 
import { Search } from 'react-feather';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const StatePage = () => {
  const [states, setStates] = useState([]); 
  const [option, setOption] = useState(""); 
  const [state, setState] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stateList, setStateList] = useState([]); 
  const [filtroName, setFiltroName] = useState(""); 

 const getStateListToSelect =  () => {
  const response =  stateService.getAll();
  return response.data; 
 }

 var requestFilter = {   
  name : setFiltroName
} 


  const getListAll = async (e) => {
    setLoading(true);
    try {
      const response = await stateService.filter(requestFilter);
      var stateList = await response.data.content;
      const transformedItems = stateList.map((state) => ({
          ...state,
          status: (
            <div >
              <span>
                {state.status}
              </span>
            </div> 
          ),      
        action : (
          <div>
            <span>
              <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick : (e) => btnDelete(state) }}>Delete</Btn>
            </span>{' '}
            &nbsp;&nbsp;
            <span>
              <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' , onClick : (e) => btnEdit(state) }}>Edit </Btn>
            </span>
          </div>
        )
      })); 
      setStates(transformedItems);    
      setLoading(false);
    } catch (error ) {
      setError(error);
      setLoading(false);      
    }    
    return stateList;
  }

  const btnEdit = (state) => {
    console.log(state);
    setOption(Edit); 
    setState(state); 
    var stateReturn = getStateListToSelect();
    setStateList(stateReturn);
  }

  const btnDelete = (state) => {
    console.log(state);
    setOption(Delete); 
    setState(state); 
  }

  const deleteState = (request) => {
    if (request.id != null) {
      console.log("Entrou no botão deletar = "+request);
      return stateService.delete(request)
      .then((response) => {
        toast.success("Estado excluído com sucesso!"); 
        getListAll();
        setOption("list");
        setState({});
        return response;
      }).catch((error) => {
        setError(error);
        toast.error("Erro ao logar = "+ error.response.data);
      });
    }  
  }

  const btnNovo =  async () => {
    var stateReturn = await getStateListToSelect();
    setStateList(stateReturn);
    initialize();
    setOption(New);
  }
  
  const initialize = () => {
    setState(
      {
      id : -1,
      name: "",
      observation: "",
      code: "",
      standard: "1",
      status : ACTIVE});
  }

  const btnCancel = () => {
    initialize();
    setOption("list");
  }


  const save = (request) => {
    return stateService.save(request)
    .then((response) => {
      initialize();
      setOption("list");
      toast.success("Estado cadastrado com sucesso!"); 
      getListAll();
      return response;
    }).catch((error) => {
      toast.error("Erro ao logar = "+ error.response.data);
    });
     
  } 

  const update = async  (request) => {
    console.log(request);
    setLoading(true);
     if (request.id != null) {
       return stateService.update(request)
       .then((response) => {
         toast.success("Estado alterado com sucesso!"); 
         getListAll();
         setOption("list");
         initialize();
         setLoading(false);
         return response;
       }).catch((error) => {
         toast.error("Erro ao logar = "+ error.response.data);
         setError(error);
         setLoading(false);
       });
     }  
   } 


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de Estados' parent='Home' title='Estados' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
             
                <Form className='theme-form'>
                  <div className='input-group flex-nowrap'>
                    <span className='btn btn-success input-group-text' onClick={() => btnNovo() }>{New}</span>
                    <Input className='form-control-plaintext' type='search' placeholder={TextSearch}   onChange={(e) =>setFiltroName(e.target.value)}  value={filtroName}  />
                    <span className='btn btn-primary input-group-text'  onClick={() => {
                      setOption('list')
                      getListAll();
                    } }><Search/> {'Pesquisa'}</span>
                  </div>
                </Form>
                <br />
               { 
                option === New || option === Edit  || option === Delete
                ?  <StateFormData loading={loading} error={error}  cancel={btnCancel}   option={option}  delete={deleteState}  update={update} save={save} data={state}  stateList={stateList} />
                : option === "list" ? 
                <StateListData loading={loading} error={error}  list={states} /> : ''}
                
              
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default StatePage;

 /* 
   
  private BigDecimal pontos; 
  private Integer nota;
  private String feedback;
  private LocalDateTime validade;
  private String responsavel;*/

import DateService from "../../Services/utils/date.service";

  
export const extratoPontuacaoClienteColumns = [
  {
    name: 'Data',
    selector: (row) => DateService.getDate(row.data),
    sortable: true,
    center: true,  
  },

  {
    name: 'Valor',
    selector: (row) =>  new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(row.valor) ,
    sortable: true,
    center: true, 
  },
  {
    name: 'Pontos',
    selector: (row) => row.pontos,
    sortable: true,
    center: true, 
  },
  {
    name: 'Nota',
    selector: (row) => row.nota,
    sortable: true,
    center: true, 
  },
  

  {
    name: 'Validade',
    selector: (row) =>  DateService.getDate(row.validade),
    sortable: true,
    center: true, 
  },
  {
    name: 'Responsável',
    selector: (row) => row.responsavel,
    sortable: true,
    center: true,
    minWidth: '150px',
    maxWidth: '150px',
  }, 
];

import axios from "axios";
import config from '../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/auth/"; 

class AuthService {
 

  login(username, password) {
    return axios
      .post(API_URL + "token", {
        username,
        password
      });
  }


  refreshToken(refreshToken) {
    return axios
      .post(API_URL + "refreshToken", {
        'token': refreshToken
      });
  }


  logout() { 
    const userId = JSON.parse(localStorage.getItem('userId'));
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
   return this.logoutApi(userId); 
     
  }

  logoutApi(userId) {   
    return axios.post(API_URL + 'logout?userId=' + userId);
  }


  register(request) {
    return axios.post(API_URL + 'registerStore', request);
  } 

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  mailConfirmation(mailKey) {
    return axios.post(API_URL + 'mailConfirmation?mailKey='+mailKey);
  } 

  validaLinkRecuperacaoSenha(mailKey) {
    return axios.post(API_URL + 'validaLinkRecuperacaoSenha?mailKey='+mailKey);
  } 

  atualizarSenha(mailKey, password, repetirSenha) {
    return axios.post(API_URL + 'atualizarSenha?mailKey=' + mailKey + '&password=' + password + '&repetirSenha='+repetirSenha);
  } 
 

  recuperarSenha(mail) {
    return axios.post(API_URL + 'recuperarSenha?mail='+mail);
  } 

  mascararEmail(email)  {
    // Divide o endereço de e-mail em duas partes: nome de usuário e domínio
    var partes = email.split('@');

    // Obtém o nome de usuário e o domínio
    var nomeUsuario = partes[0];
    var dominio = partes[1];

    // Calcula o número de caracteres a serem mascarados no nome de usuário (deixe alguns caracteres visíveis)
    var caracteresVisiveis = Math.min(3, nomeUsuario.length);
    var caracteresMascarados = nomeUsuario.length - caracteresVisiveis;

    // Gera a string mascarada com asteriscos
    var nomeUsuarioMascarado = '*'.repeat(caracteresMascarados) + nomeUsuario.slice(-caracteresVisiveis);

    // Monta o endereço de e-mail mascarado
    var emailMascarado = nomeUsuarioMascarado + '@' + dominio;

    return emailMascarado;
  }

   validateMail(mail)   {
    // Expressão regular para validar endereços de e-mail
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Testa se o e-mail corresponde à expressão regular
    return regex.test(mail);
  }

  
}


const authService = new AuthService();
export default authService;   
 
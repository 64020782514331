import React, { useEffect, useState } from 'react';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import DataTable from 'react-data-table-component';
import { extratoPontuacaoClienteColumns } from '../../../../../Data/crm/ExtratoPontuacaoClienteList';
import { H3 } from '../../../../../AbstractElements';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';

const ExtratoPontuacaoClienteList = ({ cliente }) => {
    const [loading, setLoading] = useState(true);
    const [extratoPontuacao, setExtratoPontuacao] =  useState([]);
 
    useEffect(() => {
        const getPontuacoes = async () => {
            setLoading(true);
            getExtratoPontuacaoClienteList();
            setLoading(false);
        };
        getPontuacoes();
    }, [])


    const getExtratoPontuacaoClienteList = async () => {
        setLoading(true);
        try {
            const response = await clienteService.getPontuacoes(cliente.id);
            const data = await response.data;
            setExtratoPontuacao(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (loading ? 'Carregando' :  extratoPontuacao.length === 0 ? <SearchNotFoundClass message = {'Não foi encontrado nenhum registro de pontuação para esse cliente! '}/> :
        <><H3>Pontuação</H3>  
        <DataTable noHeader columns={extratoPontuacaoClienteColumns} data={extratoPontuacao} highlightOnHover={true} striped={true} responsive={true} />
 
        </>
    );
};

export default ExtratoPontuacaoClienteList;

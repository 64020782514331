import React, { Fragment, useState, useEffect} from "react";
import { Breadcrumbs,} from "../../../../../AbstractElements";
import { Col, Container, Row,  } from "reactstrap";
import CrmProfileStore from "./ProfileStore";
import CrmEditProfileStore from "./EditProfileStore";

import crmCompanyService from "../../../../../Services/crm/crm.company.service";

const CrmMinhaLojaIdentidadeVisual = () => {
  const [company, setCompany] = useState({});
  const companyLocal = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(false); 
/*
  const handleIconClick = () => {
    fileInputRef.current.click();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (event.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);

      const response = await companyService.uploadLogo(companyId, formData);
      const image = await downloadService.getImagem(response.data);
      const imageBase64 = `data:image/${image.data.format};base64,${image.data.base64}`;
      setImageUrl(imageBase64);

      //setUploaded(true);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }
  }; */

  useEffect(() => {
    const getCompany = async () => {
      try {
        setLoading(true);
        await crmCompanyService.getId(companyLocal.id).then((resp) => {
          setCompany(resp.data);
        
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getCompany();
  }, [setCompany]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Identidade Visual' parent='CRM' title='Minha loja' />
      <Container fluid={true}>
        {loading ? 'Carregando' :
          <div className='user-profile social-app-profile'>
            <Row>
              <Col>
                <CrmProfileStore company={company} />
              </Col>
             
            </Row>
          </div>}
      </Container>
    </Fragment>
  );
};

export default CrmMinhaLojaIdentidadeVisual; 
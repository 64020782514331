
import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { cityColumns } from '../../../../Data/administrativo/CityList';
import AlertError from '../../../../CommonElements/AlertError'; 
import { Card, CardBody, CardHeader  } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { List } from 'react-feather';

const CityListData = (props) => {
    
  const cityData = props.list;
 

  return (
    <Card className={'card-absolute'}>
    <CardHeader className={'bg-primary'}>
      <H5><List/> Listando Cidades</H5> 
    </CardHeader>
    <CardBody>
      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message}/>
        ) :
        props.loading ? (
          <p>Loading...</p>
        ) :  <DataTable noHeader pagination paginationServer columns={cityColumns} data={cityData} highlightOnHover={true} striped={true} responsive={true} /> 
        }
        
      </div>
    </CardBody></Card>
  );
};
export default CityListData;

import React  from "react";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Container, Row } from "reactstrap"; 

const CrmAtendentesMetas = () => { 
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <>
      <Breadcrumbs mainTitle='Metas' parent='Atendentes' title={company.name} />
      <Container fluid={true}>
       
      </Container> 
    </>
  );
};

export default CrmAtendentesMetas; 
import React from 'react';
import {  H4, P } from '../../AbstractElements';
import Alerts from '../../CommonElements/Alert'; 

const AlertError = (props) => {
  return (
    <Alerts attrAlert={{ color: 'secondary ' }} >
    <H4 attrH4={{ className: 'alert-heading' }} >{props.title}</H4>
    <P>{props.message}</P>
  </Alerts>
  );
};

export default AlertError;
import React, { Fragment} from "react"; 

const VisitanteDashBoardDefault = () => { 
  return (
    <Fragment>
      Dashboard Visitante  
    </Fragment>
  );
};

export default VisitanteDashBoardDefault; 
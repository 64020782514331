import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoute = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem('token');

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));

  }, []);
  return  authenticated || jwt_token ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;

import DateService from "../../Services/utils/date.service";

export const segmentacaoColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  
  {
    name: 'Descrição',
    selector: (row) => row.descricao,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Data de cadastro',
    selector: (row) => DateService.getDateTime(row.dataCadastro),
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 

  {
    name: 'Último atualização',
    selector: (row) => row.ultimoProcessamento ?  DateService.getDateTime(row.ultimoProcessamento) : '-',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 

  {
    name: 'Total',
    selector: (row) => row.total,
    sortable: true,
    center: true,
    wrap: true,
    maxWidth: '60px',
  }, 
  {
    name: 'Sucesso?',
    selector: (row) => row.sucesso ? 'Sim' : 'Não',
    sortable: true,
    center: true,
    wrap: true,
    maxWidth: '60px',
  }, 
  
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    maxWidth: '180px',
  },
];

import React  from "react";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Container, Row } from "reactstrap";  

const CrmAtendentesGerenciar = () => { 
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <>
       <Breadcrumbs mainTitle='Gerenciar' parent='Atendentes' title={company.name} />
      <Container fluid={true}>
      <Row className="widget-grid">
               
        </Row>
      </Container> 
    </>
  );
};

export default CrmAtendentesGerenciar; 
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import authHeaderImportFile from '../../../../../Services/auth-header-import-file';
import { Alerts, Badges, H3, H4, H5, H6, Image } from '../../../../../AbstractElements';
import { useTranslation } from 'react-i18next';
import companyService from '../../../../../Services/administrativo/company.service';
import importacaoService from '../../../../../Services/administrativo/importacao.service';
import { Table } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { importacaoColumns } from '../../../../../Data/administrativo/ImportacaoList';
import ImportarClienteForm from './ImportacaoClienteForm';
import { Plus, PlusCircle } from 'react-feather';
import SweetAlert from 'sweetalert2';

const ImportarClienteModal = ({ toggle, modal }) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const user = JSON.parse(localStorage.getItem('user'));
    const [status, setStatus] = useState(null);
    const [novaImportacao, setNovaImportacao] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();


    const [importacoes, setImportacoes] = useState([]);

    let filter = {
        userId: user.id,
        companyId: company.id,
        tipo: 'IMPORTACAO_CADASTRO_CLIENTE'
    }

    useEffect(() => {
        setStatus(null);
        setLoading(true);
        fetListOfImportacao();
        setNovaImportacao(false);

    }, [modal])

    const fetListOfImportacao = async () => {
        try {
            const response = await importacaoService.getAll(filter);
            var importacaoList = await response.data.content;

            const transformedItems = importacaoList.map((importacao) => ({
                ...importacao,
                
                situacao: (
                  <Badges attrBadge={{ className: 'badge rounded-pill', color: importacao.situacao === 'Concluído' ? 'success' : 
                    (importacao.situacao === 'Erro' ||  importacao.situacao === 'Reprovado') ? 'danger' : 'dark', pill: true }}>
                    {importacao.situacao}
                  </Badges>
                ),
               
              }));
        
            

            setImportacoes(transformedItems);
            setLoading(false);
        } catch (error) {
            console.error('Error to load options:', error);
            setLoading(false);
        }
    };

    const getCorStatus = () => {
        if (status.indexOf('Arquivo enviado com sucesso!') !== -1) return 'success ';
        else if (status === 'removed') return 'success ';
        else if (status === 'preparing') return 'warning ';
        else if (status === 'uploading') return 'warning ';
        return 'secondary ';
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='xl'>
                <ModalHeader toggle={toggle}>
                    Importação de cliente
                </ModalHeader>
                <ModalBody>
                    {
                        novaImportacao ?
                            <ImportarClienteForm botaoNovaImportacao={setNovaImportacao} status={setStatus} fetListOfImportacao={fetListOfImportacao} /> : (
                                <>
                                    <Row>
                                        <Col md={9}>
                                            <div className='alert alert-info'>
                                                <b>Observações:</b> Importações pendentes serão processadas e assim que finalizadas avisaremos.
                                            </div>
                                        </Col>
                                        <Col md={3} >
                                            <button type="button" className="btn btn-success input-group-text" onClick={() => { setNovaImportacao(true); setStatus('') }}>
                                                <H6><PlusCircle /> {t('Iniciar nova importação')}</H6>
                                            </button>
                                        </Col>
                                    </Row>


                                    <hr />
                                    {
                                        status !== null && status !== '' ?
                                            <Alerts attrAlert={{ color: getCorStatus() }} >

                                                <H6 attrH6={{ className: 'alert-heading' }} >{t(status)}</H6>
                                            </Alerts> : ''
                                    }
                                    {
                                        loading ? (
                                            <p>Loading...</p>
                                        ) : <DataTable customStyles={{
                                            cells: {
                                                style: {
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                },
                                            },
                                        }}
                                            showGridlines fixedHeader pagination paginationServer
                                            columns={importacaoColumns} data={importacoes}
                                            highlightOnHover={true} striped={true} responsive={true} />
                                    }</>)
                    }

                </ModalBody>
            </Modal>
        </>
    );
};
export default ImportarClienteModal;

import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component'; 
import { Card, CardBody, CardHeader } from 'reactstrap'; 
import { List } from 'react-feather';
import AlertError from '../../../CommonElements/AlertError';
import { H5 } from '../../../AbstractElements';
import { notificacaoColumns } from '../../../Data/administrativo/NotificacaoList';

const NotificacaoListData = (props) => {
    
  const notificacoesData = props.list;
 

  return (
    <Card className={'card-absolute'}>
    <CardHeader className={'bg-primary'}>
      <H5>  <List className='me-2' /> Listando as notificações</H5>
    </CardHeader>
    <CardBody>

      <div className='table-responsive product-table'>
      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message}/>
        ) :
        props.loading ? (
          <p>Loading...</p>
        ) :  <DataTable noHeader pagination paginationServer columns={notificacaoColumns} data={notificacoesData} highlightOnHover={true} striped={true} responsive={true} /> 
        }
        
      </div>
    </div></CardBody></Card>
  );
};
export default NotificacaoListData;

import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/state";

 class StateService {
  
  filter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter', request, { headers: authHeader() });
  }

  getAll() {    
    return axios
      .get(API_URL, { headers: authHeader() });
  }
 
  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  }

  update(request) {    
    return axios
      .put(API_URL+"/"+request.id, request, { headers: authHeader() });
  }

  delete(request) {    
    return axios
      .delete(API_URL+"/"+request.id, { headers: authHeader() });
  }
}  

const stateService = new StateService();
export default stateService;     
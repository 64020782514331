import React  from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Container, Row } from "reactstrap"; 
import LeftGrid from './LeftGrid';   
import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWraper";

const CrmDashBoardDefault = () => { 
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <>
      <Breadcrumbs mainTitle={company.name} parent='CRM' title='Dashboard' />
      <Container fluid={true}>
      <Row className="widget-grid">
          <GreetingCard/>
          <WidgetsWrapper />
          <LeftGrid />        
        </Row>
      </Container> 
    </>
  );
};

export default CrmDashBoardDefault; 
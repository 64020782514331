export default function authHeader() { 
    try {
      const token = localStorage.getItem('token'); 
      if (token) {
        return { Authorization: 'Bearer ' + token,   'Content-Type': 'application/json' }; // for Spring Boot back-end
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }
  
 
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import authHeaderImportFile from '../../../../../Services/auth-header-import-file';
import { Alerts, H3, H4, H6, Image } from '../../../../../AbstractElements';
import { useTranslation } from 'react-i18next';
import companyService from '../../../../../Services/administrativo/company.service';
import importacaoService from '../../../../../Services/administrativo/importacao.service';
import { Col, Table } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { importacaoColumns } from '../../../../../Data/administrativo/ImportacaoList';
import { toast } from 'react-toastify';
import { AlignLeft, ArrowLeft } from 'react-feather';

const ImportarClienteForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const user = JSON.parse(localStorage.getItem('user'));
    const [status, setStatus] = useState('');


    const getUploadParams = ({ meta }) => {
        return {
            url: importacaoService.getUrlImportacaoCliente(company.id, user.id),
            headers: authHeaderImportFile()
        };
    };
    const handleChangeStatus = ({ meta, file, xhr }, status, allFiles) => {
        props.status(status);
        setStatus('');
        if (status === 'removed') {
            props.status(null);
            setStatus('');
        } else if (status === 'done') {
            allFiles.forEach(f => f.remove());
            toast.success('Arquivo enviado com sucesso!');
            props.status('Arquivo enviado com sucesso! Seu arquivo será processado e assim que terminar ti avisaremos. Se quiser pode continuar navegando no sistema normalmente');
            props.botaoNovaImportacao(false);
            props.fetListOfImportacao();
        } else if (status === 'error_upload') {
            props.status('Arquivo já existe no sistema, aguarde o processamento caso não esteja processado ainda!');
            setStatus('Arquivo já existe no sistema, aguarde o processamento caso não esteja processado ainda!');
        }
    };
    return (
        <>
            <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={() => props.botaoNovaImportacao(false)}>
                <H6><ArrowLeft /> Voltar ao início </H6>
            </button>
            <br />
            <div className='alert'>
                <ul>
                    <li>O arquivo deve estar no formato <strong>CSV</strong>.</li>
                    <li>O tamanho máximo permitido é <strong>10 MB</strong>.</li>
                    <li>A primeira linha deve conter os cabeçalhos.</li>
                    <li>Certifique-se de que os campos estejam separados por vírgulas (no caso de CSV).</li>
                </ul>
                <br/>
                <Row>
                    <Col>
                        <h6>Exemplo de Formato CSV:</h6>
                        <pre>
                            NOME,EMAIL,TELEFONE,DATA_DE_NASCIMENTO<br />
                            João Silva,joao@example.com,1112345678,1980-01-01<br />
                            Maria Oliveira,maria@example.com,2198765432,1985-02-01<br />
                        </pre>


                    </Col>
                    {
                        /*  <Col><h6>Exemplo de Formato XLSX:</h6>
                        <Image attrImage={{ className: 'img-fluid d-inline', src: `${require('../../../../../assets/images/exemplos/layout-importacao-cliente.png')}`, alt: '' }} />
                    </Col>*/
                    }
                   
                </Row>

                {
                    status !== null && status !== '' ?
                        <Alerts attrAlert={{ color: 'danger' }} >

                            <H6 attrH6={{ className: 'alert-heading' }} >{status}</H6>
                        </Alerts> : ''
                }
            </div>


            <Form>
                <div className="dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        accept=".csv"

                        inputContent="Informe um arquivo CSV"
                        styles={{
                            dropzoneActive: { borderColor: 'green' },
                        }}
                    />
                </div>

            </Form>


        </>
    );
};
export default ImportarClienteForm;
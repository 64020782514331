 
  
export const userColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  {
    name: 'Name',
    selector: (row) => row.person.name ,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: 'Login',
    selector: (row) => row.userName,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: 'Perfis',
    selector: (row) => row.perfil,
    sortable: false,
    center: true ,
    wrap: true,
    minWidth: '10px',
  },
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: false
  },
];

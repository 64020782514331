import DateService from "../../Services/utils/date.service";


export const importacaoColumns = [

    {
        name: 'Id',
        selector: (row) => row.id,
        sortable: true,
        center: true, 
        width: '60px', 
    },
   
    {
        name: 'Responsável',
        selector: (row) => row.responsavel,
        sortable: true,
        center: true, 
    },
    {
        name: 'Arquivo',
        selector: (row) => row.arquivo,
        sortable: true,
        center: true, 
    },
    {
        name: 'Cadastrado em ...',
        selector: (row) => DateService.getDateTime(row.dataCadastro),
        sortable: true,
        center: true, 
    },
    {
        name: 'Processado em ...',
        selector: (row) => row.situacao === 'Pendente' ? ' - ' :  DateService.getDateTime( row.dataProcessamento) ,
        sortable: true,
        center: true, 
    },

    {
        name: 'Situação',
        selector: (row) => row.situacao,
        sortable: true,
        center: true, 
    },
];
 
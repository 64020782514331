import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/exportacao";

 class ExportacaoService   {
  
  send(request) {    
    return axios
      .post(API_URL ,  request  , { headers: authHeader() });
  }
   
  getAll(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter', request, { headers: authHeader() });
  }

 
}  
 
export default  new ExportacaoService();    
 
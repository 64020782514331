import React from 'react';
import DataTable from 'react-data-table-component';
import { personColumns } from '../../../../Data/administrativo/PersonList';
import AlertError from '../../../../CommonElements/AlertError';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { List } from 'react-feather';
import SearchNotFoundClass from '../../../Common/CommonWidgets/SearchNotFoundClass';


const PersonListData = (props) => {

  const personData = props.list; 
  return (
    <Card className={'card-absolute'}>
      <CardHeader className={'bg-primary'}>
        <H5><List/> Listando Pessoas</H5>
      </CardHeader>
      <CardBody>

        <div className='table-responsive product-table'>{
          props.error ? (
            <AlertError title={'Sorry!'} message={props.error.message} />
          ) :
            props.loading ? (
              <p>Loading...</p>
            ) : personData.length  == 0 ?
            < SearchNotFoundClass/> :  <DataTable noHeader pagination paginationServer columns={personColumns} data={personData} highlightOnHover={true} striped={true} responsive={true} />
        }

        </div>
      </CardBody>
    </Card>
  );
};
export default PersonListData;

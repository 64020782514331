import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import { Card, Input, CardBody, CardHeader, Col, Container, Row, Form } from 'reactstrap';
import perfilService from '../../../../Services/administrativo/perfil.service';
import { toast } from "react-toastify";
import PerfilFormData from './perfil-form';
import PerfilListData from './perfil-list';
import PerfilRoles from './perfil-roles';
import { New, TextSearch, Delete, Edit, List } from "../../../../Constant";
import { Edit2, Trash } from 'react-feather';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const PerfilPage = () => {
  const { t } = useTranslation();
  const [perfis, setPerfis] = useState([]);
  const [option, setOption] = useState("");
  const [name, setName] = useState("");
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perfil, setPerfil] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  var requestFilter = {
    name: name
  }

  const getListAll = async (e) => {
    setLoading(true);
    try {
      const response = await perfilService.getFilter(requestFilter, page, size);
      var perfilList = await response.data.content;
      setTotalRows(response.data.totalItens);
      const transformedItems = perfilList.map((perfil) => ({
        ...perfil,
        roles: (
          <div><span>
            <Btn attrBtn={{ style: style2, color: 'info', className: 'btn btn-xs', type: 'button', onClick: (e) => btnRoles(perfil) }}>{t('Roles')}</Btn>
          </span>
          </div>
        ),
        action: (
          <div>
            <Btn attrBtn={{ style: style2, color: 'warning', className: 'btn btn-xs', type: 'button', onClick: (e) => btnEdit(perfil) }}>
              <Edit2 style={{ width: '18px', height: '18px' }} className='me-2' />
            </Btn>

            &nbsp;&nbsp;
            <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick: (e) => btnDelete(perfil) }}>
              <Trash style={{ width: '18px', height: '18px' }} className='me-2' />
            </Btn>
            
          </div>
        )
      }
      )
      );

      setPerfis(transformedItems);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
    return perfilList;
  }
   

  const btnEdit = (perfil) => {
    setOption(Edit);
    setPerfil(perfil);
  }

  const btnRoles = (perfil) => {
    setOption('roles');
    setPerfil(perfil);
  }

  const btnDelete = (perfil) => {
    setOption(Delete);
    setPerfil(perfil);
  }

  const deleteCountry = (request) => {
    if (request.id != null) {
      return perfilService.delete(request)
        .then((response) => {
          toast.success("Perfil excluído com sucesso!");
          getListAll(requestFilter);
          setOption("list");
          setPerfil({});
          return response;
        }).catch((error) => {
          setError(error);
          toast.error("Erro ao logar = " + error.response.data);
        });
    }
  }

  const btnNovo = () => {
    initialize();
    setOption(New);
  }

  const initialize = () => {
    setPerfil(
      {
        id: -1,
        name: "",
        observation: "",
        code: "",
        standard: "1",
        status: "Active"
      });
  }

  const btnCancel = () => {
    initialize();
    setOption("list");
  }


  const save = (request) => {
    return perfilService.save(request)
      .then((response) => {
        initialize();
        setOption("list");
        toast.success("Perfil cadastrado com sucesso!");
        getListAll(requestFilter);
        return response;
      }).catch((error) => {
        toast.error("Erro ao logar = " + error.response.data);
      });

  }

  const update = async (request) => {
    setLoading(true);
    if (request.id != null) {
      return perfilService.update(request)
        .then((response) => {
          toast.success("Perfil alterado com sucesso!");
          getListAll(requestFilter);
          setOption("list");
          initialize();
          setLoading(false);
          return response;
        }).catch((error) => {
          toast.error("Erro ao logar = " + error.response.data);
          setError(error);
          setLoading(false);
        });
    }
  }
  const handleKeyPress = (event) => {
    event.preventDefault();
    if (event.key === 'Enter') {
       // Impede o comportamento padrão
      
      setOption(List)
      getListAll(requestFilter);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de Perfil' parent='Home' title='Perfil' />

      <Container fluid={true}>
        {(option === "roles" ? <PerfilRoles perfil={perfil} /> :
          <Row>
            <Col sm='12'>
                  <Form className='theme-form'>
                    <div className='input-group flex-nowrap'>
                      <span className='btn btn-success input-group-text' onClick={() => btnNovo()}>{t(New)}</span>

                      <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)} onKeyUp={handleKeyPress} onChange={(e) => setName(e.target.value)} value={name} />
                      <span className='btn btn-primary input-group-text' onClick={() => {
                        setOption('list')
                        getListAll(requestFilter);
                      }}>{t(TextSearch)}</span>
                    </div>
                  </Form>
                  <br></br>
              {
                  option === New || option === Edit || option === Delete
                    ? <PerfilFormData loading={loading} error={error} cancel={btnCancel} option={option} delete={deleteCountry} update={update} save={save} data={perfil} />
                    : option === "list" ?
                      <PerfilListData loading={loading} error={error} list={perfis} /> : ''}
 
            </Col>
          </Row>)}
      </Container>
    </Fragment>
  );
};

export default PerfilPage;

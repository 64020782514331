import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumbs, H4,  } from "../../../../../AbstractElements";
import 'react-date-range/dist/styles.css'; // Estilos principais
import 'react-date-range/dist/theme/default.css'; // Tema padrão
import { Card, CardBody, Col, Container, Row,   } from "reactstrap"; 
import PontuarModal from "./PontuarModal"; 
import { FiGift, FiStar } from "react-icons/fi";
import ResgatarModal from "./ResgatarModal"; 
import InformacoesCliente from "./InformacoesCliente";
import ComunicacoesComCliente from "./ComunicacoesComCliente";
import ExtratosCliente from "./ExtratosCliente";
import clienteService from "../../../../../Services/administrativo/cliente.service";

const CrmClienteDashboard = () => {
  const clienteId = localStorage.getItem('clienteId');
  const [loading, setLoading] = useState(true); 

  const [cliente, setCliente] = useState({}); 

  useEffect( () => {
    return clienteService.findById(clienteId)
    .then((response) => {
      setCliente(response.data);
        setLoading(false); 
        
        return response;
    })
    .catch((error) => { 
        setLoading(false);
    });
  }, [])


  

  //const picture = ''; //cliente.client.picture ? downloadService.getBase64PictureObject(cliente.client.picture) : '';
  const [modalPontuar, setModalPontuar] = useState(false);
  const [modalResgatar, setModalResgatar] = useState(false);

  const togglePontuar = useCallback(() => {
    setModalPontuar(!modalPontuar);
  }, [modalPontuar]);

  const toggleResgatar = useCallback(() => {
    setModalResgatar(!modalResgatar);
  }, [modalResgatar]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (loading ? 'Carregando' : 
    <>
      <Breadcrumbs mainTitle={'Cliente : ' + cliente.client.name} parent='CRM' title='Pontuar / Resgatar' />
      <Container fluid={true} className='general-widget'>
        <Row>
          <Col md='4' className='box-col-6'>
            <InformacoesCliente cliente={cliente} />
            <ComunicacoesComCliente cliente={cliente} />
          </Col>
          <Col md='8' className='box-col-6'>
            <Row>

              <Col className='box-col-6'>
                <Card className={`btn btn-success course-box`} onClick={togglePontuar} style={{ cursor: 'pointer' }}>
                  <CardBody>
                    <div className='course-widget'>
                      <div className={`course-icon warning}`}>
                        <FiStar className='fill-icon' /> {/* Ícone de estrela */}
                      </div>
                      <div>
                        <H4 attrH4={{ className: 'mb-0' }}> Pontuar</H4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className='box-col-6'>
                <Card className={`btn btn-primary course-box`} onClick={toggleResgatar} style={{ cursor: 'pointer' }}>
                  <CardBody>
                    <div className='course-widget'>
                      <div className={`course-icon primary}`}>
                        <FiGift className='fill-icon' /> 
                      </div>
                      <div>
                        <H4 attrH4={{ className: 'mb-0' }}> Resgatar</H4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ExtratosCliente cliente={cliente} />
          </Col>
        </Row>
        <PontuarModal modal={modalPontuar} setModalPontuar={setModalPontuar} client={cliente} toggle={togglePontuar} />
        <ResgatarModal modal={modalResgatar} toggle={toggleResgatar} />
      </Container>

    </>
  );
};

export default CrmClienteDashboard; 
export const companyColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true, 
  },
  {
    name: 'CPF / CNPJ',
    selector: (row) => row.person.cpfCnpjMascared,
    sortable: true, 
  },
  {
    name: 'Name',
    selector: (row) => row.person.name,
    sortable: true,
    center: true, 
  }, 
  {
    name: 'Telefone',
    selector: (row) => row.person.cellphone,
    sortable: true,
    center: true, 
  }, 
  {
    name: 'E-mail',
    selector: (row) => row.person.mail,
    sortable: true,
    center: true, 
  }, 
  {
    name: 'Integração',
    selector: (row) => row.integrations,
    sortable: true,
    center: true, 
  }, 
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true
  },
];

// RecaptchaContext.js
import React, { createContext, useState, useContext } from 'react';

const RecaptchaContext = createContext();

export const useRecaptcha = () => useContext(RecaptchaContext);

 const RecaptchaProvider = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);

  const verifyCallback = () => {
    setIsVerified(true);
  };

  return (
    <RecaptchaContext.Provider value={{ isVerified, verifyCallback }}>
      {children}
    </RecaptchaContext.Provider>
  );
};

export default RecaptchaProvider; 
 
  
export const perfilColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  {
    name: 'Name',
    selector: (row) => row.module,
    sortable: true,
    center: true 
  },
  {
    name: 'Module',
    selector: (row) => row.name,
    sortable: true,
    center: true 
  },
  {
    name: 'Roles',
    selector: (row) => row.roles,
    sortable: false,
    center: true 
  }, 
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: false 
  },
  
];

export const personColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  {
    name: 'CPF / CNPJ',
    selector: (row) => row.cpfCnpjMascared,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Telefone',
    selector: (row) => row.cellphone,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Mail',
    selector: (row) => row.mail,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
];

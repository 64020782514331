import React from 'react';
import DataTable from 'react-data-table-component';
import { companyColumns } from '../../../../Data/administrativo/CompanyList';
import AlertError from '../../../../CommonElements/AlertError';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import SearchNotFoundClass from '../../../Common/CommonWidgets/SearchNotFoundClass';
import { List } from 'react-feather';

const CompanyListData = (props) => {
 
  const companyData = props.list; 
  return (
    <Card className={'card-absolute'}>
      <CardHeader className={'bg-primary'}>
        <H5>  <List className='me-2' /> Listando Empresas</H5>
      </CardHeader>
      <CardBody>

        <div className='table-responsive product-table'>{
          props.error ? (
            <AlertError title={'Sorry!'} message={props.error.message} />
          ) :
            props.loading ? (
              <p>Loading...</p>
            ) : companyData.size  == 0 ?
                < SearchNotFoundClass/> : 
            
            
            <DataTable noHeader pagination paginationServer columns={companyColumns} data={companyData} highlightOnHover={true} striped={true} responsive={true} />
        }

        </div>
      </CardBody>
    </Card>
  );
};
export default CompanyListData;

 
export const integrationColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  
  {
    name: 'Descrição',
    selector: (row) => row.descricao,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 

  {
    name: 'Identificador',
    selector: (row) => row.identificador,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 
  {
    name: 'Fornecedor',
    selector: (row) => row.fornecedor,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 

  
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  }, 

  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    maxWidth: '180px',
  },
];

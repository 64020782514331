import React, { Fragment, useState } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import { Col, Container, Form, Input, Row } from 'reactstrap';
import cityService from '../../../../Services/administrativo/city.service';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CityFormData from './city-form';
import CityListData from './city-list';
import FormSearch from '../../../../CommonElements/FormSearch';
import { ACTIVE, Delete, Edit, New, TextSearch } from '../../../../Constant';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const CityPage = () => {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const [option, setOption] = useState("");
  const [country, setCity] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filtroName, setFiltroName] = useState("");
  const [page, setPage] = useState(1);

  var requestFilter = {
    name: filtroName
  }

  const getListAll = async (e) => {
    setLoading(true);
    try {
      const response = await cityService.filter(requestFilter);
      var cityList = await response.data.content;
      const transformedItems = cityList.map((state) => ({
        ...state,
        status: (
          <div >
            <span>
              {state.status}
            </span>
          </div>
        ),
        standard: (
          <div >
            <span>
              {state.standard}
            </span>
          </div>
        ),
        action: (
          <div>
            <span>
              <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick: (e) => btnDelete(state) }}>Delete</Btn>
            </span>{' '}
            &nbsp;&nbsp;
            <span>
              <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button', onClick: (e) => btnEdit(state) }}>Edit </Btn>
            </span>
          </div>
        )
      }));
      setCities(transformedItems);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
    return cityList;
  }




  const btnEdit = (country) => {
    setOption(Edit);
    setCity(country);
  }

  const btnDelete = (country) => {
    setOption(Delete);
    setCity(country);
  }

  const deleteCity = (request) => {
    if (request.id != null) {
      return cityService.delete(request)
        .then((response) => {
          toast.success("País excluído com sucesso!");
          getListAll();
          setOption("list");
          setCity({});
          return response;
        }).catch((error) => {
          setError(error);
          toast.error("Erro ao logar = " + error.response.data);
        });
    }
  }

  const btnNovo = () => {
    initialize();
    setOption(New);
  }

  const initialize = () => {
    setCity(
      {
        id: -1,
        name: "",
        observation: "",
        code: "",
        standard: false,
        status: ACTIVE
      });
  }

  const btnCancel = () => {
    initialize();
    setOption("list");
  }


  const save = (request) => {
    return cityService.save(request)
      .then((response) => {
        initialize();
        setOption("list");
        toast.success("País cadastrado com sucesso!");
        getListAll();
        return response;
      }).catch((error) => {
        toast.error("Erro ao logar = " + error.response.data);
      });

  }

  const update = async (request) => {
    setLoading(true);
    if (request.id != null) {
      return cityService.update(request)
        .then((response) => {
          toast.success("País alterado com sucesso!");
          getListAll();
          setOption("list");
          initialize();
          setLoading(false);
          return response;
        }).catch((error) => {
          toast.error("Erro ao logar = " + error.response.data);
          setError(error);
          setLoading(false);
        });
    }
  }


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de Cidades' parent='Home' title='Cidades' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form className='theme-form'>

              <div className='input-group flex-nowrap'>
                <span className='btn btn-success input-group-text' onClick={() => btnNovo()}>{t(New)}</span>

                <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)}
                  onChange={(e) => setFiltroName(e.target.value)} value={filtroName} />
                <span className='btn btn-primary input-group-text' onClick={() => {
                  setOption('list');
                  setPage(0);
                  getListAll(requestFilter);
                }}>{t(TextSearch)}</span>
              </div>
            </Form>
            <br />


            <br />{
              option === New || option === Edit || option === Delete
                ? <CityFormData loading={loading} error={error} cancel={btnCancel} option={option} delete={deleteCity} update={update} save={save} data={country} />
                : option === "list" ?
                  <CityListData loading={loading} error={error} list={cities} /> : ''}


          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CityPage;

import React, { Fragment, useState, useEffect} from "react";
import { Breadcrumbs,} from "../../../../../AbstractElements";
import { Col, Container, Row,  } from "reactstrap"; 

const CrmMinhaLojaAplicativo = () => {
   
   

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Aplicativo' parent='CRM' title='Minha loja' />
      <Container fluid={true}>
       
      </Container>
    </Fragment>
  );
};

export default CrmMinhaLojaAplicativo; 
import React, { Fragment, } from "react";
import { Breadcrumbs, } from "../../../../../AbstractElements";
import { Col, Container, Row, } from "reactstrap";
import CrmMinhaLojaDiaDeFuncionamento from "./DiasDeFuncionamento";
import CrmMinhaLojaRegrasDePontuacao from "./RegrasDePontuacao";

const CrmMinhaLojaParametrizacao = () => {
  var companyLocal = JSON.parse(localStorage.getItem('company'));


  return (
    <> <Fragment>
      <Breadcrumbs mainTitle='Parametrizações' parent='CRM' title='Minha loja' />
      <Container fluid={true}>

        <Row>
          <Col md={5}>
            <CrmMinhaLojaRegrasDePontuacao />
          </Col>
          <Col md={7}>
            <CrmMinhaLojaDiaDeFuncionamento />
          </Col>
        </Row>

      </Container>
    </Fragment></>
  );
};

export default CrmMinhaLojaParametrizacao; 
import React, {useState } from "react";
import { Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import HeaderCard from "../../../../../Common/Component/HeaderCard";
import { Breadcrumbs, H4 } from "../../../../../../AbstractElements";
import { FiSave, FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router";
import SweetAlert from 'sweetalert2';
import companyService from "../../../../../../Services/administrativo/company.service";

const CrmMinhaLojaComprarSms = () => {
    var companyLocal = JSON.parse(localStorage.getItem('company')); 
    const [valor, setValor] = useState('0');
    const [nome, setNome] = useState(companyLocal.name);
    const [cpfCnpj, setCpfCnpj] = useState(companyLocal.cpfCnpj);
    const [email, setEmail] = useState(companyLocal.email);
    const [telefone, setTelefone] = useState(companyLocal.telefone);
    const history = useNavigate();
    const [gerou, setGerou] = useState(false);
    const [link, setLink] = useState(null);

    const [dadosPagSeguro, setDadosPagSeguro] = useState(null);


    let request = {
        companyId : companyLocal.id,
        nome : nome,
        cpfCnpj : cpfCnpj,
        email : email,
        telefone : telefone,
        valor : +valor
    }
    const gerarGuia = async () => {
        companyService.gerarGuiaPagamento(companyLocal.id , request).then((response) => {
            if (response.status === 200) {
                setDadosPagSeguro(response.data);
                var link = response.data.links[1].href;
                setLink(link);
                console.log(link);
                window.open(link, '_blank');
                setGerou(true);
            }

           
        }).catch((error) => {
            SweetAlert.fire({ title: 'Desculpe', text: 'Ocorreu um erro ao salvar o cliente', icon: 'danger', });
        });
    };


    const cancelar = () => {

        if (gerou) history(-2);
        else history(-1);
    }; 

    return (
        <> <Breadcrumbs mainTitle='Comprar SMS' parent='CRM' title='Minha loja' />
            <Container fluid={true}>
               
                <Row>
                    <Col>
                        <Card className='checkout'>
                            <HeaderCard title={'Dados do pagamento'} />
                            <CardBody>
                            {gerou ? (
                                <>
                                
                                <div className='mb-3'>
                                    <Label>{'Acesse o link e realize o pagamento'}</Label>

                                    <h4><a href={link} target="_blank">Link do pagseguro</a></h4>
                                </div>

                                </>
                            
                            
                             ) :  

                                <Form className='theme-form crypto-form'>
                                    <div className='mb-3'>
                                        <Label className='form-label f-light' htmlFor='money'>
                                            <H4>{'Quanto você quer adicionar a sua conta?'}</H4>
                                        </Label>
                                        <div className='position-relative'>
                                            <Input
                                                id='money'
                                                className='form-control form-control-lg highlighted-input'
                                                type='number'
                                                onChange={(e) => { setValor(e.target.value); }}
                                                value={valor}
                                                style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', padding: '10px' }}
                                            />
                                            <select className='form-select crypto-select warning' style={{ height: 'calc(3.5rem + 2px)', marginLeft: '10px', fontSize: '1.5rem' }}>
                                                <option>{'BR'}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <Row>
                                        <FormGroup className="mb-6 col-sm-8">
                                            <Label>{'Nome'}</Label>
                                            <input className="form-control" type="text" name="nome"   onChange={(e) => { setNome(e.target.value); }} value={nome}/>
                                        </FormGroup>
                                        <FormGroup className="mb-6 col-sm-4">
                                            <Label>{'CPF/CNPJ'}</Label>
                                            <input className="form-control" type="text" name="cpfCnpj"   onChange={(e) => { setCpfCnpj(e.target.value); }} value={cpfCnpj}/>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup className="mb-6 col-sm-8">
                                            <Label>{'Email'}</Label>
                                            <input className="form-control" type="email" name="email"   onChange={(e) => { setEmail(e.target.value); }} value={email}/>
                                        </FormGroup>
                                        <FormGroup className="mb-6 col-sm-4">
                                            <Label>{'Telefone'}</Label>
                                            <input className="form-control" type="text" name="telefone"   onChange={(e) => { setTelefone(e.target.value); }} value={telefone}/>
                                        </FormGroup>
                                    </Row>
                                </Form>
}
                            </CardBody> <CardFooter>
                                <Row>
                                    <Col>
                                        <button type="button" className="btn btn-danger btn-lg w-100 input-group-text" onClick={cancelar}>
                                            <FiXCircle style={{ marginRight: '5px' }} />
                                            Cancelar
                                        </button>
                                    </Col>
                                    {!gerou ? 
                                    <Col>
                                        <button type="button" className="btn btn-success btn-lg  w-100 input-group-text" onClick={gerarGuia}>
                                            <FiSave style={{ marginRight: '5px' }} />
                                            Salvar e continuar
                                        </button>
                                    </Col> : '' }
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

                     

            </Container>

        </>
    );
};

export default CrmMinhaLojaComprarSms; 
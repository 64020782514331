import React from 'react';
import { Col, Container, Row } from 'reactstrap';  

const HomePage = () => {
    return (
        <>
            <Container fluid={true} className="p-0">
                <Row>
                    <Col>
                        <h1>Home</h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default HomePage;
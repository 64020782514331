import React, {useState } from 'react';
import { useTranslation } from "react-i18next";
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { Btn, H4, P,  H6, Progressbar } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import { Login, EmailAddress, Password, show } from "../../../Constant";
import Alerts from '../../../CommonElements/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import authService from '../../../Services/auth.service';

const RegisterFrom = (props) => { 
  const { t } = useTranslation();
  const [togglePassword, setTogglePassword] = useState(false);
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState(''); 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('Teste@123');
  const [applyPolitc, setApplyPolitc] = useState(null);
  const [mailValid, setMailValid] = useState(null);
  const [mail, setMail] = useState('');
  const [pontuacaoSenha, setPontuacaoSenha] = useState(0); 
 


  var request =
  {
    name: name,
    username: username,
    password: password,
    mail: mail,  
    cpf : cpf
  }



  const submitDados = async (request) => {
    setMailValid(authService.validateMail(request.mail));
    if (applyPolitc == null) {
      setApplyPolitc(false);
    }

    if (applyPolitc) {
      props.register(request);

    } else {
      props.setError(null);
      props.setSuccess(false);
    }
  }

  function calcularForcaSenha() {
    var senha = password;
    var pontos = 0;
    var maxPontos = 100; // Valor máximo possível para a pontuação da senha

    // Adiciona pontos com base no comprimento da senha
    pontos += senha.length * 4;

    // Adiciona pontos extras se a senha contiver letras maiúsculas
    if (/[A-Z]/.test(senha)) {
        pontos += senha.length * 2;
    }

    // Adiciona pontos extras se a senha contiver letras minúsculas
    if (/[a-z]/.test(senha)) {
        pontos += senha.length * 2;
    }

    // Adiciona pontos extras se a senha contiver números
    if (/[0-9]/.test(senha)) {
        pontos += senha.length * 4;
    }

    // Adiciona pontos extras se a senha contiver caracteres especiais
    if (/[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
        pontos += senha.length * 6;
    }

    // Penalizações
    // Remove pontos se a senha contiver apenas letras
    if (/^[a-zA-Z]*$/.test(senha)) {
        pontos -= senha.length;
    }

    // Remove pontos se a senha contiver apenas números
    if (/^\d*$/.test(senha)) {
        pontos -= senha.length;
    }

    // Remove pontos se a senha for uma sequência comum (e.g., '123456', 'abcdef')
    if (/^(?:([a-zA-Z0-9])(?!.*\1))*$/.test(senha)) {
        pontos -= senha.length;
    }

    // Certifique-se de que a pontuação mínima seja 0
    pontos = Math.max(0, pontos);

    // Calcule o percentual
    var percentual = (pontos / maxPontos) * 100;
    setPontuacaoSenha(percentual);
    calcularForcaEShow(percentual);
    return percentual;
}

function calcularForcaEShow(forcaPercentual) { 
  var forcaLabel = document.getElementById('forcaLabel');

  if (forcaPercentual < 33) {
      forcaLabel.innerHTML = "Força da senha: Fraca";
      forcaLabel.style.color = "red";
  } else if (forcaPercentual >= 33 && forcaPercentual < 66) {
      forcaLabel.innerHTML = "Força da senha: Média";
      forcaLabel.style.color = "orange";
  } else if (forcaPercentual >= 67 && forcaPercentual < 75) {
    forcaLabel.innerHTML = "Força da senha: Forte";
    forcaLabel.style.color = "blue";
  } else {
      forcaLabel.innerHTML = "Força da senha: Muito Forte";
      forcaLabel.style.color = "green";
  }
}


  return (

    <Form className='theme-form login-form' method='post'>

      <H4>{t('Create your account')}</H4>
      <P>{t('Enter your personal details to create account')}</P>

      {props.error ? <Alerts attrAlert={{ color: 'secondary  alert-dismissible' }} >
        <H4 attrH4={{ className: 'alert-heading' }} >{t('Sorry!')}</H4>
        <H6 attrH6={{ className: 'alert-heading' }} >{t(props.error)}</H6>
      </Alerts> : ''}

      {props.success ? <Alerts attrAlert={{ color: 'success  alert-dismissible' }} >
        <H4 attrH4={{ className: 'alert-heading' }} >{t('Congratulations!')}</H4>
        <H6 attrH6={{ className: 'alert-heading' }} >{t('Foi enviado para ' + props.mailMascared + ' para que seja confirmado a sua conta. Acesse-o e confirme!')}</H6>
      </Alerts> : ''}

      {mailValid === false && applyPolitc ? <Alerts attrAlert={{ color: 'secondary  alert-dismissible' }} >
        <H4 attrH4={{ className: 'alert-heading' }} >{'Sorry!'}</H4>
        <H6 attrH6={{ className: 'alert-heading' }} >{t('O e-mail informado está incorreto, favor verificar o e-mail informado!')}</H6>
      </Alerts> : ''}

      {applyPolitc === false ? <Alerts attrAlert={{ color: 'secondary  alert-dismissible' }} >
        <H4 attrH4={{ className: 'alert-heading' }} >{'Sorry!'}</H4>
        <H6 attrH6={{ className: 'alert-heading' }} >{t('É obrigatório aceitar as políticas de segurança!')}</H6>
      </Alerts> : ''}

      <FormGroup>
        <Label className='col-form-label m-0 pt-0'>{t('Your Name')}</Label>
        <Row className='g-2'>
          <Col xs='12'>
            <Input className='form-control' type='text' required='true' placeholder={t('Fist Name')} onChange={(e) => setName(e.target.value)} value={name} />
          </Col>         
        </Row>
      </FormGroup>
      <FormGroup>
        <Label className='col-form-label m-0 pt-0'>{t('CPF / CNPJ')}</Label>
        <Row className='g-2'>
          <Col xs='12'>
            <Input className='form-control' type='text' required='true' placeholder={t('CPF / CNPJ')} onChange={(e) => setCpf(e.target.value)} value={cpf} />
          </Col>         
        </Row>
      </FormGroup>
      <FormGroup>
        <Label className='col-form-label m-0 pt-0'>{t(EmailAddress)}</Label>
        <Input className='form-control' type='email' required='true' placeholder='test@gmail.com' onChange={(e) => { setMail(e.target.value); setUsername(e.target.value); }} value={mail} />
      </FormGroup>
      <FormGroup className='position-relative'>
        <Label className='col-form-label m-0 pt-0'>{t(Password)}</Label>
        <div className='position-relative'>
          <Input className='form-control' type={togglePassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' onChange={(e) => { setPassword(e.target.value); calcularForcaSenha(); }} value={password} />
          <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
            <span className={togglePassword ? '' : t(show)}></span>
          </div>
        </div>
        <Progressbar attrProgress={{ color: 'primary', value: '' +pontuacaoSenha, animated: true  }} />
        <div id="forcaLabel"></div>
      </FormGroup>
      <FormGroup className='m-0'>
        <div className='checkbox'>
          <Input id='checkbox1' type='checkbox'
            checked={applyPolitc === true} value={applyPolitc} onChange={(e) => setApplyPolitc(e.target.checked ? true : false)} />
          <Label className='text-muted' for='checkbox1'>
            {t('Agree with')} <span>{t('Privacy Policy')}</span>
          </Label>
        </div>
      </FormGroup>
      
      <div>
        <ReCAPTCHA
          sitekey="6Le0cK4pAAAAAPD3n7ER4Llwu-vTEOgcBuf5oiDB"
          onChange={props.handleRecaptchaChange}
        /></div>
        
       
      
      <FormGroup>
        <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'button', onClick: (e) => submitDados(request) }}>{t('Create Account')}</Btn>
      </FormGroup>


      <P attrPara={{ className: 'mb-0 text-start' }}>
        {t('Already have an account?')}
        <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
          {t(Login)}
        </Link>
      </P>
    </Form>

  );
};


export default RegisterFrom;
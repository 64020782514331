import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, H6, P } from '../../../AbstractElements';
import { Login } from '../../../Constant';
import Loader from '../../../Layout/Loader';
import authService from '../../../Services/auth.service';
import Alerts from '../../../CommonElements/Alert';

const ForgetPwd = () => {
  const { t } = useTranslation();
  const [mail, setmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const [mailMascared, setMailMascared] = useState(false);
  const [errors, setErrors] = useState(null);


  const recuperarSenha = (e) => {

    if (authService.validateMail(mail)) {
      setLoading(true);
      console.log(mail);
      return authService.recuperarSenha(mail)
        .then((response) => {
          setLoading(false);
          setErrors(null);
          setSended(response.data);
          setMailMascared(authService.mascararEmail(mail));
        }).finally((f) => {
          setLoading(false);
        });
    } else {
      setErrors(t('Invalid mail informed! This field is required!'));
    }
  }

  return (
    loading ? (
      <Loader show={loading} />
    ) :
      <Fragment>
        <section>
          <Container className='p-0 login-page' fluid={true}>
            <Row className='m-0'>
              <Col className='p-0'>
                <div className='login-card'>
                  <div className='login-main'>
                    {errors ? <Alerts attrAlert={{ color: 'secondary  alert-dismissible' }} >
                      <H4 attrH4={{ className: 'alert-heading' }} >{t('Sorry!')}</H4>
                      <H6 attrH6={{ className: 'alert-heading' }} >{t(errors)}</H6>
                    </Alerts> : ''}

                    {
                      sended ?
                        <>
                          <P attrPara={{ className: 'mb-0 text-start' }}>
                            {t('Foi enviado um e-mail para ') + mailMascared + t(' com o link para recuperação de senha. Acesso o e-mail enviado e continue o processo!')}
                          
                          </P>
                          <P attrPara={{ className: 'mb-0 text-start' }}>
                            {t('Return to ')}
                            <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                              {t('Login')}
                            </Link>
                          </P>

                        </> : (
                          <Form className='theme-form login-form'>
                            <H4>{t('Reset Your Password')}</H4>
                            <FormGroup>
                              <Label className='m-0 col-form-label'>{t('Enter Your mail')}</Label>
                              <Row>
                                <Col>
                                  <Input className='form-control' type='mail' onChange={(e) => setmail(e.target.value)} value={mail} />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className='text-end'>
                              <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => recuperarSenha(mail) }}>{t('Send')}</Btn>

                            </FormGroup>

                            <P attrPara={{ className: 'mb-0 text-start' }}>
                              {t('Already have an account?')}
                              <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                                {t(Login)}
                              </Link>
                            </P>
                          </Form>
                        )
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
  );
};

export default ForgetPwd;

import { Activity, Cast, CheckCircle, FilePlus, Trash } from 'react-feather';

export const ClientCategoriesList = [
  {
    title: 'Todos os clientes',
    icon: <FilePlus />,
    color: 'primary',
  },
  {
    title: 'Ativos',
    icon: <CheckCircle />, 
    color: 'success',
  },
  {
    title: 'Pendentes',
    icon: <Cast />, 
    color: 'danger',
  },
  
];

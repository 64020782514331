import DateService from "../../Services/utils/date.service";

 
 

export const notificacaoColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '80px',
  },
  {
    name: 'Assunto',
    selector: (row) => row.subject,
    sortable: true,
    center: true 
  },
  {
    name: 'Conteúdo',
    selector: (row) => row.content,
    sortable: true,
    center: true 
  },
  {
    name: 'Data',
    selector: (row) =>  DateService.getDateTime(row.dateCreated),
    sortable: true,
    center: true 
  }, 
  {
    name: 'Lida',
    selector: (row) =>  row.readed ? 'Sim' : 'Não',
    sortable: true,
    center: true ,
    maxWidth: '80px'
  }, 
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: false,
    center: false ,
    maxWidth: '80px',
  },
  
];

import React, { useEffect, useState } from 'react';
import { Col , Row } from 'reactstrap';
import { H5, H6 } from '../../../../../AbstractElements';
import { PlusCircle } from 'react-feather'; 
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';

const EnviarMensagemSmsForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const [editorContent, setEditorContent] = useState('');
    const [dadosUsuario, setDadosUsuario] = useState(null);
    const [loading, setLoading] = useState(true);
    const [enviandoSms, setEnviandoSms] = useState(false);

    
    useEffect(() => { 
        if (props.cliente !== 0) {
            getDadosCliente();
        } else {
            setLoading(false);
        }
    }, []);

    const getDadosCliente = () => {
        return clienteService.findById(props.cliente)
            .then((response) => {
                setDadosUsuario(response.data);
                setLoading(false);
                return response;
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

     const enviarSms = async () => {
        setEnviandoSms(true);
        try {

            const response = await clienteService.enviaSms(company.id, {
                body: editorContent,
                companyId: company.id,
                userId: dadosUsuario ? dadosUsuario.client.user.id : null,
                filter : props.requestFilter
            });
            setEnviandoSms(false);

            if (response.status === 201) {
                SweetAlert.fire({
                    title: 'Sms enviado com sucesso!',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                }).then((result) => {
                    setEditorContent('');
                });
            } else {
                SweetAlert.fire({
                    title: 'Erro ao enviar sms',
                    icon: 'danger',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                });

            }
        } catch (error) {
            console.error('Erro ao enviar sms:', error);
            alert('Erro ao enviar sms.');

            setEnviandoSms(false);

        }
    };

    return (loading ? 'Carregando dados' :
        <>
            Enviar SMS para <H5>{dadosUsuario ? dadosUsuario.client.cellphone :  props.totalClientes + ' cliente(s)'}</H5> 

            <Row>
                <Col>
                    <label htmlFor="emailDestinatario">Conteúdo</label>
                    <textarea className='form-control' 
                        value={editorContent} maxLength={160}
                        onChange={(e) => setEditorContent(e.target.value)} // Corrige para atualizar o estado com o valor do textarea
                        style={{ height: '100px' }} // Define a altura do editor
                    />
                </Col>

            </Row>
            <br />
            <br />
            {
                enviandoSms ? 
                    <button type="button" className="btn btn-success disabled w-100 btn-sm input-group-text" >
                        <H6><PlusCircle /> Enviando... </H6>
                    </button> :
                    <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={enviarSms}>
                        <H6><PlusCircle /> Enviar </H6>
                    </button>
            }

        </>
    );
}
export default EnviarMensagemSmsForm;
 
import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import { CardFooter } from 'reactstrap'
import { ACTIVE, Cancel, Delete, Edit, INACTIVE, New, Submit } from '../../../../../Constant';
import { Btn, H1, H3, H5, H6, LI, P, Ribbon, UL } from '../../../../../AbstractElements';
import { useTranslation } from 'react-i18next';
import segmentacaoService from '../../../../../Services/crm/segmentacao.service';
import { ArrowDown, PlusCircle } from 'react-feather';



const SegmentacaoFormData = (props) => {
  const { t } = useTranslation();
  const [option, setOption] = useState(props.option);
  const company = JSON.parse(localStorage.getItem('company'));
  const user = JSON.parse(localStorage.getItem('user'));
  const [status, setStatus] = useState(props.data.status ? props.data.status : ACTIVE);



  const [id, setId] = useState(props.data.id);
  const [descricao, setDescricao] = useState(props.data.descricao);
  const [loading, setLoading] = useState(false);
  const [grupos, setGrupos] = useState(props.data.condicoes ? JSON.parse(props.data.condicoes) : [{ condicoes: [{ tipo: '', condicao: '', condicaoFinal: '' }] }]);


  // Adicionar um novo grupo vazio
  const handleAddGroup = () => {
    setGrupos([...grupos, { condicoes: [{ tipo: '', condicao: '', condicaoFinal: '' }] }]);
  };

  // Adicionar uma nova condição ao grupo
  const handleAddCondition = (groupIndex) => {
    const newGrupos = [...grupos];
    newGrupos[groupIndex].condicoes.push({ tipo: '', condicao: '', condicaoFinal: '' });
    setGrupos(newGrupos);
  };

  // Remover condição de um grupo específico
  const handleRemoveCondition = (groupIndex, conditionIndex) => {
    const newGrupos = [...grupos];
    newGrupos[groupIndex].condicoes = newGrupos[groupIndex].condicoes.filter((_, i) => i !== conditionIndex);
    setGrupos(newGrupos);
  };

  // Atualizar valores dos campos
  const handleChange = (groupIndex, conditionIndex, field, value) => {
    const newGrupos = [...grupos];
    newGrupos[groupIndex].condicoes[conditionIndex][field] = value;
    setGrupos(newGrupos);
  };

  // Remover um grupo específico
  const handleRemoveGroup = (groupIndex) => {
    const newGrupos = grupos.filter((_, i) => i !== groupIndex);
    setGrupos(newGrupos);
  };


  var request = {
    id: id,
    descricao: descricao,
    companyId: company.id,
    userId: user.id,
    status: status,
    grupos: grupos
  }

  const retornaErro = (field) => {
    try {
      if (props.errors) {
        const errors = props.errors.filter(e => e.objectName === field);
        console.log(errors);
        return errors || []; // Retorna um array vazio se não houver erros
      }
      return []; // Retorna um array vazio se props ou props.errors forem nulos
    } catch (error) {
      // console.error('Erro ao filtrar erros:', error);
      return []; // Retorna um array vazio em caso de erro
    }
  };



  return (
    <Fragment>
      <Col sm="12">
        {loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) :
          <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
            <Card className={'card-absolute'}>
              <CardHeader className={'bg-primary'}>
                <H5>{t(option)}</H5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label className="col-form-label">Id</Label>
                      {option !== New ?
                        <Input className="form-control btn-square disabled" type="text" placeholder="Id" onChange={(e) => setId(e.target.value)} value={id} readOnly /> :
                        <Input className="form-control btn-square disabled" type="text" placeholder="Código será gerado automaticamente" disabled readOnly />
                      }
                    </FormGroup>
                  </Col>

                  <Col md={6}><FormGroup>
                    <Label className="col-form-label"> Descrição</Label>
                    <Input className="form-control btn-square" type="text" onChange={(e) => setDescricao(e.target.value)} value={descricao} />
                    <span style={{ color: 'red' }}>{retornaErro('name')}</span>
                  </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label className="col-form-label">Status:</Label><br />
                      <Label className="switch">
                        <Input type="checkbox" checked={status === ACTIVE} value={status} onChange={(e) => setStatus(e.target.checked ? ACTIVE : INACTIVE)} />
                        <span className="switch-state" ></span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>


                {grupos.map((grupo, groupIndex) => (
                  <>
                    <Card className={'ribbon-wrapper'} style={{ border: '2px solid #ccc', borderRadius: '8px', padding: '8px', backgroundColor: '#f9f9f9' }} key={groupIndex}>

                      <CardBody>
                        <Ribbon attrRibbons={{ className: 'ribbon ribbon-primary' }} >Grupo {groupIndex + 1}</Ribbon>

                        <div className="position-absolute" style={{ top: '10px', right: '10px', display: 'flex', gap: '10px' }}>



                          <button
                            type="button"

                            onClick={() => handleRemoveGroup(groupIndex)}
                          >
                            <i className="fa fa-times"></i>
                          </button>



                        </div>


                        {grupo.condicoes.map((condicao, conditionIndex) => (
                          <div key={conditionIndex} className="position-relative" style={{ marginBottom: '30px' }}>

                            <Row className="mb-2"
                              style={{
                                border: '2px solid #ccc',
                                borderRadius: '8px',
                                padding: '15px',
                                position: 'relative',
                              }} >
                              <Col style={{ maxWidth: '50px', backgroundColor: '#007bff', color: '#fff', borderRadius: '8px', }}
                                className="d-flex align-items-center justify-content-center"
                              ><div style={{ transform: 'rotate(-90deg)', fontSize: 10, whiteSpace: 'nowrap', }} >Condição {conditionIndex + 1} </div>
                              </Col>
                              <Col md={10}>
                                <Row >

                                  <Col md={4}>
                                    <FormGroup>
                                      <div className="position-relative">
                                        <select
                                          className="form-control"
                                          value={condicao.tipo} required
                                          onChange={(e) => handleChange(groupIndex, conditionIndex, 'tipo', e.target.value)}
                                        >
                                          <option  >
                                            Selecione o filtro desejado
                                          </option>
                                          <optgroup label="Classificação">
                                            <option value="classificacao">Classificação</option>
                                            <option value="ranking" >Ranking</option>
                                            <option value="tag" disabled>Tag (Pendente)</option>
                                            <option value="nps" >NPS (Última avaliação)</option>
                                          </optgroup>
                                          <optgroup label="Dados do Cliente">
                                            <option value="aniversario" disabled>Aniversário</option>
                                            <option value="idade">Idade</option>
                                            <option value="sexo" >Gênero</option>
                                            <option value="teste-ab" disabled>Teste A/B (Pendente)</option>
                                            <option value="email">Email</option>
                                          </optgroup>
                                          <optgroup label="Fidelidade">
                                            <option value="pontos_conquistados" >Pontos conquistados</option>
                                            <option value="saldo_atual" >Saldo atual</option>
                                            <option value="pontos_expirando" disabled>Pontos expirando (Pendente)</option>
                                          </optgroup>
                                          <optgroup label="Métricas">
                                            <option value="primeira_visita" disabled>Primeira visita (Pendente)</option>
                                            <option value="ultima_visita" disabled>Última visita (Pendente)</option>
                                            <option value="presencas"  >Número de presenças</option>
                                            <option value="compras" >Compras</option>
                                            <option value="ticket_medio" disabled>Ticket médio (Pendente)</option>
                                            <option value="dinheiro_gasto_compra_andamento" disabled>Dinheiro gasto (compra em andamento) (Pendente)</option>
                                            <option value="frequencia" disabled>Frequência (Pendente)</option>
                                            <option value="receita_gerada" disabled>Receita gerada (Pendente)</option>
                                          </optgroup>
                                        </select>  <span
                                          className="fa fa-chevron-down position-absolute"
                                          style={{ top: '50%', right: '15px', transform: 'translateY(-50%)', pointerEvents: 'none' }}
                                        ></span>
                                      </div>
                                    </FormGroup>
                                  </Col>

                                  <Col md={4}><FormGroup>
                                    <div className="position-relative">
                                      <select
                                        className="form-control" required
                                        value={condicao.condicao}
                                        onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicao', e.target.value)}
                                      >
                                        <option value="">Selecione a condição</option>

                                        {condicao.tipo === 'classificacao' && (

                                          <>

                                            <option value="igual_a">Igual a</option>
                                            <option value="diferente_de">Diferente de</option>
                                          </>

                                        )}
                                        {(condicao.tipo === 'ranking' || condicao.tipo === 'nps' || condicao.tipo === 'idade'
                                          || condicao.tipo === 'saldo_atual' || condicao.tipo === 'presencas' || condicao.tipo === 'compras'
                                          || condicao.tipo === 'pontos_conquistados') && (
                                            <>


                                              <option value="menor_ou_igual_a">Menor ou igual a</option>
                                              <option value="maior_ou_igual_a">Maior ou igual a</option>
                                              <option value="igual_a">Igual a</option>
                                              <option value="diferente_de">Diferente de</option>
                                            </>

                                          )}
                                        {condicao.tipo === 'aniversario' && (

                                          <>

                                            <option value="faltando">Faltando</option>
                                            <option value="no_dia">No dia</option>
                                            <option value="foi_ha">Foi exatamente há</option>
                                            <option value="no_periodo_de">No período de</option>
                                          </>
                                        )}

                                        {(condicao.tipo === 'email') && (
                                          <>

                                            <option value="igual_a">Igual a</option>
                                            <option value="diferente_de">Direferente de</option>
                                            <option value="contem">Contém</option>
                                            <option value="nao_contem">Não contém</option>
                                          </>

                                        )}

                                        {(condicao.tipo === 'sexo') && (
                                          <>

                                            <option value="igual_a">Igual a</option>
                                            <option value="diferente_de">Direferente de</option>
                                          </>

                                        )} </select>
                                      <span
                                        className="fa fa-chevron-down position-absolute"
                                        style={{ top: '50%', right: '15px', transform: 'translateY(-50%)', pointerEvents: 'none' }}
                                      ></span>
                                    </div>
                                  </FormGroup>
                                  </Col>

                                  <Col md={4}>
                                    {condicao.tipo === 'classificacao' && (

                                      <Input
                                        type="select" required
                                        value={condicao.condicaoFinal}
                                        onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicaoFinal', e.target.value)}
                                      >
                                        <option>Todas</option>
                                        <option value={"NOVO"} >{'Novo'}</option>
                                        <option value={"VIP"} >{'Vip (Pendente)'}</option>
                                        <option value={"HABITUAIS"} >{'Habituais (Pendente)'}</option>
                                        <option value={"ESPORATICOS"} >{'Esporáticos (Pendente)'}</option>
                                        <option value={"UMA_COMPRA"} >{'Uma compra'}</option>
                                        <option value={"PERDIDOS"} >{'Perdidos'}</option>
                                        <option value={"SEM_COMPRAS"} >{'Sem compras'}</option>
                                        <option value={"REGULARES"} >{'Regulares (Pendente)'}</option>
                                        <option value={"RECUPERADOS"} >{'Recuperados (Pendente)'}</option>
                                      </Input>

                                    )}

                                    {(condicao.tipo === 'ranking' || condicao.tipo === 'nps'
                                      || condicao.tipo === 'presencas' || condicao.tipo === 'compras'
                                      || condicao.tipo === 'pontos_conquistados' || condicao.tipo === 'saldo_atual') && (
                                        <Input className="form-control btn-square" required type="number" onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicaoFinal', e.target.value)} value={condicao.condicaoFinal} />
                                      )}
                                    {(condicao.tipo === 'idade') && (
                                      <Input className="form-control btn-square" required type="number" onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicaoFinal', e.target.value)} value={condicao.condicaoFinal} />
                                    )}

                                    {(condicao.tipo === 'aniversario') && (
                                      <Input className="form-control btn-square" required type="number" onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicaoFinal', e.target.value)} value={condicao.condicaoFinal} />
                                    )}
                                    {(condicao.tipo === 'email') && (
                                      <Input className="form-control btn-square" required type="email" onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicaoFinal', e.target.value)} value={condicao.condicaoFinal} />
                                    )}


                                    {condicao.tipo === 'sexo' && (

                                      <Input
                                        type="select" required
                                        value={condicao.condicaoFinal}
                                        onChange={(e) => handleChange(groupIndex, conditionIndex, 'condicaoFinal', e.target.value)}
                                      >
                                        <option >Selecione a condição desejada</option>
                                        <option value={"MALE"} >{'Masculino'}</option>
                                        <option value={"FEMALE"} >{'Feminino'}</option>
                                        <option value={"OTHER"} >{'Outros'}</option>
                                        <option value={"CANNOT_SAY"} >{'Prefiro não dizer'}</option>
                                        <option value={"NOT_INFORMED"} >{'Não informado'}</option>
                                      </Input>
                                    )}

                                  </Col>


                                </Row>
                              </Col>

                              <Col md="1"

                              >
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleRemoveCondition(groupIndex, conditionIndex)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </Col>

                            </Row>


                            {conditionIndex < grupo.condicoes.length - 1 && (
                              <div className="text-center">
                                <span
                                  style={{
                                    display: 'block',
                                    fontWeight: 'bold',
                                    marginBottom: '-25px',
                                  }}
                                >
                                  E
                                </span>

                              </div>
                            )}
                            {conditionIndex === grupo.condicoes.length - 1 && (
                              <div className="text-center">
                                <button
                                  type='button'
                                  className="btn btn-success" style={{
                                    position: 'absolute',
                                    bottom: '-10px',  // Posiciona um pouco abaixo da borda do Card
                                    left: '50%',  // Centraliza horizontalmente
                                    transform: 'translateX(-50%)',  // Ajusta a posição para o centro exato
                                    padding: '0 10px',
                                  }}
                                  onClick={() => handleAddCondition(groupIndex)}

                                > <PlusCircle /> Adicionar Condição
                                </button>



                              </div>
                            )}



                          </div>
                        ))}
                        {groupIndex < grupos.length - 1 && (
                          <>
                            <span
                              style={{
                                position: 'absolute',
                                bottom: '-10px',  // Posiciona um pouco abaixo da borda do Card
                                left: '50%',  // Centraliza horizontalmente
                                transform: 'translateX(-50%)',  // Ajusta a posição para o centro exato
                                backgroundColor: '#fff',  // Cor de fundo para "ou"
                                padding: '0 5px',  // Espaçamento ao redor da palavra "ou"
                                color: '#333',  // Cor do texto
                                fontWeight: 'bold',  // Estilo do texto

                              }}
                            >
                              OU
                            </span>  <div
                              style={{
                                position: 'absolute',
                                bottom: '-40px',  // Ajusta para ficar abaixo do card
                                left: '50%',  // Centraliza horizontalmente em relação ao card
                                transform: 'translateX(-50%)',

                              }}
                            >
                              <ArrowDown color="#ccc" />
                            </div> </>)}

                        {groupIndex === grupos.length - 1 && (

                          <button type='button' className="btn  btn-primary" style={{
                            position: 'absolute',
                            bottom: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            padding: '0 10px',
                          }} onClick={handleAddGroup}>
                            <PlusCircle /> Adicionar Grupo
                          </button>)}
                      </CardBody>
                    </Card>


                  </>
                ))}

              </CardBody>
              <CardFooter className="text-end">
                {
                  option === Edit || option === New ?
                    <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "button", onClick: (e) => option === Edit ? props.update(request) : props.save(request) }}  >{'Salvar'}</Btn> :
                    <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "button", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
                }
                <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
              </CardFooter>
            </Card>

          </Form>

        }



      </Col>
    </Fragment>
  );
};
export default SegmentacaoFormData;

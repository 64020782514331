import React, { Fragment, useContext, useRef, useState, useEffect } from "react";
import { Breadcrumbs, Btn, H4, H5, Image } from "../../../../../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import userService from "../../../../../Services/administrativo/user.service";
import downloadService from "../../../../../Services/utils/download.service";
import CustomizerContext from '../../../../../_helper/Customizer';
import { Link } from 'react-router-dom';
import companyService from "../../../../../Services/administrativo/company.service";

const CrmProfileStore = ({ company }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const name = JSON.parse(localStorage.getItem('name'));
  const [mail, setMail] = useState(company.mail);
  const companyId = company.id;
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(downloadService.getBase64Picture(company.logo));


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (event.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);

      const response = await companyService.uploadLogo(companyId, formData);
      const image = await downloadService.getImagem(response.data);
      const imageBase64 = `data:image/${image.data.format};base64,${image.data.base64}`;
      setImageUrl(imageBase64);

      //setUploaded(true);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Card className='text-center'>
      <CardBody>

        <div className='user-image'>
          <div className='avatar'>
            <Image attrImage={{ alt: 'user', src: `${imageUrl}`, height: 200, onClick :  handleIconClick}} ></Image>
          </div>
        </div>
        <div className='icon-wrapper'  >
          <i className='icofont icofont-pencil-alt-5' onClick={handleIconClick}>
            <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={(e) => handleSubmit(e)} accept="image/*" />
          </i>

        </div>
        <Form>


          <div className='form-footer'>
            <Btn attrBtn={{ className: 'btn-block', color: 'primary' }}>{'Save'}</Btn>
          </div>

        </Form>
      </CardBody>
    </Card>
  );
};

export default CrmProfileStore; 
import React from "react";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Container } from "reactstrap"; 

const CrmMinhaLojaInstagram = () => {
    
    return (
        <>  
            <Breadcrumbs mainTitle='Instagram' parent='CRM' title='Minha loja' />
            <Container fluid={true}>
              
            </Container>
       </>
    );
};

export default CrmMinhaLojaInstagram; 
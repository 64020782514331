import React, { useState, useEffect } from "react";
import { Btn, H5, } from "../../../../../AbstractElements";
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import cityService from "../../../../../Services/administrativo/city.service";
import stateService from "../../../../../Services/administrativo/state.service";
import MaskedInput from "react-text-mask";
import personService from "../../../../../Services/administrativo/person.service";
import { toast } from "react-toastify";

// Máscara para CEP
const CepMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);


// Máscara para telefone
const PhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);

// Máscara para telefone
const CellPhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} // Correção: passando a referência diretamente
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);

const CrmEditProfileStore = ({ company }) => {

  const [name, setName] = useState(company.person ? company.person.name : '');
  const [rg, setRG] = useState(company.person ? company.person.rg : '');
  const [dateOfBirth, setDateOfBirth] = useState(company.person ? company.person.dateOfBirth : '');
  const [phone, setPhone] = useState(company.person ? company.person.phone : '');
  const [cellphone, setCellphone] = useState(company.person ? company.person.cellphone : '');
  const [mail, setMail] = useState(company.person ? company.person.mail : '');
  const [zipCode, setZipCode] = useState(company.person ? company.person.zipCode : '');
  const [address, setAddress] = useState(company.person ? company.person.address : '');
  const [number, setNumber] = useState(company.person ? company.person.number : '');
  const [complement, setComplement] = useState(company.person ? company.person.complement : '');
  const [district, setDistrict] = useState(company.person ? company.person.district : '');
  const [cpfCnpj, setCpfCnpj] = useState(company.person ? company.person.cpfCnpjMascared : '');
  const [personType, setPersonType] = useState(company.person ? company.person.personType : '');
  const [stateId, setStateId] = useState(company.person && company.person.city ? company.person.city.state.id : 0);
  const [listOfCity, setListOfCity] = useState([]);
  const [listOfState, setListOfState] = useState([]);
  const [cityId, setCityId] = useState(company.person && company.person.city ? company.person.city.id : 0);
  const [loading, setLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [error, setError] = useState(null);


  const [site, setSite] = useState(company.person ? company.person.socialMedia.site : '');
  const [blog, setBlog] = useState(company.person ? company.person.socialMedia.blog : '');
  const [facebook, setFacebook] = useState(company.person ? company.person.socialMedia.facebook : '');
  const [instagram, setInstagram] = useState(company.person ? company.person.socialMedia.instagram : '');
  const [twitter, setTwitter] = useState(company.person ? company.person.socialMedia.twitter : '');

  const [linkedin, setLinkedin] = useState(company.person ? company.person.socialMedia.linkedin : '');
  const [tiktok, setTiktok] = useState(company.person ? company.person.socialMedia.tiktok : '');
  const [youtube, setYoutube] = useState(company.person ? company.person.socialMedia.youtube : '');



  var request = {
    name: name,
    rg: rg,
    dateOfBirth: dateOfBirth,
    phone: phone,
    cellphone: cellphone,
    mail: mail,
    zipCode: zipCode,
    address: address,
    number: number,
    complement: complement,
    district: district,
    personType: personType,
    cpfCnpj: cpfCnpj,
    city: cityId,
    socialMedia: {
    site: site,
    blog: blog,
    facebook: facebook,
    instagram: instagram,
    twitter: twitter,
    linkedin: linkedin,

    tiktok: tiktok,
    youtube: youtube }
  }

  useEffect(() => {
    setCityLoading(true);
    const fetListOfState = async () => {
      try {
        const response = await stateService.getAll();
        const data = await response.data;
        setListOfState(data);

        const responseCity = await cityService.getAll(stateId);
        const dataCity = await responseCity.data;

        setListOfCity(dataCity);
        setCityLoading(false);

      } catch (error) {
        console.error('Error to load options:', error);
        setCityLoading(false);
      }
    };
    fetListOfState();
  }, []);


  const update = (request) => {
    setLoading(true);
    if (company.person.id != null) {
      return personService.update(company.person.id, request)
        .then((response) => {
          toast.success("Informações básicas alterada com sucesso!");

          setLoading(false);
          return response;
        }).catch((error) => {
          console.log(error.response)
          toast.error("Erro ao logar = " + error.response.data);
          setError(error);
          setLoading(false);
        });
    }
  }

  const updateCityList = async (stateId) => {
    try {
      if (stateId === '') {
        setListOfCity([]);
      } else {
        if (stateId == null) setListOfCity([]);
        else {
          const response = await cityService.getAll(stateId);
          const data = await response.data;
          setListOfCity(data);
          setStateId(stateId);
        }
      }
    } catch (error) {
      console.error('Error to load options:', error);
    }
  }

  return (
    <Card>

      <CardBody>
        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
          <fieldset>
            <legend><H5>Dados Pessoais</H5></legend>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="col-form-label"> Nome</Label>
                  <Input className="form-control btn-square " type="text" onChange={(e) => setName(e.target.value)} value={name} />
                </FormGroup>
              </Col>


              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> Tipo de Pessoa</Label>
                  <Input className="form-control btn-square disabled" type="text" onChange={(e) => setPersonType(e.target.value)} value={personType} />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> CPF/CNPJ</Label>
                  <Input className="form-control btn-square disabled" type="text" onChange={(e) => setCpfCnpj(e.target.value)} value={cpfCnpj} />
                </FormGroup>
              </Col>
            </Row>

            {
              personType !== 'JURIDICA' ?
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label className="col-form-label"> RG</Label>
                      <Input className="form-control btn-square" type="text" onChange={(e) => setRG(e.target.value)} value={rg} />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label className="col-form-label">Data de Nascimento</Label>
                      <Input className="form-control btn-square" type="date" placeholder="dd/mm/aaaa" onChange={(e) => setDateOfBirth(e.target.value)} value={dateOfBirth} />
                    </FormGroup>
                  </Col> </Row> : ''}

          </fieldset>
          <fieldset>
            <legend><H5>Endereço</H5></legend>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> CEP</Label>
                  <Input tag={CepMask} className="form-control btn-square" type="text" onChange={(e) => setZipCode(e.target.value)} value={zipCode} />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label className="col-form-label"> Endereço</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setAddress(e.target.value)} value={address} />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> Número</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setNumber(e.target.value)} value={number} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> Complemento</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setComplement(e.target.value)} value={complement} />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> Bairro</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setDistrict(e.target.value)} value={district} />
                </FormGroup>
              </Col>
              <Col md={3}>

                <FormGroup>

                  <Label className="col-form-label">Estado:</Label>
                  <div className="position-relative">
                    <select className='form-control' size='1' value={stateId} onChange={(e) => { setStateId(e.target.value.id); updateCityList(e.target.value); }}>
                      <option value='' >Selecione</option>
                      {listOfState.map((state) => (
                        <option value={state.id} key={state.id} selected={state.id === state} >{state.name}</option>
                      ))}
                    </select> <span
                      className="fa fa-chevron-down position-absolute"
                      style={{ top: '50%', right: '15px', transform: 'translateY(-50%)', pointerEvents: 'none' }}
                    ></span>
                  </div>
                </FormGroup>
              </Col><Col md={3}>
                <FormGroup>
                  <Label className="col-form-label">Cidade:</Label>   <div className="position-relative">
                    <select className='form-control' size='1' value={cityId} onChange={(e) => setCityId(e.target.value)}>
                      <option >{cityLoading ? 'Carregando lista de cidades' : 'Selecione'}</option>
                      {listOfCity.map((city) => (
                        <option value={city.id} key={city.id} selected={city.id === city} >{city.name}</option>
                      ))}
                    </select>
                    <span
                      className="fa fa-chevron-down position-absolute"
                      style={{ top: '50%', right: '15px', transform: 'translateY(-50%)', pointerEvents: 'none' }}
                    ></span>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <legend><H5>Contatos</H5></legend>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> Telefone</Label>
                  <Input tag={PhoneMask} className="form-control btn-square" type="text" onChange={(e) => setPhone(e.target.value)} value={phone} />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label"> Celular</Label>
                  <Input tag={CellPhoneMask} className="form-control btn-square" type="text" onChange={(e) => setCellphone(e.target.value)} value={cellphone} />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label className="col-form-label"> Email</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setMail(e.target.value)} value={mail} />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <legend><H5>Redes Sociais</H5></legend>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="col-form-label">Site</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setSite(e.target.value)} value={site} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="col-form-label"> Blog</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setBlog(e.target.value)} value={blog} />
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="col-form-label">Facebook</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setFacebook(e.target.value)} value={facebook} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="col-form-label"> Instagram</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setInstagram(e.target.value)} value={instagram} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="col-form-label"> X (Twitter)</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setTwitter(e.target.value)} value={twitter} />
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="col-form-label">Linkedin</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setLinkedin(e.target.value)} value={linkedin} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="col-form-label">Tiktok</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setTiktok(e.target.value)} value={tiktok} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="col-form-label"> Youtube</Label>
                  <Input className="form-control btn-square" type="text" onChange={(e) => setYoutube(e.target.value)} value={youtube} />
                </FormGroup>
              </Col>

            </Row>
          </fieldset>
        </Form>

      </CardBody>
      <CardFooter className="text-end">
        <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => update(request) }}  >{'Salvar'}</Btn>
      </CardFooter>
    </Card>
  );
};

export default CrmEditProfileStore; 
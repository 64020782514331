import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import { CardFooter } from 'reactstrap'
import { ACTIVE, Cancel, Delete, Edit, INACTIVE, New, Submit } from '../../../../Constant';
import { Btn, H5 } from '../../../../AbstractElements';
import { useTranslation } from 'react-i18next';



const IntegrationFormData = (props) => {
  const { t } = useTranslation();
  const [option, setOption] = useState(props.option);
  const company = JSON.parse(localStorage.getItem('company'));
  const user = JSON.parse(localStorage.getItem('user'));
  const [status, setStatus] = useState(props.data.status === "" ? ACTIVE : props.data.status);

  const [id, setId] = useState(props.data.id);
  const [descricao, setDescricao] = useState(props.data.descricao);
  const [fornecedor, setFornecedor] = useState(props.data.fornecedor);
  const [dadosAcesso, setDadosAcesso] = useState(props.data.dadosAcesso);
  const [token, setToken] = useState(props.data.token);
  const [identificador, setIdentificador] = useState(props.data.identificador);

 
 

  var request = {
    id: id,
    descricao: descricao, 
    status: status,
    fornecedor: fornecedor,
    dadosAcesso: dadosAcesso,
    token: token,
    identificador: identificador 
  }

  const retornaErro = (field) => {
    try {
      if (props.errors) {
        const errors = props.errors.filter(e => e.objectName === field);
        console.log(errors);
        return errors || []; // Retorna um array vazio se não houver erros
      }
      return []; // Retorna um array vazio se props ou props.errors forem nulos
    } catch (error) {
      // console.error('Erro ao filtrar erros:', error);
      return []; // Retorna um array vazio em caso de erro
    }
  };


  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }
        <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
          <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              <H5>{t(option)}</H5>
            </CardHeader>
            <CardBody><Row><Col md={3}>
              <FormGroup>
                <Label className="col-form-label">Id</Label>
                {option !== New ?
                  <Input className="form-control btn-square disabled" type="text" placeholder="Id" onChange={(e) => setId(e.target.value)} value={id} readOnly /> :
                  <Input className="form-control btn-square disabled" type="text" placeholder="Código será gerado automaticamente" disabled readOnly />
                }
              </FormGroup>
            </Col>


              <Col md={6}><FormGroup>
                <Label className="col-form-label"> Descrição</Label>
                <Input className="form-control btn-square" type="text" onChange={(e) => setDescricao(e.target.value)} value={descricao} />
                <span style={{ color: 'red' }}>{retornaErro('name')}</span>
              </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label className="col-form-label">Status:</Label>
                  <Label className="switch">
                    <Input type="checkbox" checked={status === ACTIVE} value={status} onChange={(e) => setStatus(e.target.checked ? ACTIVE : INACTIVE)} />
                    <span className="switch-state" ></span>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>              
              <Col md={3}>
              <FormGroup>
                    <Label className="col-form-label">Classificação:</Label>
                    <div className="position-relative">
                      <Input type="select" value={fornecedor}
                        onChange={(e) => setFornecedor(e.target.value)}>
                        <option >{'Nenhum'}</option>
                        <option value={"WHATSAPP"} >{'Whatsapp'}</option>
                        <option value={"INSTAGRAM"} >{'Instagram'}</option>
                      </Input>       <span
                        className="fa fa-chevron-down position-absolute"
                        style={{ top: '50%', right: '15px', transform: 'translateY(-50%)', pointerEvents: 'none' }}
                      ></span>
                    </div>
                  </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="col-form-label"> Dados de acesso</Label>
                <Input className="form-control btn-square" type="text" onChange={(e) => setDadosAcesso(e.target.value)} value={dadosAcesso} />
                <span style={{ color: 'red' }}>{retornaErro('dadosAcesso')}</span>
              </FormGroup>
            </Col>
            
            <Col md={3}>
              <FormGroup>
                <Label className="col-form-label"> Token</Label>
                <Input className="form-control btn-square" type="text" onChange={(e) => setToken(e.target.value)} value={token} />
                <span style={{ color: 'red' }}>{retornaErro('token')}</span>
              </FormGroup>
            </Col>
              
            <Col md={3}>
              <FormGroup>
                <Label className="col-form-label"> Identificador</Label>
                <Input className="form-control btn-square" type="text" onChange={(e) => setIdentificador(e.target.value)} value={identificador} />
                <span style={{ color: 'red' }}>{retornaErro('identificador')}</span>
              </FormGroup>
            </Col>
           </Row> 

            </CardBody></Card>

        </Form>


        <CardFooter className="text-end">
          {
            option === Edit || option === New ?
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => option === Edit ? props.update(request) : props.save(request) }}  >{'Salvar'}</Btn> :
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
          }
          <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>

      </Col>
    </Fragment>
  );
};
export default IntegrationFormData;

import { useTranslation } from "react-i18next";
import React from 'react'; 
import { Loading} from "../../Constant"; 

const LoadingView = (props) => {
  const { t } = useTranslation();
  return (
    <div className='spinner-border Typeahead-spinner' role='status'>
      <span className='sr-only'>{t(Loading)}</span>
    </div> 
  );
};

export default LoadingView;
import { Row, Col } from "react-bootstrap";
import WidgetsGrid from "./WidgetsGrid";
import OrderOverview from "./OrderOverview";
import VisitorsCard from "./VisitorsCard";

const LeftGrid = () => {
    return (<Col xxl='12' md='12' className='box-col-8 grid-ed-12'>
        <Row className='row'>
            <WidgetsGrid />
            <Col xxl='7' xl='7' sm='6' className='box-col-6'>
                <VisitorsCard />
            </Col>
            <Col xxl='12' className='box-col-12'>
                <OrderOverview />
            </Col>
        </Row>
    </Col>);
};

export default LeftGrid;
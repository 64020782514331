// Arquivo gerado de forma automática pelo gerador. 
import React, { Fragment , useState} from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn} from '../../../../AbstractElements';
import { Card, Form, Input, CardBody, Col, Container, Row } from 'reactstrap';
import branchService from '../../../../Services/administrativo/branch.service'; 
import { toast } from "react-toastify";
import { New, TextTextSearch, Edit, Delete, TextSearch} from "../../../../Constant"; 

import SweetAlert from 'sweetalert2';
import BranchListData from './branch-list';
import BranchFormData from './branch-form';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const BranchPage = () => {
  const { t } = useTranslation();
  const [branchs, setbranchs] = useState([]); 
  const [option, setOption] = useState(""); 
  const [branch, setBranch] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0); 
  const [totalRows, setTotalRows] = useState(0); 
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  var requestFilter = {  
  } 

  const getListAll = async (e) => {
    setLoading(true);
    try {
      	const response = await branchService.getFilter(requestFilter , page, size);    
      	var branchList = await response.data.content;
      	setTotalRows(response.data.totalItens);
      	const transformedItems = branchList.map((branch) => ({
	      	...branch,
	      	action : (
		        <div>
		          <span>
		            <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick : (e) => btnDelete(branch) }}>Delete</Btn>
		          </span>{' '}
		          &nbsp;&nbsp;
		          <span>
		            <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' , onClick : (e) => btnEdit(branch) }}>{t(Edit)} </Btn>
		          </span>
		        </div>
	      	)
    		}
      	)
      );

      setbranchs(transformedItems);    
      setLoading(false);
    } catch (error ) {
      setError(error);
      setLoading(false);
    }
    return branchList;
  }

  const btnEdit = (branch) => {
    setOption(Edit); 
    setBranch(branch); 
  }

  const btnDelete = (branch) => {
    setOption(Delete); 
    setBranch(branch); 
  }

  const deletebranch = (request) => {
    if (request.id != null && request.id >0) {
      SweetAlert.fire({
        title: 'Are you right?',
        text: 'Uma vez excluído, você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          branchService.delete(request).then((response) => {
            SweetAlert.fire(
              'Removed!',
              'Registro foi apagado com sucesso.',
              'success'
            ); 
            getListAll();
		        setOption("list");
		        setBranch({});
		        return response;
          });
  
        } else {
          SweetAlert.fire(
            'The register went deleted with security!'
          );
        }
      });


    }  
  }

  const btnNovo = () => {
    setOption(New); 
    initialize();
  }
  
  const initialize = () => {
    setError(null);
		setBranch( {	id : -1 , 	name : "" , 	corporateName : "" , 	nickname : "" , 	rg : "" , 	phone : "" , 	cellphone : "" , 	mail : "" , 	zipCode : "" , 	address : "" , 	number : "" , 	complement : "" , 	district : "" , 	cpfCnpj : "" , } );
  }

  const btnCancel = () => {
    initialize();
    setOption("list");
  }


  const save = (request) => {
    return branchService.save(request)
    .then((response) => {
      initialize();
      setOption("list");
      toast.success("branch cadastrado com sucesso!"); 
      getListAll();
      return response;
    }).catch((error) => {
      setErrors(error.fieldErrors);
    });
     
  } 

  const update = async  (request) => {
    setLoading(true);
     if (request.id != null) {
       return branchService.update(request)
       .then((response) => {
         toast.success("branch alterado com sucesso!"); 
         getListAll();
         setOption("list");
         initialize();
         setLoading(false);
         return response;
       }).catch((error) => {
         toast.error("Erro ao logar = "+ error.response.data);
         setError(error);
         setLoading(false);
       });
     }  
   } 
   const handlePageChange = (page) => {
    setPage(page); 
    getListAll();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(0);
    setSize(newPerPage);
    getListAll();
  };
  

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de branchs' parent='Home' title='branchs' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
             <Card>
              <CardBody>
                <Form className='theme-form'>

                  <div className='input-group flex-nowrap'>
                      <span className='btn btn-success input-group-text' onClick={() => btnNovo() }>{t(New)}</span> 

                      <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)} />
                      <span className='btn btn-primary input-group-text'  onClick={() => {
                                  setOption('list');
                                  setPage(0);
                                  getListAll(requestFilter);
                                } }>{t(TextSearch)}</span>
                  </div>
                </Form>
                </CardBody>
                </Card> { 
                option === New || option === Edit  || option === "delete" 
                ?  <BranchFormData errors = {errors} loading={loading} error={error}  cancel={btnCancel} option={option}  delete={deletebranch}  update={update} save={save} data = {branch} />
                : option === "list" ? 
                <BranchListData totalRows = {totalRows} loading={loading} error={error}  handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} list={branchs} /> : '' }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BranchPage;

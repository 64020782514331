import React from 'react';
import { Card, CardBody, CardHeader, Col, Media, Row, UncontrolledTooltip } from 'reactstrap';
import { Btn, H3, H5, H6, LI, UL } from '../../../../../AbstractElements';
import { ChevronDown, ChevronUp, Image } from 'react-feather';
import downloadService from '../../../../../Services/utils/download.service';
import StarRatingClass from '../../../../Bonus-Ui/Rating/StarRating';
import AvalicaoCliente from './AvaliacaoCliente';
import dateService from '../../../../../Services/utils/date.service';
import DateService from '../../../../../Services/utils/date.service';

const InformacoesCliente = ({ cliente }) => {

    return (
        <>
            <Card>

                <CardBody className='socialprofile filter-cards-view'>
                    <Media>
                        <Image attrImage={{ className: 'img-50 img-fluid m-r-20 rounded-circle', src: downloadService.getBase64PictureObject(cliente.client.picture), alt: 'user' }} />
                    </Media>

                    
                    <Row >
                        <Col>   <span >{"Email"}:  <b>{cliente.client.mail}</b></span>
                        </Col>

                    </Row>
                    <Row >
                        <Col >
                            <span >{"Telefone"}: <b>{cliente.client.cellphone}</b></span>
                        </Col>

                    </Row>
                    <Row >
                        <Col >
                            <span >{"Data de Nascimento"}: <b>{dateService.getDate(cliente.client.dateOfBirth)}</b></span>
                        </Col>

                    </Row>
                    <br />
                    <div className='likes-profile text-center'>
                        <Row >
                            <Col sm="8">
                                <H3 attrH6={{ className: "mb-0 text-start" }} >{"Pontuação Atual"}: </H3>
                            </Col>
                            <Col sm="4">
                                <H3><i className='fa fa-heart font-danger'></i> {cliente.points}</H3>
                            </Col>
                        </Row>
                    </div>

                    <br />
                    <AvalicaoCliente rating={cliente.nps} />
                    <br />
 
                </CardBody>
            </Card>
        </>
    );
};

export default InformacoesCliente;

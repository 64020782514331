import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import AlertError from '../../../../../CommonElements/AlertError';
import { crmListaClientesColumns } from '../../../../../Data/crm/CrmListaClientes';
import companyService from '../../../../../Services/administrativo/company.service';
import { extratoSmsListColumns } from '../../../../../Data/administrativo/ExtratoSmsList';
import SkeletonTable from '../../../../../Layout/SkeletonTable';

const ExtratoSms = () => {
  var companyLocal = JSON.parse(localStorage.getItem('company'));
  const [mensagens, setMensagens] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchCompanyListMensagens = async () => {
      try {
        const response = await companyService.getExtratoSms(companyLocal.id);
        const data = await response.data;
        setMensagens(data);
      } catch (error) {
        console.error('Error to load options:', error);
        
      } finally {
        setLoading(false);
      }
    };
    fetchCompanyListMensagens();

  }, []);





  return (mensagens.length === 0 ? 'Nenhum registro encontrado' :
    <>
      <div className='table-responsive product-table'>{
        loading ? (
          <div>
            <SkeletonTable rows={10} columns={7} />
          </div>
          ) :  <DataTable noHeader pagination paginationServer columns={extratoSmsListColumns} data={mensagens} highlightOnHover={true} striped={true} responsive={true} /> 
      }

      </div>

    </>

  );
};

export default ExtratoSms; 
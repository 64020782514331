import React from "react"; 
import { Breadcrumbs } from "../../../../AbstractElements";
import { Container, Row } from "reactstrap";

const CrmRecuperarCliente = () => {
  
  return (
    <>
      
      <Breadcrumbs mainTitle={'Recuperar cliente'} parent='CRM' title='Dashboard' />
      <Container fluid={true}>
        <Row className='size-column'>         
          Recupere seus clientes
        </Row>
      </Container> 
    </>
  );
};

export default CrmRecuperarCliente; 
import React, {  } from "react"; 

const CrmOperator = () => {
 

  return (
    <>

         Operadores
      
    </>
  );
};

export default CrmOperator; 
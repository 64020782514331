import React, { useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ExtratoClienteList from "./ExtratoPontuacaoClienteList";
import ExtratoPontuacaoClienteList from './ExtratoPontuacaoClienteList';
import ExtratoResgateClienteList from './ExtratoResgateClienteList';

const ExtratosCliente = ({ cliente }) => {

    const [BasicLineTab, setBasicLineTab] = useState('1');
    return (
        <><Card>
            <CardBody>
                <Nav className='border-tab d-flex' tabs>
                    <NavItem className="flex-grow-1">
                        <NavLink href='#' className={'w-100' + BasicLineTab === '1' ? ' active btn btn-succes' : ''} onClick={() => setBasicLineTab('1')}>
                            <i className="icofont icofont-envelope"></i>
                            {'Pontuações'}
                        </NavLink>
                    </NavItem>

                    <NavItem className="flex-grow-1">
                        <NavLink href='#' className={'w-100' + BasicLineTab === '2' ? ' active' : ''} onClick={() => setBasicLineTab('2')}>
                            <i className="icofont icofont-envelope"></i>
                            {'Resgates'}
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={BasicLineTab}>
                    <TabPane className='fade show' tabId='1'>
                        <ExtratoPontuacaoClienteList cliente={cliente}  />
                         
                    </TabPane>
                    <TabPane tabId='2'>
                        <ExtratoResgateClienteList cliente={cliente}  />
                    </TabPane>
                  
                </TabContent></CardBody>
        </Card>
        </>
    );
};

export default ExtratosCliente;

// Arquivo gerado de forma automática pelo gerador. 
import React, { Fragment , useEffect, useState} from 'react';
import { useTranslation } from "react-i18next"; 
import { Card, Form, Input, CardBody, Col, Container, Row } from 'reactstrap'; 
import { toast } from "react-toastify"; 

import SweetAlert from 'sweetalert2';
import SegmentacaoListData from './segmentacao-list';
import SegmentacaoFormData from './segmentacao-form';
import segmentacaoService from '../../../../../Services/crm/segmentacao.service';
import { Delete, Edit, New, TextSearch } from '../../../../../Constant';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const SegmentacaoPage = () => {
  const { t } = useTranslation();
  const [segmentacoes, setSegmentacoes] = useState([]); 
  const [option, setOption] = useState(""); 
  const [segmentacao, setSegmentacao] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0); 
  const [totalRows, setTotalRows] = useState(0); 
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const company = JSON.parse(localStorage.getItem('company'));

  useEffect( () => {
    window.scrollTo(0, 0);
  }, []);


  var requestFilter = {  
    companyId : company.id
  } 

  const getListAll = async (e) => {
    setLoading(true);
    try {
      	const response = await segmentacaoService.getFilter(requestFilter , page, size);    
      	var segmentacaoList = await response.data.content;
      	setTotalRows(response.data.totalItens);
      	const transformedItems = segmentacaoList.map((segmentacao) => ({
	      	...segmentacao,
	      	action : (
		        <div>
		          <span>
		            <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick : (e) => btnDelete(segmentacao) }}>Delete</Btn>
		          </span>{' '}
		          &nbsp;&nbsp;
		          <span>
		            <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' , onClick : (e) => btnEdit(segmentacao) }}>{t(Edit)} </Btn>
		          </span>
		        </div>
	      	)
    		}
      	)
      );

      setSegmentacoes(transformedItems);    
      setLoading(false);
    } catch (error ) {
      setError(error);
      setLoading(false);
    }
    return segmentacaoList;
  }

  const btnEdit = (segmentacao) => {
    setOption(Edit); 
    setSegmentacao(segmentacao); 
  }

  const btnDelete = (segmentacao) => {
    setOption(Delete); 
    setSegmentacao(segmentacao); 
  }

  const deletesegmentacao = (request) => {
    if (request.id != null && request.id >0) {
      SweetAlert.fire({
        title: 'Are you right?',
        text: 'Uma vez excluído, você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          segmentacaoService.delete(request).then((response) => {
            SweetAlert.fire(
              'Removed!',
              'Registro foi apagado com sucesso.',
              'success'
            ); 
            getListAll();
		        setOption("list");
		        setSegmentacao({});
		        return response;
          });
  
        } else {
          SweetAlert.fire(
            'The register went deleted with security!'
          );
        }
      });


    }  
  }

  const btnNovo = () => {
    setOption(New); 
    initialize();
  }
  
  const initialize = () => {
    setError(null);
		setSegmentacao( {	id : -1 , 	name : "" , 	corporateName : "" , 	nickname : "" , 	rg : "" , 	phone : "" , 	cellphone : "" , 	mail : "" , 	zipCode : "" , 	address : "" , 	number : "" , 	complement : "" , 	district : "" , 	cpfCnpj : "" , } );
  }

  const btnCancel = () => {
    initialize();
    setOption("list");
  }


  const save = (request) => {
    return segmentacaoService.save(request)
    .then((response) => {
      initialize();
      setOption("list");
      toast.success("Segmentação cadastrada com sucesso!"); 
      getListAll();
      return response;
    }).catch((error) => {
      setErrors(error.fieldErrors);
    });
     
  } 

  const update = async  (request) => {
    setLoading(true);
     if (request.id != null) {
       return segmentacaoService.update(request)
       .then((response) => {
         toast.success("Segmentação alterada com sucesso!"); 
         getListAll();
         setOption("list");
         initialize();
         setLoading(false);
         return response;
       }).catch((error) => {
         toast.error("Erro ao logar = "+ error.response.data);
         setError(error);
         setLoading(false);
       });
     }  
   } 
   const handlePageChange = (page) => {
    setPage(page); 
    getListAll();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(0);
    setSize(newPerPage);
    getListAll();
  };
  

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de segmentações' parent='Home' title='Segmentações' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
             <Card>
              <CardBody>
                <Form className='theme-form'>

                  <div className='input-group flex-nowrap'>
                      <span className='btn btn-success input-group-text' onClick={() => btnNovo() }>{t(New)}</span> 

                      <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)} />
                      <span className='btn btn-primary input-group-text'  onClick={() => {
                                  setOption('list');
                                  setPage(0);
                                  getListAll(requestFilter);
                                } 
                                }>{t(TextSearch)}</span>
                  </div>
                </Form>
                </CardBody>
                </Card> { 
                option === New || option === Edit  || option === Delete
                ?  <SegmentacaoFormData errors = {errors} loading={loading} error={error}  cancel={btnCancel} option={option}  delete={deletesegmentacao}  update={update} save={save} data = {segmentacao} />
                : option === "list" ? 
                <SegmentacaoListData totalRows = {totalRows} loading={loading} error={error}  handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} list={segmentacoes} /> : '' }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SegmentacaoPage;

import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL + "/fanfave-api/role";

 class RoleService {
  
  getAll() {    
    return axios
      .get(API_URL, { headers: authHeader() });
  }

  getAllByModule(module) {    
    return axios
      .get(API_URL + '/byModule/'+module , { headers: authHeader() });
  }
  
}  

const roleService = new RoleService();
export default roleService;      
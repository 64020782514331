import React, { useState, useEffect } from 'react';
import userPerfilService from '../../Services/administrativo/userPerfil.service';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Image } from 'react-bootstrap';
import downloadService from '../../Services/utils/download.service';
import { H6, P } from '../../AbstractElements';


const TrocarEmpresa = (props) => {
    const [listOfCompany, setListOfCompany] = useState([]);
    const userId = JSON.parse(localStorage.getItem('userId'));
    const company = JSON.parse(localStorage.getItem('company'));
    useEffect(() => {
        const fetListOfCompany = async () => {
            try {
                const response = await userPerfilService.getCompanies(userId);
                const data = await response.data;


                setListOfCompany(data);

            } catch (error) {
                console.error('Error to load options:', error);
            }
        };
        fetListOfCompany();
    }, []);

    const updateCompany = async (company) => {
        try {
            const response = await userPerfilService.updateCompany(userId, company.id);
            const data = await response.data;
            if (data) {
                localStorage.setItem('company', JSON.stringify(company));
                localStorage.setItem('roles', JSON.stringify(data.roles));
                localStorage.setItem('actualModule', data.module);
                window.location.reload();
                props.toggle();
            } else {
                alert('Erro');
            }
        } catch (error) {
            console.error('Error to load options:', error);
        }
    };


    return <>
        <Row className='mb-5'>
            {listOfCompany.map((item, i) => (
                <Col key={i} >
                    <Card className={`${company.id === item.id ? 'ribbon-vertical-left-wrapper' : ''}`} style={{ cursor: 'pointer' }} onClick={(event) => {
                        event.preventDefault();
                        updateCompany(item);
                    }} >
                        {company.id === item.id ? (
                            <div className='ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary'>
                                <i className='icofont icofont-love'></i>
                            </div>
                        ) : (
                            ''
                        )}

                        <CardBody>
                            <div className='media'>
                                <div className='media-body'>
                                    <H6 attrH6={{ className: 'f-w-600' }}>
                                        {item.name}
                                    </H6>
                                    <P> <div className='media profile-media'>
                                        <Image
                                        attrImage={{
                                            className: 'img-30 img-fluid m-r-20 rounded-circle',
                                            src: downloadService.getBase64PictureObject(item.picture),
                                            alt: '',
                                        }}
                                    /></div></P>
                                </div>
                            </div>
                            <P>{item.description}</P>
                        </CardBody>

                    </Card>
                </Col>
            ))}
        </Row>
    </>;
}

export default TrocarEmpresa;
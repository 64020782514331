import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs,  } from "../../../../../AbstractElements";
import { Card, CardBody,   CardHeader, Col, Container,   Row, } from "reactstrap";
import companyService from "../../../../../Services/administrativo/company.service"; 
import ExtratoSms from "./ExtratoSms";
import SaldoSms from "./SaldoSms";

const CrmMinhaLojaSms = () => {
    var companyLocal = JSON.parse(localStorage.getItem('company'));

    const [saldoAtual, setSaldoAtual] = useState(null);

    useEffect(() => {
        const fetchCompanySaldoAtual = async () => {
            try {
                const response = await companyService.getSaldoSms(companyLocal.id);
                const data = await response.data;
                setSaldoAtual(data);
            } catch (error) {
                console.error('Error to load options:', error);
            }
        };
        fetchCompanySaldoAtual();
    }, []);



    return (
        <> <Fragment>
            <Breadcrumbs mainTitle='Sms' parent='CRM' title='Minha loja' />
            <Container fluid={true}>
                <Row>
                    <Col md={9}>
                    </Col>
                    <Col md={3}>
                        <SaldoSms  saldoAtual={saldoAtual} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                Extrato de SMS
                            </CardHeader>
                            <CardBody>
                                <ExtratoSms />
                            </CardBody>

                        </Card>

                    </Col>

                </Row>


            </Container>
        </Fragment></>
    );
};

export default CrmMinhaLojaSms; 
import React, { useEffect, useState } from 'react';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { CHECKALL, DeliveryComplete, DeliveryProcessing, Notification, OrderComplete, TicketsGenerated } from '../../../Constant';
import { Link } from 'react-router-dom';
import DateService from '../../../Services/utils/date.service';

const Notificationbar = () => {
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const notifications = user.notificacoes;
 
 
  return (
    <li className='onhover-dropdown'>
      <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}>
        <SvgIcon iconId='notification' />
        <span className='badge rounded-pill badge-secondary' id={'badgeNotification' + user.id}>{notifications.length === 0 ? '0' : notifications.length}</span>

      </div>
      <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
        <h6 className='f-18 mb-0 dropdown-title'>{'Notificações'}</h6>
        <ul>
          {notifications.map((notificacao, i) => (
            <li className='b-l-primary border-4' key={i}>
              <p>
                {notificacao.subject} <span className='font-danger'>{ DateService.getDateTime(notificacao.dateCreated)}</span>
              </p>
            </li>
          ))}

          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/notificacoes`}
            >
              Ver todas
            </Link>


          </li>
        </ul>
      </div>
    </li>
  );
};

export default Notificationbar;
